import * as cognito from "@/libs/cognito";
import useKompassRouter from "@/hooks/useKompassRouter";

const useAuth = () => {
    const { kompassNavigate } = useKompassRouter();

    async function getSession() {
        try {
            const session = await cognito.getSession();
            return session;
        } catch (err) {
            throw err;
        }
    }

    const getUserInfo = async () => {
        // console.log("===============getUserInfo===============");

        const authToken = await getAuthToken();
        let response = null;
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/getUserInfo`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            response = await _resData.json();
            if (response.status) {
                response = response.data;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
            throw new Error(e?.message ?? "Something went wrong !");
        }
        return response;
    };

    const updateBasicInfo = async (params) => {
        // console.log("===============updateBasicInfo===============");

        const { userName, mobile, jobRole, industry, arePersonalEmailsPriority } = params;

        const authToken = await getAuthToken();
        let response = null;
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/userInfo`, {
                method: `PUT`,
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userName,
                    mobile,
                    jobRole,
                    industry,
                    arePersonalEmailsPriority,
                }),
            });

            response = await _resData.json();
            if (response.status) {
                response = response.data;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
            throw new Error(e?.message ?? "Something went wrong !");
        }
        return response;
    };

    const deleteAccount = async () => {
        // console.log("===============deleteBasicInfo===============");

        const authToken = await getAuthToken();
        let response = null;
        try {
            // console.log("delete user from cognito")
            await cognito.deleteUser();
        } catch (e) {
            console.log(e);
            throw new Error(e?.message ?? "Something went wrong !");
        }
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/userInfo`, {
                method: `DELETE`,
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            response = await _resData.json();
            if (response.status) {
                response = response.data;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
            throw new Error(e?.message ?? "Something went wrong !");
        }
        return response;
    };

    const passwordUpdated = async () => {
        // console.log("===============passwordUpdated===============");

        const authToken = await getAuthToken();
        let response = null;
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/userInfo`, {
                method: `PUT`,
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                    isPasswordFilled: true,
                }),
            });

            response = await _resData.json();
            if (response.status) {
                response = response.data;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
            throw new Error(e?.message ?? "Something went wrong !");
        }
        return response;
    };

    const resetPassword = async (email, password) => {
        // console.log("===============resetPassword===============");

        let response = null;
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/resetPassword`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: email, password: password }),
            });

            if (_resData.ok) {
                const data = await _resData.json();
                response = data.data;
            } else {
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            throw new Error("Something went wrong !");
        }
        return response;
    };

    const getAuthToken = async () => {
        try {
            const session = await getSession();
            return session.idToken?.jwtToken || null;
        } catch (error) {
            cognito.signOut();
            kompassNavigate("/signin");
            return null;
        }
    };

    return {
        getSession,
        getAuthToken,
        getUserInfo,
        resetPassword,
        updateBasicInfo,
        passwordUpdated,
        deleteAccount,
    };
};

export default useAuth;
