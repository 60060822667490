export const newDepartmentList = [
    {
        label: "Advisory",
        value: "advisory",
        subDepartment: [
            {
                class: "general_and_administrative",
                label: "Advisor",
                value: "advisor",
            },
            {
                class: "general_and_administrative",
                label: "Board Member",
                value: "board_member",
            },
            {
                class: "general_and_administrative",
                label: "Investor",
                value: "investor",
            },
        ],
    },
    {
        label: "Analyst",
        value: "analyst",
        subDepartment: [
            {
                class: "general_and_administrative",
                label: "Business Analyst",
                value: "business_analyst",
            },
            {
                class: "general_and_administrative",
                label: "Data Analyst",
                value: "data_analyst",
            },
            {
                class: "general_and_administrative",
                label: "Revenue Operations",
                value: "revenue_operations",
            },
        ],
    },
    {
        label: "Creative",
        value: "creative",
        subDepartment: [
            { class: "services", label: "Artist", value: "artist" },
            { class: "services", label: "Entertainment", value: "entertainment" },
            { class: "services", label: "Fashion", value: "fashion" },
            { class: "services", label: "Graphic Design", value: "graphic_design" },
            { class: "services", label: "Hair Stylist", value: "hair_stylist" },
            { class: "services", label: "Journalism", value: "journalism" },
        ],
    },
    {
        label: "Education",
        value: "education",
        subDepartment: [
            { class: "services", label: "Curation", value: "curation" },
            {
                class: "services",
                label: "Primary and Secondary",
                value: "primary_and_secondary",
            },
            { class: "services", label: "Professor", value: "professor" },
            { class: "services", label: "Student", value: "student" },
            { class: "services", label: "Tour and Travel", value: "tour_and_travel" },
        ],
    },
    {
        label: "Engineering",
        value: "engineering",
        subDepartment: [
            {
                class: "research_and_development",
                label: "Chemical Engineering",
                value: "chemical",
            },
            {
                class: "research_and_development",
                label: "Data Engineering",
                value: "data_engineering",
            },
            {
                class: "research_and_development",
                label: "Data Science",
                value: "data_science",
            },
            {
                class: "research_and_development",
                label: "DevOps",
                value: "devops",
            },
            {
                class: "research_and_development",
                label: "Electrical Engineering",
                value: "electrical",
            },
            {
                class: "research_and_development",
                label: "Hardware Engineering",
                value: "hardware",
            },
            {
                class: "research_and_development",
                label: "Industrial Engineering",
                value: "industrial",
            },
            {
                class: "research_and_development",
                label: "Information Technology",
                value: "information_technology",
            },
            {
                class: "research_and_development",
                label: "Mechanical Engineering",
                value: "mechanical",
            },
            {
                class: "research_and_development",
                label: "Network Engineering",
                value: "network",
            },
            {
                class: "research_and_development",
                label: "QA Engineering",
                value: "qa_engineering",
            },
            {
                class: "research_and_development",
                label: "Security Engineering",
                value: "security",
            },
            {
                class: "research_and_development",
                label: "Software Engineering",
                value: "software",
            },
            {
                class: "research_and_development",
                label: "Web Engineering",
                value: "web",
            },
        ],
    },
    {
        label: "Finance",
        value: "finance",
        subDepartment: [
            {
                class: "general_and_administrative",
                label: "Accounting",
                value: "accounting",
            },
            {
                class: "general_and_administrative",
                label: "Bookkeeping",
                value: "bookkeeping",
            },
            {
                class: "general_and_administrative",
                label: "Procurement",
                value: "procurement",
            },
            {
                class: "general_and_administrative",
                label: "Planning and Analysis",
                value: "planning_and_analysis",
            },
            {
                class: "general_and_administrative",
                label: "Risk",
                value: "risk",
            },
        ],
    },
    {
        label: "Fulfillment",
        value: "fulfillment",
        subDepartment: [
            { class: "services", label: "Logistics", value: "logistics" },
            {
                class: "services",
                label: "Project Management",
                value: "project_management",
            },
            { class: "services", label: "Transport", value: "transport" },
            { class: "services", label: "Warehouse", value: "warehouse" },
        ],
    },
    {
        label: "Health",
        value: "health",
        subDepartment: [
            { class: "services", label: "Dental", value: "dental" },
            { class: "services", label: "Doctor", value: "doctor" },
            { class: "services", label: "Fitness", value: "fitness" },
            { class: "services", label: "Nursing", value: "nursing" },
            { class: "services", label: "Pharmacy", value: "pharmacy" },
            { class: "services", label: "Therapy", value: "therapy" },
            { class: "services", label: "Veterinarian", value: "veterinarian" },
            { class: "services", label: "Wellness", value: "wellness" },
        ],
    },
    {
        label: "Hospitality",
        value: "hospitality",
        subDepartment: [
            { class: "services", label: "Restaurants", value: "restaurants" },
            { class: "services", label: "Retail", value: "retail" },
        ],
    },
    {
        label: "Human Resources",
        value: "human_resources",
        subDepartment: [
            {
                class: "general_and_administrative",
                label: "Human Resources",
                value: "human_resources",
            },
            {
                class: "general_and_administrative",
                label: "Recruiting",
                value: "recruiting",
            },
            {
                class: "general_and_administrative",
                label: "Talent Analytics",
                value: "talent_analytics",
            },
            {
                class: "general_and_administrative",
                label: "Training",
                value: "training",
            },
        ],
    },
    {
        label: "Legal",
        value: "legal",
        subDepartment: [
            {
                class: "general_and_administrative",
                label: "Compliance",
                value: "compliance",
            },
            {
                class: "general_and_administrative",
                label: "Legal",
                value: "legal",
            },
            {
                class: "services",
                label: "Legal Services",
                value: "legal_services",
            },
        ],
    },
    {
        label: "Manufacturing",
        value: "manufacturing",
        subDepartment: [
            {
                class: "services",
                label: "Health and Safety",
                value: "health_and_safety",
            },
            { class: "services", label: "Machinist", value: "machinist" },
            {
                class: "services",
                label: "Quality Assurance",
                value: "quality_assurance",
            },
        ],
    },
    {
        label: "Marketing",
        value: "marketing",
        subDepartment: [
            { class: "sales_and_marketing", label: "Brand", value: "brand" },
            { class: "sales_and_marketing", label: "Content", value: "content" },
            { class: "sales_and_marketing", label: "Growth", value: "growth" },
            {
                class: "sales_and_marketing",
                label: "Marketing Design",
                value: "marketing_design",
            },
            {
                class: "services",
                label: "Marketing Services",
                value: "marketing_services",
            },
        ],
    },
    {
        label: "Operations",
        value: "operations",
        subDepartment: [
            {
                class: "general_and_administrative",
                label: "Administrative",
                value: "administrative",
            },
            {
                class: "general_and_administrative",
                label: "Aides",
                value: "aides",
            },
            {
                class: "general_and_administrative",
                label: "Building and Grounds",
                value: "building_and_grounds",
            },
            {
                class: "general_and_administrative",
                label: "Corporate Development",
                value: "corporate_development",
            },
            {
                class: "general_and_administrative",
                label: "Executive",
                value: "executive",
            },
            {
                class: "general_and_administrative",
                label: "Investor Relations",
                value: "investor_relations",
            },
            {
                class: "general_and_administrative",
                label: "Strategy",
                value: "strategy",
            },
        ],
    },
    {
        label: "Partnerships",
        value: "partnerships",
        subDepartment: [
            {
                class: "sales_and_marketing",
                label: "Business Development",
                value: "business_development",
            },
            {
                class: "sales_and_marketing",
                label: "Partnerships",
                value: "partnerships",
            },
        ],
    },
    {
        label: "Product",
        value: "product",
        subDepartment: [
            {
                class: "research_and_development",
                label: "Product Design",
                value: "product_design",
            },
            {
                class: "research_and_development",
                label: "Product Management",
                value: "product_management",
            },
        ],
    },
    {
        label: "Professional Service",
        value: "professional_service",
        subDepartment: [
            {
                class: "services",
                label: "Accounting Services",
                value: "accounting_services",
            },
            {
                class: "services",
                label: "Architecture",
                value: "architecture",
            },
            {
                class: "services",
                label: "Consulting",
                value: "consulting",
            },
            {
                class: "services",
                label: "Investment Banking",
                value: "investment_banking",
            },
            {
                class: "services",
                label: "Translation",
                value: "translation",
            },
        ],
    },
    {
        label: "Public Service",
        value: "public_service",
        subDepartment: [
            {
                class: "services",
                label: "Emergency Services",
                value: "emergency_services",
            },
            { class: "services", label: "Judicial", value: "judicial" },
            { class: "services", label: "Military", value: "military" },
            { class: "services", label: "Political", value: "political" },
            {
                class: "services",
                label: "Protective Service",
                value: "protective_service",
            },
            {
                class: "services",
                label: "Social Service",
                value: "social_service",
            },
        ],
    },
    {
        label: "Research",
        value: "research",
        subDepartment: [
            {
                class: "research_and_development",
                label: "Academic Research",
                value: "academic",
            },
            {
                class: "research_and_development",
                label: "Financial Research",
                value: "financial",
            },
            {
                class: "research_and_development",
                label: "Scientific Research",
                value: "scientific",
            },
        ],
    },
    {
        label: "Sales",
        value: "sales",
        subDepartment: [
            {
                class: "sales_and_marketing",
                label: "Account Executive",
                value: "account_executive",
            },
            { class: "sales_and_marketing", label: "Insurance", value: "insurance" },
            { class: "sales_and_marketing", label: "Realtor", value: "realtor" },
            {
                class: "sales_and_marketing",
                label: "Sales Development",
                value: "sales_development",
            },
        ],
    },
    {
        label: "Sales Engineering",
        value: "sales_engineering",
        subDepartment: [
            {
                class: "sales_and_marketing",
                label: "Implementation",
                value: "implementation",
            },
            {
                class: "sales_and_marketing",
                label: "Solutions Engineer",
                value: "solutions_engineer",
            },
        ],
    },
    {
        label: "Support",
        value: "support",
        subDepartment: [
            {
                class: "services",
                label: "Account Management",
                value: "account_management",
            },
            {
                class: "services",
                label: "Customer Success",
                value: "customer_success",
            },
            {
                class: "services",
                label: "Customer Support",
                value: "customer_support",
            },
            { class: "services", label: "Fraud", value: "fraud" },
        ],
    },
    {
        label: "Trade",
        value: "trade",
        subDepartment: [
            { class: "services", label: "Agriculture", value: "agriculture" },
            { class: "services", label: "Construction", value: "construction" },
            { class: "services", label: "Electric", value: "electric" },
            { class: "services", label: "Mechanic", value: "mechanic" },
            { class: "services", label: "Plumbing", value: "plumbing" },
        ],
    },
    {
        label: "Unemployed",
        value: "unemployed",
        subDepartment: [{ class: "unemployed", label: "Unemployed", value: "unemployed" }],
    },
];

export const departmentList = [
    { label: "Customer Service", value: "customer_service" },
    { label: "Design", value: "design" },
    { label: "Education", value: "education" },
    { label: "Engineering", value: "engineering" },
    { label: "Finance", value: "finance" },
    { label: "Health", value: "health" },
    { label: "Human Resources", value: "human_resources" },
    { label: "Legal", value: "legal" },
    { label: "Marketing", value: "marketing" },
    { label: "Media", value: "media" },
    { label: "Operations", value: "operations" },
    { label: "Public Relations", value: "public_relations" },
    { label: "Real Estate", value: "real_estate" },
    { label: "Sales", value: "sales" },
    { label: "Trades", value: "trades" },
];
export const subDepartmentList = [
    { label: "Accounting", value: "accounting" },
    { label: "Accounts", value: "accounts" },
    { label: "Brand Marketing", value: "brand_marketing" },
    { label: "Broadcasting", value: "broadcasting" },
    { label: "Business Development", value: "business_development" },
    { label: "Compensation", value: "compensation" },
    { label: "Content Marketing", value: "content_marketing" },
    { label: "Customer Success", value: "customer_success" },
    { label: "Data", value: "data" },
    { label: "Dental", value: "dental" },
    { label: "DevOps", value: "devops" },
    { label: "Doctor", value: "doctor" },
    { label: "Editorial", value: "editorial" },
    { label: "Education Administration", value: "education_administration" },
    { label: "Electrical", value: "electrical" },
    { label: "Employee Development", value: "employee_development" },
    { label: "Events", value: "events" },
    { label: "Fitness", value: "fitness" },
    { label: "Graphic Design", value: "graphic_design" },
    { label: "Information Technology", value: "information_technology" },
    { label: "Instructor", value: "instructor" },
    { label: "Investment", value: "investment" },
    { label: "Journalism", value: "journalism" },
    { label: "Judicial", value: "judicial" },
    { label: "Lawyer", value: "lawyer" },
    { label: "Logistics", value: "logistics" },
    { label: "Marketing Communications", value: "marketing_communications" },
    { label: "Mechanical", value: "mechanical" },
    { label: "Media Relations", value: "media_relations" },
    { label: "Network", value: "network" },
    { label: "Nursing", value: "nursing" },
    { label: "Office Management", value: "office_management" },
    { label: "Paralegal", value: "paralegal" },
    { label: "Pipeline", value: "pipeline" },
    { label: "Product", value: "product" },
    { label: "Product Design", value: "product_design" },
    { label: "Product Marketing", value: "product_marketing" },
    { label: "Professor", value: "professor" },
    { label: "Project Engineering", value: "project_engineering" },
    { label: "Project Management", value: "project_management" },
    { label: "Property Management", value: "property_management" },
    { label: "Quality Assurance", value: "quality_assurance" },
    { label: "Realtor", value: "realtor" },
    { label: "Recruiting", value: "recruiting" },
    { label: "Researcher", value: "researcher" },
    { label: "Security", value: "security" },
    { label: "Software", value: "software" },
    { label: "Support", value: "support" },
    { label: "Systems", value: "systems" },
    { label: "Tax", value: "tax" },
    { label: "Teacher", value: "teacher" },
    { label: "Therapy", value: "therapy" },
    { label: "Video", value: "video" },
    { label: "Web", value: "web" },
    { label: "Web Design", value: "web_design" },
    { label: "Wellness", value: "wellness" },
    { label: "Writing", value: "writing" },
];

export const departmentSubDepartmentMap = {
    customer_service: ["customer_success", "support"],
    design: ["graphic_design", "product_design", "web_design"],
    education: ["education_administration", "professor", "instructor", "researcher", "teacher"],
    engineering: [
        "data",
        "devops",
        "electrical",
        "information_technology",
        "mechanical",
        "network",
        "project_engineering",
        "quality_assurance",
        "security",
        "software",
        "systems",
        "web",
    ],
    finance: ["accounting", "investment", "tax"],
    health: ["dental", "doctor", "fitness", "nursing", "therapy", "wellness"],
    human_resources: ["compensation", "employee_development", "recruiting"],
    legal: ["judicial", "lawyer", "paralegal"],
    marketing: ["brand_marketing", "content_marketing", "product_marketing", "marketing_communications"],
    media: ["broadcasting", "editorial", "journalism", "video", "writing"],
    operations: ["logistics", "office_management", "product", "project_management"],
    public_relations: ["events", "media_relations"],
    real_estate: ["property_management", "realtor"],
    sales: ["accounts", "business_development", "pipeline"],
};

export const levelList = [
    { label: "CXO", value: "cxo" },
    { label: "Director", value: "director" },
    { label: "Entry", value: "entry" },
    { label: "Manager", value: "manager" },
    { label: "Owner", value: "owner" },
    { label: "Partner", value: "partner" },
    { label: "Senior", value: "senior" },
    { label: "Training", value: "training" },
    { label: "Unpaid", value: "unpaid" },
    { label: "VP", value: "vp" },
];

export const emailEnrichmentPreferencesList = [
    { label: "Work", value: "Work" },
    { label: "Personal", value: "Personal" },
];

export const numberOfEmployeesList = [
    { label: "1 - 10", value: "1-10" },
    { label: "11 - 50", value: "11-50" },
    { label: "51 - 200", value: "51-200" },
    { label: "201 - 500", value: "201-500" },
    { label: "501 - 1000", value: "501-1000" },
    { label: "1001 - 5000", value: "1001-5000" },
    { label: "5001 - 10000", value: "5001-10000" },
    { label: "10001+", value: "10001+" },
];

export const revenueList = [
    { label: "$0 - 1M", value: "$0-$1M", realValue: 1e6 },
    { label: "$1M - 10M", value: "$1M-$10M", realValue: 1e7 },
    { label: "$10M - 25M", value: "$10M-$25M", realValue: 25 * 1e6 },
    { label: "$25M - 50M", value: "$25M-$50M", realValue: 50 * 1e6 },
    { label: "$50M - 100M", value: "$50M-$100M", realValue: 100 * 1e6 },
    { label: "$100M - 250M", value: "$100M-$250M", realValue: 250 * 1e6 },
    { label: "$250M - 500M", value: "$250M-$500M", realValue: 500 * 1e6 },
    { label: "$500M - 1B", value: "$500M-$1B", realValue: 1e9 },
    { label: "$1B - 10B", value: "$1B-$10B", realValue: 10 * 1e9 },
    { label: "$10B+", value: "$10B+", realValue: 10.1 * 1e9 },
];

export const fundingRaisedList = [
    { label: "$0 - 1M", value: "$0-$1M", realValue: 1e6 },
    { label: "$1 - 10M", value: "$1-$10M", realValue: 1e7 },
    { label: "$10 - 50M", value: "$10-$50M", realValue: 5 * 1e7 },
    { label: "$50 - 100M", value: "$50-$100M", realValue: 10 * 1e7 },
    { label: "$100 - 250M", value: "$100-$250M", realValue: 25 * 1e7 },
    { label: "$250 - 500M", value: "$250-$500M", realValue: 50 * 1e7 },
    { label: "$500M - 1B", value: "$500M-$1B", realValue: 100 * 1e7 },
    { label: "> $1B", value: ">$1B", realValue: 1000 * 1e7 },
];

export const industryList = [
    { label: "Accounting", value: "accounting" },
    { label: "Airlines/Aviation", value: "airlines/aviation" },
    {
        label: "Alternative Dispute Resolution",
        value: "alternative dispute resolution",
    },
    { label: "Alternative Medicine", value: "alternative medicine" },
    { label: "Animation", value: "animation" },
    { label: "Apparel & Fashion", value: "apparel & fashion" },
    { label: "Architecture & Planning", value: "architecture & planning" },
    { label: "Arts and Crafts", value: "arts and crafts" },
    { label: "Automotive", value: "automotive" },
    { label: "Aviation & Aerospace", value: "aviation & aerospace" },
    { label: "Banking", value: "banking" },
    { label: "Biotechnology", value: "biotechnology" },
    { label: "Broadcast Media", value: "broadcast media" },
    { label: "Building Materials", value: "building materials" },
    {
        label: "Business Supplies and Equipment",
        value: "business supplies and equipment",
    },
    { label: "Capital Markets", value: "capital markets" },
    { label: "Chemicals", value: "chemicals" },
    {
        label: "Civic & Social Organization",
        value: "civic & social organization",
    },
    { label: "Civil Engineering", value: "civil engineering" },
    { label: "Commercial Real Estate", value: "commercial real estate" },
    {
        label: "Computer & Network Security",
        value: "computer & network security",
    },
    { label: "Computer Games", value: "computer games" },
    { label: "Computer Hardware", value: "computer hardware" },
    { label: "Computer Networking", value: "computer networking" },
    { label: "Computer Software", value: "computer software" },
    { label: "Construction", value: "construction" },
    { label: "Consumer Electronics", value: "consumer electronics" },
    { label: "Consumer Goods", value: "consumer goods" },
    { label: "Consumer Services", value: "consumer services" },
    { label: "Cosmetics", value: "cosmetics" },
    { label: "Dairy", value: "dairy" },
    { label: "Defense & Space", value: "defense & space" },
    { label: "Design", value: "design" },
    { label: "E-Learning", value: "e-learning" },
    { label: "Education Management", value: "education management" },
    {
        label: "Electrical/Electronic Manufacturing",
        value: "electrical/electronic manufacturing",
    },
    { label: "Entertainment", value: "entertainment" },
    { label: "Environmental Services", value: "environmental services" },
    { label: "Events Services", value: "events services" },
    { label: "Executive Office", value: "executive office" },
    { label: "Facilities Services", value: "facilities services" },
    { label: "Farming", value: "farming" },
    { label: "Financial Services", value: "financial services" },
    { label: "Fine Art", value: "fine art" },
    { label: "Fishery", value: "fishery" },
    { label: "Food & Beverages", value: "food & beverages" },
    { label: "Food Production", value: "food production" },
    { label: "Fund-Raising", value: "fund-raising" },
    { label: "Furniture", value: "furniture" },
    { label: "Gambling & Casinos", value: "gambling & casinos" },
    { label: "Glass, Ceramics & Concrete", value: "glass, ceramics & concrete" },
    { label: "Government Administration", value: "government administration" },
    { label: "Government Relations", value: "government relations" },
    { label: "Graphic Design", value: "graphic design" },
    {
        label: "Health, Wellness and Fitness",
        value: "health, wellness and fitness",
    },
    { label: "Higher Education", value: "higher education" },
    { label: "Hospital & Health Care", value: "hospital & health care" },
    { label: "Hospitality", value: "hospitality" },
    { label: "Human Resources", value: "human resources" },
    { label: "Import and Export", value: "import and export" },
    {
        label: "Individual & Family Services",
        value: "individual & family services",
    },
    { label: "Industrial Automation", value: "industrial automation" },
    { label: "Information Services", value: "information services" },
    {
        label: "Information Technology and Services",
        value: "information technology and services",
    },
    { label: "Insurance", value: "insurance" },
    { label: "International Affairs", value: "international affairs" },
    {
        label: "International Trade and Development",
        value: "international trade and development",
    },
    { label: "Internet", value: "internet" },
    { label: "Investment Banking", value: "investment banking" },
    { label: "Investment Management", value: "investment management" },
    { label: "Judiciary", value: "judiciary" },
    { label: "Law Enforcement", value: "law enforcement" },
    { label: "Law Practice", value: "law practice" },
    { label: "Legal Services", value: "legal services" },
    { label: "Legislative Office", value: "legislative office" },
    { label: "Leisure, Travel & Tourism", value: "leisure, travel & tourism" },
    { label: "Libraries", value: "libraries" },
    { label: "Logistics and Supply Chain", value: "logistics and supply chain" },
    { label: "Luxury Goods & Jewelry", value: "luxury goods & jewelry" },
    { label: "Machinery", value: "machinery" },
    { label: "Management Consulting", value: "management consulting" },
    { label: "Maritime", value: "maritime" },
    { label: "Market Research", value: "market research" },
    { label: "Marketing and Advertising", value: "marketing and advertising" },
    {
        label: "Mechanical or Industrial Engineering",
        value: "mechanical or industrial engineering",
    },
    { label: "Media Production", value: "media production" },
    { label: "Medical Devices", value: "medical devices" },
    { label: "Medical Practice", value: "medical practice" },
    { label: "Mental Health Care", value: "mental health care" },
    { label: "Military", value: "military" },
    { label: "Mining & Metals", value: "mining & metals" },
    { label: "Motion Pictures and Film", value: "motion pictures and film" },
    { label: "Museums and Institutions", value: "museums and institutions" },
    { label: "Music", value: "music" },
    { label: "Nanotechnology", value: "nanotechnology" },
    { label: "Newspapers", value: "newspapers" },
    {
        label: "Non-Profit Organization Management",
        value: "non-profit organization management",
    },
    { label: "Oil & Energy", value: "oil & energy" },
    { label: "Online Media", value: "online media" },
    { label: "Outsourcing/Offshoring", value: "outsourcing/offshoring" },
    { label: "Package/Freight Delivery", value: "package/freight delivery" },
    { label: "Packaging and Containers", value: "packaging and containers" },
    { label: "Paper & Forest Products", value: "paper & forest products" },
    { label: "Performing Arts", value: "performing arts" },
    { label: "Pharmaceuticals", value: "pharmaceuticals" },
    { label: "Philanthropy", value: "philanthropy" },
    { label: "Photography", value: "photography" },
    { label: "Plastics", value: "plastics" },
    { label: "Political Organization", value: "political organization" },
    {
        label: "Primary/Secondary Education",
        value: "primary/secondary education",
    },
    { label: "Printing", value: "printing" },
    {
        label: "Professional Training & Coaching",
        value: "professional training & coaching",
    },
    { label: "Program Development", value: "program development" },
    { label: "Public Policy", value: "public policy" },
    {
        label: "Public Relations and Communications",
        value: "public relations and communications",
    },
    { label: "Public Safety", value: "public safety" },
    { label: "Publishing", value: "publishing" },
    { label: "Railroad Manufacture", value: "railroad manufacture" },
    { label: "Ranching", value: "ranching" },
    { label: "Real Estate", value: "real estate" },
    {
        label: "Recreational Facilities and Services",
        value: "recreational facilitiesand services",
    },
    { label: "Religious Institutions", value: "religious institutions" },
    { label: "Renewables & Environment", value: "renewables & environment" },
    { label: "Research", value: "research" },
    { label: "Restaurants", value: "restaurants" },
    { label: "Retail", value: "retail" },
    {
        label: "Security and Investigations",
        value: "security and investigations",
    },
    {
        label: "Semiconductors",
        value: "semiconductors",
    },
    { label: "Shipbuilding", value: "shipbuilding" },
    { label: "Sporting Goods", value: "sporting goods" },
    { label: "Sports", value: "sports" },
    { label: "Staffing and Recruiting", value: "staffing and recruiting" },
    { label: "Supermarkets", value: "supermarkets" },
    { label: "Telecommunications", value: "telecommunications" },
    {
        label: "Textiles",
        value: "textiles",
    },
    {
        label: "Think Tanks",
        value: "think tanks",
    },
    {
        label: "Tobacco",
        value: "tobacco",
    },
    {
        label: "Translation and Localization",
        value: "translation and localization",
    },
    {
        label: "Transportation/Trucking/Railroad",
        value: "transportation/trucking/railroad",
    },
    {
        label: "Utilities",
        value: "utilities",
    },
    {
        label: "Venture Capital & Private Equity",
        value: "venture capital & private equity",
    },
    {
        label: "Veterinary",
        value: "veterinary",
    },
    {
        label: "Warehousing",
        value: "warehousing",
    },
    {
        label: "Wholesale",
        value: "wholesale",
    },
    {
        label: "Wine and Spirits",
        value: "wine and spirits",
    },
    {
        label: "Wireless",
        value: "wireless",
    },
    {
        label: "Writing and Editing",
        value: "writing and editing",
    },
];

export const degreeList = [
    { label: "Associate of Arts", value: "associate of arts" },
    { label: "Associates", value: "associates" },
    {
        label: "Bachelor of Aerospace Engineering",
        value: "bachelor of aerospace engineering",
    },
    {
        label: "Bachelor of Applied Science",
        value: "bachelor of applied science",
    },
    { label: "Bachelor of Architecture", value: "bachelor of architecture" },
    { label: "Bachelor of Arts", value: "bachelor of arts" },
    {
        label: "Bachelor of Arts in Business Administration",
        value: "bachelor of arts in business administration",
    },
    {
        label: "Bachelor of Arts in Communication",
        value: "bachelor of arts in communication",
    },
    {
        label: "Bachelor of Arts in Education",
        value: "bachelor of arts in education",
    },
    {
        label: "Bachelor of Biosystems Engineering",
        value: "bachelor of biosystems engineering",
    },
    {
        label: "Bachelor of Business Administration",
        value: "bachelor of business administration",
    },
    {
        label: "Bachelor of Chemical Engineering",
        value: "bachelor of chemical engineering",
    },
    {
        label: "Bachelor of Civil Engineering",
        value: "bachelor of civil engineering",
    },
    { label: "Bachelor of Commerce", value: "bachelor of commerce" },
    { label: "Bachelor of Design", value: "bachelor of design" },
    { label: "Bachelor of Education", value: "bachelor of education" },
    {
        label: "Bachelor of Electrical Engineering",
        value: "bachelor of electrical engineering",
    },
    { label: "Bachelor of Engineering", value: "bachelor of engineering" },
    { label: "Bachelor of Fine Arts", value: "bachelor of fine arts" },
    {
        label: "Bachelor of General Studies",
        value: "bachelor of general studies",
    },
    {
        label: "Bachelor of Industrial & Systems Engineering",
        value: "bachelor of industrial and systems engineering",
    },
    {
        label: "Bachelor of Industrial Design",
        value: "bachelor of industrial design",
    },
    {
        label: "Bachelor of Interdisciplinary Studies",
        value: "bachelor of interdisciplinary studies",
    },
    {
        label: "Bachelor of Interior Architecture",
        value: "bachelor of interior architecture",
    },
    { label: "Bachelor of Law", value: "bachelor of law" },
    { label: "Bachelor of Liberal Arts", value: "bachelor of liberal arts" },
    {
        label: "Bachelor of Liberal Arts and Sciences",
        value: "bachelor of liberal arts and sciences",
    },
    {
        label: "Bachelor of Materials Engineering",
        value: "bachelor of materials engineering",
    },
    { label: "Bachelor of Mathematics", value: "bachelor of mathematics" },
    {
        label: "Bachelor of Mechanical Engineering",
        value: "bachelor of mechanical engineering",
    },
    { label: "Bachelor of Medicine", value: "bachelor of medicine" },
    { label: "Bachelor of Music", value: "bachelor of music" },
    {
        label: "Bachelor of Music Education",
        value: "bachelor of music education",
    },
    { label: "Bachelor of Pharmacy", value: "bachelor of pharmacy" },
    {
        label: "Bachelor of Polymer and Fiber Engineering",
        value: "bachelor of polymer and fiber engineering",
    },
    {
        label: "Bachelor of Professional Health Science",
        value: "bachelor of professional health science",
    },
    { label: "Bachelor of Science", value: "bachelor of science" },
    {
        label: "Bachelor of Science in Aerospace Engineering",
        value: "bachelor of science in aerospace engineering",
    },
    {
        label: "Bachelor of Science in Biomedical Engineering",
        value: "bachelor of science in biomedical engineering",
    },
    {
        label: "Bachelor of Science in Business Administration",
        value: "bachelor of science in business administration",
    },
    {
        label: "Bachelor of Science in Chemical Engineering",
        value: "bachelor of science in chemical engineering",
    },
    {
        label: "Bachelor of Science in Chemistry",
        value: "bachelor of science in chemistry",
    },
    {
        label: "Bachelor of Science in Civil Engineering",
        value: "bachelor of science in civil engineering",
    },
    {
        label: "Bachelor of Science in Commerce Business Administration",
        value: "bachelor of science in commerce business administration",
    },
    {
        label: "Bachelor of Science in Computer Science",
        value: "bachelor of science in computer science",
    },
    {
        label: "Bachelor of Science in Education",
        value: "bachelor of science in education",
    },
    {
        label: "Bachelor of Science in Electrical Engineering",
        value: "bachelor of science in electrical engineering",
    },
    {
        label: "Bachelor of Science in Engineering",
        value: "bachelor of science in engineering",
    },
    {
        label: "Bachelor of Science in Engineering Technology",
        value: "bachelor of science in engineering technology",
    },
    {
        label: "Bachelor of Science in Geology",
        value: "bachelor of science in geology",
    },
    {
        label: "Bachelor of Science in Human Environmental Sciences",
        value: "bachelor of science in human environmental sciences",
    },
    {
        label: "Bachelor of Science in Materials Engineering",
        value: "bachelor of science in materials engineering",
    },
    {
        label: "Bachelor of Science in Mechanical Engineering",
        value: "bachelor of science in mechanical engineering",
    },
    {
        label: "Bachelor of Science in Metallurgical Engineering",
        value: "bachelor of science in metallurgical engineering",
    },
    {
        label: "Bachelor of Science in Microbiology",
        value: "bachelor of science in microbiology",
    },
    {
        label: "Bachelor of Science in Nursing",
        value: "bachelor of science in nursing",
    },
    {
        label: "Bachelor of Science in Social Work",
        value: "bachelor of science in social work",
    },
    { label: "Bachelor of Social Work", value: "bachelor of social work" },
    {
        label: "Bachelor of Software Engineering",
        value: "bachelor of software engineering",
    },
    { label: "Bachelor of Technology", value: "bachelor of technology" },
    {
        label: "Bachelor of Textile Engineering",
        value: "bachelor of textile engineering",
    },
    {
        label: "Bachelor of Textile Management and Technology",
        value: "bachelor of textile management and technology",
    },
    {
        label: "Bachelor of Veterinary Science",
        value: "bachelor of veterinary science",
    },
    {
        label: "Bachelor of Wireless Engineering",
        value: "bachelor of wireless engineering",
    },
    { label: "Bachelors", value: "bachelors" },
    { label: "Doctor of Audiology", value: "doctor of audiology" },
    {
        label: "Doctor of Business Administration",
        value: "doctor of business administration",
    },
    { label: "Doctor of Chiropractic", value: "doctor of chiropractic" },
    { label: "Doctor of Dental Surgery", value: "doctor of dental surgery" },
    { label: "Doctor of Education", value: "doctor of education" },
    { label: "Doctor of Jurisprudence", value: "doctor of jurisprudence" },
    {
        label: "Doctor of Medical Dentistry",
        value: "doctor of medical dentistry",
    },
    { label: "Doctor of Medicine", value: "doctor of medicine" },
    { label: "Doctor of Ministry", value: "doctor of ministry" },
    { label: "Doctor of Musical Arts", value: "doctor of musical arts" },
    { label: "Doctor of Nursing Practice", value: "doctor of nursing practice" },
    { label: "Doctor of Optometry", value: "doctor of optometry" },
    { label: "Doctor of Osteophathy", value: "doctor of osteophathy" },
    { label: "Doctor of Pharmacy", value: "doctor of pharmacy" },
    { label: "Doctor of Philosophy", value: "doctor of philosophy" },
    { label: "Doctor of Physical Therapy", value: "doctor of physical therapy" },
    { label: "Doctor of Psychology", value: "doctor of psychology" },
    { label: "Doctor of Public Health", value: "doctor of public health" },
    { label: "Doctor of Science", value: "doctor of science" },
    {
        label: "Doctor of Veterinary Medicine",
        value: "doctor of veterinary medicine",
    },
    { label: "Doctorates", value: "doctorates" },
    { label: "Magister Juris", value: "magister juris" },
    { label: "Magisters", value: "magisters" },
    { label: "Master of Accountancy", value: "master of accountancy" },
    { label: "Master of Accounting", value: "master of accounting" },
    {
        label: "Master of Aerospace Engineering",
        value: "master of aerospace engineering",
    },
    { label: "Master of Agriculture", value: "master of agriculture" },
    {
        label: "Master of Applied Mathematics",
        value: "master of applied mathematics",
    },
    { label: "Master of Aquaculture", value: "master of aquaculture" },
    { label: "Master of Arts", value: "master of arts" },
    {
        label: "Master of Arts in Education",
        value: "master of arts in education",
    },
    { label: "Master of Arts in Teaching", value: "master of arts in teaching" },
    {
        label: "Master of Building Construction",
        value: "master of building construction",
    },
    {
        label: "Master of Business Administration",
        value: "master of business administration",
    },
    {
        label: "Master of Chemical Engineering",
        value: "master of chemical engineering",
    },
    { label: "Master of Civil Engineering", value: "master ofcivil engineering" },
    { label: "Master of Commerce", value: "master of commerce" },
    {
        label: "Master of Communication Disorders",
        value: "master of communication disorders",
    },
    {
        label: "Master of Community Planning",
        value: "master of community planning",
    },
    { label: "Master of Dental Surgery", value: "master of dental surgery" },
    { label: "Master of Design", value: "master of design" },
    { label: "Master of Divinity", value: "master of divinity" },
    { label: "Master of Education", value: "master of education" },
    {
        label: "Master of Electrical Engineering",
        value: "master of electrical engineering",
    },
    { label: "Master of Engineering", value: "master of engineering" },
    { label: "Master of Fine Arts", value: "master of fine arts" },
    { label: "Master of Health Science", value: "master of health science" },
    { label: "Master of Hispanic Studies", value: "master of hispanic studies" },
    {
        label: "Master of Industrial Design",
        value: "master of industrial design",
    },
    {
        label: "Master of Integrated Design and Construction",
        value: "master of integrated design and construction",
    },
    {
        label: "Master of International Studies",
        value: "master of international studies",
    },
    {
        label: "Master of Landscape Architecture",
        value: "master of landscape architecture",
    },
    { label: "Master of Laws", value: "master of laws" },
    { label: "Master of Liberal Arts", value: "master of liberal arts" },
    {
        label: "Master of Library & Information Studies",
        value: "master of library and information studies",
    },
    { label: "Master of Library Science", value: "master of library science" },
    {
        label: "Master of Materials Engineering",
        value: "master of materials engineering",
    },
    {
        label: "Master of Mechanical Engineering",
        value: "master of mechanical engineering",
    },
    { label: "Master of Music", value: "master of music" },
    {
        label: "Master of Natural Resources",
        value: "master of natural resources",
    },
    { label: "Master of Nurse Anesthesia", value: "master of nurse anesthesia" },
    {
        label: "Master of Political Science",
        value: "master of political science",
    },
    {
        label: "Master of Probability and Statistics",
        value: "master of probability and statistics",
    },
    {
        label: "Master of Professional Studies",
        value: "master of professional studies",
    },
    {
        label: "Master of Public Administration",
        value: "master of public administration",
    },
    { label: "Master of Public Health", value: "master of public health" },
    {
        label: "Master of Real Estate Development",
        value: "master of real estate development",
    },
    {
        label: "Master of Rehabilitation Counseling",
        value: "master of rehabilitation counseling",
    },
    { label: "Master of Science", value: "master of science" },
    {
        label: "Master of Science in Aerospace Engineering",
        value: "master of science in aerospace engineering",
    },
    {
        label: "Master of Science in Basic Medical Sciences",
        value: "master of science in basic medical sciences",
    },
    {
        label: "Master of Science in Biomedical Engineering",
        value: "master of science in biomedical engineering",
    },
    {
        label: "Master of Science in Chemical Engineering",
        value: "master of science in chemical engineering",
    },
    {
        label: "Master of Science in Chemistry",
        value: "master of science in chemistry",
    },
    {
        label: "Master of Science in Civil Engineering",
        value: "master of science in civil engineering",
    },
    {
        label: "Master of Science in Computer Science",
        value: "master of science in computer science",
    },
    {
        label: "Master of Science in Criminal Justice",
        value: "master of science in criminal justice",
    },
    {
        label: "Master of Science in Education",
        value: "master of science in education",
    },
    {
        label: "Master of Science in Electrical Engineering",
        value: "master of science in electrical engineering",
    },
    {
        label: "Master of Science in Engineering Science & Mechanics",
        value: "master of science in engineering science and mechanics",
    },
    {
        label: "Master of Science in Forensic Science",
        value: "master of science in forensic science",
    },
    {
        label: "Master of Science in Health Administration",
        value: "master of science in health administration",
    },
    {
        label: "Master of Science in Health Informatics",
        value: "master of science in health informatics",
    },
    {
        label: "Master of Science in Human Environmental Sciences",
        value: "master of science in human environmental sciences",
    },
    {
        label: "Master of Science in Industrial Engineering",
        value: "master of science in industrial engineering",
    },
    {
        label: "Master of Science in Information Systems",
        value: "master of science in information systems",
    },
    {
        label: "Master of Science in Instructional Leadership Administration",
        value: "master of science in instructional leadership administration",
    },
    {
        label: "Master of Science in Justice and Public Safety",
        value: "master of science in justice and public safety",
    },
    {
        label: "Master of Science in Marine Science",
        value: "master of science in marine science",
    },
    {
        label: "Master of Science in Materials Engineering",
        value: "master of science in materials engineering",
    },
    {
        label: "Master of Science in Mechanical Engineering",
        value: "master of science in mechanical engineering",
    },
    {
        label: "Master of Science in Metallurgical Engineering",
        value: "master of science in metallurgical engineering",
    },
    {
        label: "Master of Science in Nursing",
        value: "master of science in nursing",
    },
    {
        label: "Master of Science in Occupational Therapy",
        value: "master of science in occupational therapy",
    },
    {
        label: "Master of Science in Operations Research",
        value: "master of science in operations research",
    },
    {
        label: "Master of Science in Physician Assistant Studies",
        value: "master of science in physician assistant studies",
    },
    {
        label: "Master of Science in Public Health",
        value: "master of science in public health",
    },
    {
        label: "Master of Science in Software Engineering",
        value: "master of science in software engineering",
    },
    { label: "Master of Social Work", value: "master of social work" },
    {
        label: "Master of Software Engineering",
        value: "master of software engineering",
    },
    { label: "Master of Tax Accounting", value: "master of tax accounting" },
    { label: "Master of Taxation", value: "master of taxation" },
    {
        label: "Master of Technical & Professional Communication",
        value: "master of technical and professional communication",
    },
    { label: "Master of Technology", value: "master of technology" },
    {
        label: "Master of Urban and Regional Planning",
        value: "master of urban and regional planning",
    },
    { label: "Masters", value: "masters" },
];

export const genderList = [
    { label: "Male", value: "male" },
    { label: "Female", value: "Female" },
];

export const continentList = [
    { label: "Africa", value: "africa" },
    { label: "Antarctica", value: "antarctica" },
    { label: "Asia", value: "asia" },
    { label: "Europe", value: "europe" },
    { label: "North America", value: "north america" },
    { label: "Oceania", value: "oceania" },
    { label: "South America", value: "south america" },
];

export const countryList = [
    { label: "Afghanistan", value: "afghanistan" },
    { label: "Albania", value: "albania" },
    { label: "Algeria", value: "algeria" },
    { label: "American Samoa", value: "american samoa" },
    { label: "Andorra", value: "andorra" },
    { label: "Angola", value: "angola" },
    { label: "Anguilla", value: "anguilla" },
    { label: "Antarctica", value: "antarctica" },
    { label: "Antigua and Barbuda", value: "antigua and barbuda" },
    { label: "Argentina", value: "argentina" },
    { label: "Armenia", value: "armenia" },
    { label: "Aruba", value: "aruba" },
    { label: "Australia", value: "australia" },
    { label: "Austria", value: "austria" },
    { label: "Azerbaijan", value: "azerbaijan" },
    { label: "Bahamas", value: "bahamas" },
    { label: "Bahrain", value: "bahrain" },
    { label: "Bangladesh", value: "bangladesh" },
    { label: "Barbados", value: "barbados" },
    { label: "Belarus", value: "belarus" },
    { label: "Belgium", value: "belgium" },
    { label: "Belize", value: "belize" },
    { label: "Benin", value: "benin" },
    { label: "Bermuda", value: "bermuda" },
    { label: "Bhutan", value: "bhutan" },
    { label: "Bolivia", value: "bolivia" },
    { label: "Bosnia and Herzegovina", value: "bosnia and herzegovina" },
    { label: "Botswana", value: "botswana" },
    { label: "Bouvet Island", value: "bouvet island" },
    { label: "Brazil", value: "brazil" },
    {
        label: "British Indian Ocean Territory",
        value: "british indian ocean territory",
    },
    { label: "British Virgin Islands", value: "british virgin islands" },
    { label: "Brunei", value: "brunei" },
    { label: "Bulgaria", value: "bulgaria" },
    { label: "Burkina Faso", value: "burkina faso" },
    { label: "Burundi", value: "burundi" },
    { label: "Cambodia", value: "cambodia" },
    { label: "Cameroon", value: "cameroon" },
    { label: "Canada", value: "canada" },
    { label: "Cape Verde", value: "cape verde" },
    { label: "Caribbean Netherlands", value: "caribbean netherlands" },
    { label: "Cayman Islands", value: "cayman islands" },
    { label: "Central African Republic", value: "central african republic" },
    { label: "Chad", value: "chad" },
    { label: "Chile", value: "chile" },
    { label: "China", value: "china" },
    { label: "Christmas Island", value: "christmas island" },
    { label: "Cocos (Keeling) Islands", value: "cocos (keeling) islands" },
    { label: "Colombia", value: "colombia" },
    { label: "Comoros", value: "comoros" },
    { label: "Cook Islands", value: "cook islands" },
    { label: "Costa Rica", value: "costa rica" },
    { label: "Croatia", value: "croatia" },
    { label: "Cuba", value: "cuba" },
    { label: "Curaçao", value: "curaçao" },
    { label: "Cyprus", value: "cyprus" },
    { label: "Czechia", value: "czechia" },
    { label: "Côte d’Ivoire", value: "côte d’ivoire" },
    {
        label: "Democratic Republic of the Congo",
        value: "democratic republic of the congo",
    },
    { label: "Denmark", value: "denmark" },
    { label: "Djibouti", value: "djibouti" },
    { label: "Dominica", value: "dominica" },
    { label: "Dominican Republic", value: "dominican republic" },
    { label: "Ecuador", value: "ecuador" },
    { label: "Egypt", value: "egypt" },
    { label: "El Salvador", value: "el salvador" },
    { label: "Equatorial Guinea", value: "equatorial guinea" },
    { label: "Eritrea", value: "eritrea" },
    { label: "Estonia", value: "estonia" },
    { label: "Ethiopia", value: "ethiopia" },
    { label: "Falkland Islands", value: "falkland islands" },
    { label: "Faroe Islands", value: "faroe islands" },
    { label: "Fiji", value: "fiji" },
    { label: "Finland", value: "finland" },
    { label: "France", value: "france" },
    { label: "French Guiana", value: "french guiana" },
    { label: "French Polynesia", value: "french polynesia" },
    {
        label: "French Southern Territories",
        value: "french southern territories",
    },
    { label: "Gabon", value: "gabon" },
    { label: "Gambia", value: "gambia" },
    { label: "Georgia", value: "georgia" },
    { label: "Germany", value: "germany" },
    { label: "Ghana", value: "ghana" },
    { label: "Gibraltar", value: "gibraltar" },
    { label: "Greece", value: "greece" },
    { label: "Greenland", value: "greenland" },
    { label: "Grenada", value: "grenada" },
    { label: "Guadeloupe", value: "guadeloupe" },
    { label: "Guam", value: "guam" },
    { label: "Guatemala", value: "guatemala" },
    { label: "Guernsey", value: "guernsey" },
    { label: "Guinea", value: "guinea" },
    { label: "Guinea-Bissau", value: "guinea-bissau" },
    { label: "Guyana", value: "guyana" },
    { label: "Haiti", value: "haiti" },
    {
        label: "Heard Island and McDonald Islands",
        value: "heard island and mcdonald islands",
    },
    { label: "Honduras", value: "honduras" },
    { label: "Hong Kong", value: "hong kong" },
    { label: "Hungary", value: "hungary" },
    { label: "Iceland", value: "iceland" },
    { label: "India", value: "india" },
    { label: "Indonesia", value: "indonesia" },
    { label: "Iran", value: "iran" },
    { label: "Iraq", value: "iraq" },
    { label: "Ireland", value: "ireland" },
    { label: "Isle of Man", value: "isle of man" },
    { label: "Israel", value: "israel" },
    { label: "Italy", value: "italy" },
    { label: "Ivory Coast", value: "ivory coast" },
    { label: "Jamaica", value: "jamaica" },
    { label: "Japan", value: "japan" },
    { label: "Jersey", value: "jersey" },
    { label: "Jordan", value: "jordan" },
    { label: "Kazakhstan", value: "kazakhstan" },
    { label: "Kenya", value: "kenya" },
    { label: "Kiribati", value: "kiribati" },
    { label: "Kosovo", value: "kosovo" },
    { label: "Kuwait", value: "kuwait" },
    { label: "Kyrgyzstan", value: "kyrgyzstan" },
    { label: "Laos", value: "laos" },
    { label: "Latvia", value: "latvia" },
    { label: "Lebanon", value: "lebanon" },
    { label: "Lesotho", value: "lesotho" },
    { label: "Liberia", value: "liberia" },
    { label: "Libya", value: "libya" },
    { label: "Liechtenstein", value: "liechtenstein" },
    { label: "Lithuania", value: "lithuania" },
    { label: "Luxembourg", value: "luxembourg" },
    { label: "Macau", value: "macau" },
    { label: "Macedonia", value: "macedonia" },
    { label: "Madagascar", value: "madagascar" },
    { label: "Malawi", value: "malawi" },
    { label: "Malaysia", value: "malaysia" },
    { label: "Maldives", value: "maldives" },
    { label: "Mali", value: "mali" },
    { label: "Malta", value: "malta" },
    { label: "Marshall Islands", value: "marshall islands" },
    { label: "Martinique", value: "martinique" },
    { label: "Mauritania", value: "mauritania" },
    { label: "Mauritius", value: "mauritius" },
    { label: "Mayotte", value: "mayotte" },
    { label: "Mexico", value: "mexico" },
    { label: "Micronesia", value: "micronesia" },
    { label: "Moldova", value: "moldova" },
    { label: "Monaco", value: "monaco" },
    { label: "Mongolia", value: "mongolia" },
    { label: "Montenegro", value: "montenegro" },
    { label: "Montserrat", value: "montserrat" },
    { label: "Morocco", value: "morocco" },
    { label: "Mozambique", value: "mozambique" },
    { label: "Myanmar", value: "myanmar" },
    { label: "Namibia", value: "namibia" },
    { label: "Nauru", value: "nauru" },
    { label: "Nepal", value: "nepal" },
    { label: "Netherlands", value: "netherlands" },
    { label: "Netherlands Antilles", value: "netherlands antilles" },
    { label: "New Caledonia", value: "new caledonia" },
    { label: "New Zealand", value: "new zealand" },
    { label: "Nicaragua", value: "nicaragua" },
    { label: "Niger", value: "niger" },
    { label: "Nigeria", value: "nigeria" },
    { label: "Niue", value: "niue" },
    { label: "Norfolk Island", value: "norfolk island" },
    { label: "North Korea", value: "north korea" },
    { label: "Northern Mariana Islands", value: "northern mariana islands" },
    { label: "Norway", value: "norway" },
    { label: "Oman", value: "oman" },
    { label: "Pakistan", value: "pakistan" },
    { label: "Palau", value: "palau" },
    { label: "Palestine", value: "palestine" },
    { label: "Panama", value: "panama" },
    { label: "Papua New Guinea", value: "papua new guinea" },
    { label: "Paraguay", value: "paraguay" },
    { label: "Peru", value: "peru" },
    { label: "Philippines", value: "philippines" },
    { label: "Pitcairn", value: "pitcairn" },
    { label: "Poland", value: "poland" },
    { label: "Portugal", value: "portugal" },
    { label: "Puerto Rico", value: "puerto rico" },
    { label: "Qatar", value: "qatar" },
    { label: "Republic of the Congo", value: "republic of the congo" },
    { label: "Romania", value: "romania" },
    { label: "Russia", value: "russia" },
    { label: "Rwanda", value: "rwanda" },
    { label: "Réunion", value: "réunion" },
    { label: "Saint Barthélemy", value: "saint barthélemy" },
    { label: "Saint Helena", value: "saint helena" },
    { label: "Saint Kitts and Nevis", value: "saint kitts and nevis" },
    { label: "Saint Lucia", value: "saint lucia" },
    { label: "Saint Martin", value: "saint martin" },
    { label: "Saint Pierre and Miquelon", value: "saint pierre and miquelon" },
    {
        label: "Saint Vincent and the Grenadines",
        value: "saint vincent and the grenadines",
    },
    { label: "Samoa", value: "samoa" },
    { label: "San Marino", value: "san marino" },
    { label: "Saudi Arabia", value: "saudi arabia" },
    { label: "Senegal", value: "senegal" },
    { label: "Serbia", value: "serbia" },
    { label: "Seychelles", value: "seychelles" },
    { label: "Sierra Leone", value: "sierra leone" },
    { label: "Singapore", value: "singapore" },
    { label: "Sint Maarten", value: "sint maarten" },
    { label: "Slovakia", value: "slovakia" },
    { label: "Slovenia", value: "slovenia" },
    { label: "Solomon Islands", value: "solomon islands" },
    { label: "Somalia", value: "somalia" },
    { label: "South Africa", value: "south africa" },
    {
        label: "South Georgia and the South Sandwich Islands",
        value: "south georgia and the south sandwich islands",
    },
    { label: "South Korea", value: "south korea" },
    { label: "South Sudan", value: "south sudan" },
    { label: "Spain", value: "spain" },
    { label: "Sri Lanka", value: "sri lanka" },
    { label: "Sudan", value: "sudan" },
    { label: "Suriname", value: "suriname" },
    { label: "Svalbard and Jan Mayen", value: "svalbard and jan mayen" },
    { label: "Swaziland", value: "swaziland" },
    { label: "Sweden", value: "sweden" },
    { label: "Switzerland", value: "switzerland" },
    { label: "Syria", value: "syria" },
    { label: "São Tomé and Príncipe", value: "são tomé and príncipe" },
    { label: "Taiwan", value: "taiwan" },
    { label: "Tajikistan", value: "tajikistan" },
    { label: "Tanzania", value: "tanzania" },
    { label: "Thailand", value: "thailand" },
    { label: "Timor-Leste", value: "timor-leste" },
    { label: "Togo", value: "togo" },
    { label: "Tokelau", value: "tokelau" },
    { label: "Tonga", value: "tonga" },
    { label: "Trinidad and Tobago", value: "trinidad and tobago" },
    { label: "Tunisia", value: "tunisia" },
    { label: "Turkey", value: "turkey" },
    { label: "Turkmenistan", value: "turkmenistan" },
    { label: "Turks and Caicos Islands", value: "turks and caicos islands" },
    { label: "Tuvalu", value: "tuvalu" },
    { label: "U.S. Virgin Islands", value: "u.s. virgin islands" },
    { label: "Uganda", value: "uganda" },
    { label: "Ukraine", value: "ukraine" },
    { label: "United Arab Emirates", value: "united arab emirates" },
    { label: "United Kingdom", value: "united kingdom" },
    { label: "United States", value: "united states" },
    {
        label: "United States Minor Outlying Islands",
        value: "united states minor outlying islands",
    },
    { label: "Uruguay", value: "uruguay" },
    { label: "Uzbekistan", value: "uzbekistan" },
    { label: "Vanuatu", value: "vanuatu" },
    { label: "Vatican City", value: "vatican city" },
    { label: "Venezuela", value: "venezuela" },
    { label: "Vietnam", value: "vietnam" },
    { label: "Wallis and Futuna", value: "wallis and futuna" },
    { label: "Western Sahara", value: "western sahara" },
    { label: "Yemen", value: "yemen" },
    { label: "Zambia", value: "zambia" },
    { label: "Zimbabwe", value: "zimbabwe" },
    { label: "Åland Islands", value: "åland islands" },
];

export const listingList = [
    { label: "Educational", value: "educational" },
    { label: "Government", value: "government" },
    { label: "Nonprofit", value: "nonprofit" },
    { label: "Private", value: "private" },
    { label: "Public", value: "public" },
];

export const languageList = [
    { label: "Afrikaans", value: "afrikaans" },
    { label: "Akan", value: "akan" },
    { label: "Albanian", value: "albanian" },
    { label: "Amazigh", value: "amazigh" },
    { label: "American Sign Language", value: "american sign language" },
    { label: "Amharic", value: "amharic" },
    { label: "Arabic", value: "arabic" },
    { label: "Aramaic", value: "aramaic" },
    { label: "Armenian", value: "armenian" },
    { label: "Assamese", value: "assamese" },
    { label: "Aymara", value: "aymara" },
    { label: "Azerbaijani", value: "azerbaijani" },
    { label: "Balochi", value: "balochi" },
    { label: "Bambara", value: "bambara" },
    { label: "Banda", value: "banda" },
    { label: "Bashkort", value: "bashkort" },
    { label: "Basque", value: "basque" },
    { label: "Belarusian", value: "belarusian" },
    { label: "Bemba", value: "bemba" },
    { label: "Bengali", value: "bengali" },
    { label: "Bhojpuri", value: "bhojpuri" },
    { label: "Bislama", value: "bislama" },
    { label: "Bosnian", value: "bosnian" },
    { label: "Brahui", value: "brahui" },
    { label: "Bulgarian", value: "bulgarian" },
    { label: "Burmese", value: "burmese" },
    { label: "Cantonese", value: "cantonese" },
    { label: "Catalan", value: "catalan" },
    { label: "Cebuano", value: "cebuano" },
    { label: "Chechen", value: "chechen" },
    { label: "Cherokee", value: "cherokee" },
    { label: "Chewa", value: "chewa" },
    { label: "Croatian", value: "croatian" },
    { label: "Czech", value: "czech" },
    { label: "Dakota", value: "dakota" },
    { label: "Danish", value: "danish" },
    { label: "Dari", value: "dari" },
    { label: "Dholuo", value: "dholuo" },
    { label: "Dinka", value: "dinka" },
    { label: "Dutch", value: "dutch" },
    { label: "English", value: "english" },
    { label: "Esperanto", value: "esperanto" },
    { label: "Estonian", value: "estonian" },
    { label: "Ewe", value: "ewe" },
    { label: "Farsi", value: "farsi" },
    { label: "Filipino", value: "filipino" },
    { label: "Finnish", value: "finnish" },
    { label: "Fon", value: "fon" },
    { label: "French", value: "french" },
    { label: "Fula", value: "fula" },
    { label: "Galician", value: "galician" },
    { label: "Georgian", value: "georgian" },
    { label: "German", value: "german" },
    { label: "Gikuyu", value: "gikuyu" },
    { label: "Greek", value: "greek" },
    { label: "Guarani", value: "guarani" },
    { label: "Gujarati", value: "gujarati" },
    { label: "Haitian Creole", value: "haitian creole" },
    { label: "Hausa", value: "hausa" },
    { label: "Hawaiian", value: "hawaiian" },
    { label: "Hawaiian Creole", value: "hawaiian creole" },
    { label: "Hebrew", value: "hebrew" },
    { label: "Hiligaynon", value: "hiligaynon" },
    { label: "Hindi", value: "hindi" },
    { label: "Hungarian", value: "hungarian" },
    { label: "Icelandic", value: "icelandic" },
    { label: "Igbo", value: "igbo" },
    { label: "Ilocano", value: "ilocano" },
    { label: "Indonesian", value: "indonesian" },
    { label: "Inuit/Inupiaq", value: "inuit/inupiaq" },
    { label: "Irish Gaelic", value: "irish gaelic" },
    { label: "Italian", value: "italian" },
    { label: "Japanese", value: "japanese" },
    { label: "Jarai", value: "jarai" },
    { label: "Javanese", value: "javanese" },
    { label: "K'iche'", value: "k'iche'" },
    { label: "Kabyle", value: "kabyle" },
    { label: "Kannada", value: "kannada" },
    { label: "Kashmiri", value: "kashmiri" },
    { label: "Kazakh", value: "kazakh" },
    { label: "Khmer", value: "khmer" },
    { label: "Khoekhoe", value: "khoekhoe" },
    { label: "Kinyarwanda", value: "kinyarwanda" },
    { label: "Kongo", value: "kongo" },
    { label: "Konkani", value: "konkani" },
    { label: "Korean", value: "korean" },
    { label: "Kurdish", value: "kurdish" },
    { label: "Kyrgyz", value: "kyrgyz" },
    { label: "Lao", value: "lao" },
    { label: "Latin", value: "latin" },
    { label: "Latvian", value: "latvian" },
    { label: "Lingala", value: "lingala" },
    { label: "Lithuanian", value: "lithuanian" },
    { label: "Macedonian", value: "macedonian" },
    { label: "Maithili", value: "maithili" },
    { label: "Malagasy", value: "malagasy" },
    { label: "Malay", value: "malay" },
    { label: "Malayalam", value: "malayalam" },
    { label: "Mandarin", value: "mandarin" },
    { label: "Mandinka", value: "mandinka" },
    { label: "Marathi", value: "marathi" },
    { label: "Mende", value: "mende" },
    { label: "Mongolian", value: "mongolian" },
    { label: "Nahuatl", value: "nahuatl" },
    { label: "Navajo", value: "navajo" },
    { label: "Nepali", value: "nepali" },
    { label: "Norwegian", value: "norwegian" },
    { label: "Ojibwa", value: "ojibwa" },
    { label: "Oriya", value: "oriya" },
    { label: "Oromo", value: "oromo" },
    { label: "Pashto", value: "pashto" },
    { label: "Persian", value: "persian" },
    { label: "Polish", value: "polish" },
    { label: "Portuguese", value: "portuguese" },
    { label: "Punjabi", value: "punjabi" },
    { label: "Quechua", value: "quechua" },
    { label: "Romani", value: "romani" },
    { label: "Romanian", value: "romanian" },
    { label: "Russian", value: "russian" },
    { label: "Samoan", value: "samoan" },
    { label: "Sanskrit", value: "sanskrit" },
    { label: "Serbian", value: "serbian" },
    { label: "Shona", value: "shona" },
    { label: "Sindhi", value: "sindhi" },
    { label: "Sinhala", value: "sinhala" },
    { label: "Sinhalese", value: "sinhalese" },
    { label: "Slovak", value: "slovak" },
    { label: "Slovene", value: "slovene" },
    { label: "Slovenian", value: "slovenian" },
    { label: "Somali", value: "somali" },
    { label: "Songhay", value: "songhay" },
    { label: "Spanish", value: "spanish" },
    { label: "Swahili", value: "swahili" },
    { label: "Swazi", value: "swazi" },
    { label: "Swedish", value: "swedish" },
    { label: "Tachelhit", value: "tachelhit" },
    { label: "Tagalog", value: "tagalog" },
    { label: "Taiwanese", value: "taiwanese" },
    { label: "Tajiki", value: "tajiki" },
    { label: "Tamil", value: "tamil" },
    { label: "Tatar", value: "tatar" },
    { label: "Telugu", value: "telugu" },
    { label: "Thai", value: "thai" },
    { label: "Tibetic Languages", value: "tibetic languages" },
    { label: "Tigrigna", value: "tigrigna" },
    { label: "Tok Pisin", value: "tok pisin" },
    { label: "Tonga", value: "tonga" },
    { label: "Tsonga", value: "tsonga" },
    { label: "Tswana", value: "tswana" },
    { label: "Tuareg", value: "tuareg" },
    { label: "Turkish", value: "turkish" },
    { label: "Turkmen", value: "turkmen" },
    { label: "Ukrainian", value: "ukrainian" },
    { label: "Urdu", value: "urdu" },
    { label: "Uyghur", value: "uyghur" },
    { label: "Uzbek", value: "uzbek" },
    { label: "Vietnamese", value: "vietnamese" },
    { label: "Warlpiri", value: "warlpiri" },
    { label: "Welsh", value: "welsh" },
    { label: "Wolof", value: "wolof" },
    { label: "Xhosa", value: "xhosa" },
    { label: "Yakut", value: "yakut" },
    { label: "Yiddish", value: "yiddish" },
    { label: "Yoruba", value: "yoruba" },
    { label: "Yucatec", value: "yucatec" },
    { label: "Zapotec", value: "zapotec" },
    { label: "Zulu", value: "zulu" },
];
