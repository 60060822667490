import BackdropLoader from "@/components/common/BackdropLoader";
import DateRange from "@/components/common/DateRange";
import HtmlEditor from "@/components/common/HtmlEditor";
import SelectDropdown from "@/components/common/SelectDropdown";
import { WarmupModal } from "@/components/email_warmup/summary/WarmupModal";
import DatePicker from "react-datepicker";
import ReactHtmlParser from "react-html-parser";
import FirstPaginationIcon from "@/components/icons/FirstPaginationIcon";
import LastPaginationIcon from "@/components/icons/LastPaginationIcon";
import Pagination from "@mui/material/Pagination";
import { PaginationItem } from "@mui/material";
import RelativeModal from "./RelativeModal";

import {
    CampainSvg,
    Compose,
    Delete,
    Engaged,
    FilterSvg,
    ForwardSvg,
    ImportantIcon,
    NoPreview,
    ReloadEmails,
    ReplySvg,
    Sent,
    UnReadIcon,
    Alarm,
} from "@/components/icons/InboxIcon";
import useAiEngagement from "@/hooks/useAIEngagement";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import NoMailBoxButton from "../NoMailBoxButton";
import { toStylelessDocument } from "@/utils/common";

const initialDefaultEmail = {
    emailBody: "",
    emailSubject: "",
    toEmail: "",
    from: "",
    ccEmail: "",
    bccEmail: "",
};

const initialState = {
    activeState: "engaged",
    apiEndPoint: "inbox",
    isFilter: false,
    loading: false,
    mailData: {},
    mailList: [],
    fetchEmails: false,
    appendProperty: false,
    currentPageNo: 1,
    isMoreDataToLoad: true,
    isComposeEmail: false,
    emailBody: "",
    isCCOn: false,
    isBCCOn: false,
    currentSendType: "",
    scheduledDateTime: null,
    draftMailData: {
        ...initialDefaultEmail,
    },
};

const defaultColor = ["#eaf180", "#fdce94", "#fdcf8c", "#dbd186", "#e6d788", "#eaef76", "#b4eeb4", "#cfead9", "#ffb2d7", "#cbb2ff"];

const stringCheck = (str) => {
    let i = 0;
    while (i < str.length) {
        if (/[a-zA-Z]/.test(str[i])) {
            return str[i];
        }
        i++;
    }
    return false;
};

const reterivStringName = (senderName) => {
    const splitedName = (senderName || "").split(" ");
    let firstChar = "",
        secondChar = "";
    if (splitedName[0]) {
        const reult = stringCheck(splitedName[0]);
        if (reult) {
            firstChar = reult;
        }
    }
    if (splitedName[1]) {
        const reult = stringCheck(splitedName[1]);
        if (reult) {
            secondChar = reult;
        }
    }

    return firstChar + secondChar;
};

const retiverEmailAddressFromName = (name) => {
    const startingIndex = (name || "").indexOf("<") || "";
    const endingIndex = (name || "").indexOf(">") || "";

    return (name || "").substring(startingIndex + 1, endingIndex);
};

function formatDate(dateString, type = "both") {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = date.toLocaleString("en-US", { month: "short", timeZone: "UTC" });
    const monthNumber = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear(); // Get the year in UTC
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");

    const period = hours >= 12 ? "pm" : "am";
    const hour12 = hours % 12 || 12;

    return type === "dateonly" ? `${year}-${monthNumber}-${day}` : `${day} ${month}, ${hour12}:${minutes} ${period}`;
}

const initialSearchState = {
    dateRang: [null, null],
    campainId: "",
    emailAddress: "",
    emailList: [],
    campainList: [],
};
const Inbox = () => {
    const { getEmailListAccordingToType, sendEmail, getEmailsData, getEmailCampain, reFetchAllEmail, markEmailAsRead } = useAiEngagement();

    const [state, setState] = useState(initialState);
    const [openFilter, setOpenFilter] = useState(false);
    const [isNoMailBox, setIsNoMailBox] = useState(false);
    const [fiterOption, setFilterOption] = useState(initialSearchState);
    const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
    const [page, setPage] = React.useState(1);

    const { dateRang, campainId, emailAddress, emailList, campainList } = fiterOption || {};

    const {
        activeState,
        loading,
        mailList,
        mailData,
        apiEndPoint,
        isMoreDataToLoad,
        fetchEmails,
        currentPageNo,
        isComposeEmail,
        draftMailData,
        isBCCOn,
        isCCOn,
        currentSendType,
        isFilter,
        scheduledDateTime,
    } = state || {};

    const { emailBody, toEmail, ccEmail, bccEmail, emailSubject, from } = draftMailData || {};

    const setActiveStateData = (type, endPoint) => {
        setState((prevState) => ({
            ...prevState,
            activeState: type,
            apiEndPoint: endPoint,
            mailList: [],
            mailData: {},
            fetchEmails: true,
            appendProperty: false,
            currentPageNo: 1,
            isMoreDataToLoad: true,
        }));
    };

    const convertInStandardStructureData = (emailArray = []) => {
        let updatedEmailList = [];
        (emailArray || [])?.forEach((i) => {
            let updateObj = {};
            const cName = (campainList || [])?.find((e) => e._id === i?.campaignId)?.campaignName || "";
            if (i?.emailAccountType !== "gmail") {
                updateObj = {
                    id: i?._id,
                    senderEmail: i?.sender?.emailAddress?.address || "",
                    senderName: i?.sender?.emailAddress?.name || "",
                    subject: i?.subject,
                    sentDateAndTime: formatDate(i?.sentDateTime ?? ""),
                    bodyText: i?.body.content,
                    bgColor: defaultColor[Math.floor(Math.random() * 10)],
                    reciverEmail: i?.receiverEmailAddress,
                    campaignName: cName ? cName : "",
                    campaignId: i?.campaignId ? i?.campaignId : "",
                    shortName: reterivStringName(i?.sender?.emailAddress?.address || ""),
                    isGmail: false,
                    isRead: i?.isRead === true || false,
                };
            } else {
                const result = retiverEmailAddressFromName(i?.headers?.from);
                updateObj = {
                    id: i?._id,
                    senderEmail: result || "",
                    senderName: i?.headers?.from || "",
                    subject: i?.headers?.subject,
                    sentDateAndTime: formatDate(i?.headers?.date ?? ""),
                    bodyText: i?.body,
                    bgColor: defaultColor[Math.floor(Math.random() * 10)],
                    reciverEmail: i?.receiverEmailAddress,
                    campaignName: cName ? cName : "",
                    campaignId: i?.campaignId ? i?.campaignId : "",
                    shortName: reterivStringName(i?.headers?.from || ""),
                    isGmail: true,
                    isRead: i?.isRead === true || false,
                };
            }
            updatedEmailList.push(updateObj);
        });
        return updatedEmailList;
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const fetchEmailListAccordingToType = async () => {
        try {
            // setState((prevState) => ({ ...prevState, fetchEmails: true }));
            console.log("maildata", mailData);
            let limit = Math.round(window.innerHeight / 64);
            const response = await getEmailListAccordingToType({
                queryString: `?page=${currentPageNo}&limit=${limit}&campaignId=${campainId}&mailBoxAccount=${emailAddress?.split("_")[0] ? emailAddress?.split("_")[0] : ""}&fromDate=${dateRang?.[0] ? formatDate(dateRang?.[0], "dateonly") : ""}&toDate=${dateRang?.[1] ? formatDate(dateRang?.[1], "dateonly") : ""}&is_gmail=${emailAddress?.split("_")[1] ? emailAddress?.split("_")[1] : ""}&filter=${isFilter}`,
                endPoint: apiEndPoint,
            });
            if ((response?.data?.emails || [])?.length > 0) {
                const updatedList = convertInStandardStructureData(response?.data?.emails);
                setState((prevState) => {
                    let updatedEmailList = cloneDeep(updatedList);

                    if (prevState?.appendProperty) {
                        updatedEmailList = (prevState?.mailList || []).concat(updatedEmailList || []);
                    }

                    return {
                        ...prevState,
                        mailList: updatedEmailList || [],
                        isMoreDataToLoad: prevState.currentPageNo < response?.data?.totalPages,
                    };
                });
            }
        } catch (error) {
            console.log("Error", error);
        } finally {
            setState((prevState) => ({ ...prevState, fetchEmails: false }));
        }
    };
    const reFetchEmails = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const response = await reFetchAllEmail();
            if (response?.status === true) {
                toast.success(response?.message, {
                    theme: "colored",
                });
            } else {
                toast.error(response?.message, {
                    theme: "colored",
                });
            }
        } catch (error) {
            console.log("Error", error);
        } finally {
            setState((prevState) => ({ ...prevState, loading: false, fetchEmails: true }));
        }
    };
    const mailSend = async () => {
        if (!toEmail) {
            toast.error("Sender Email required", {
                theme: "colored",
            });
            return;
        }
        if (!emailSubject) {
            toast.error("Email Subject is required", {
                theme: "colored",
            });
            return;
        }
        const fromEmail = currentSendType === "forward" ? mailData?.reciverEmail : from;
        if (!fromEmail) {
            toast.error("From Email required", {
                theme: "colored",
            });
            return;
        }

        try {
            const isGmail = emailList.find((emailAcc) => emailAcc?.emailAddress === fromEmail).account === "gmail";
            const mailInfo = {
                from: fromEmail,
                to: [toEmail],
                subject: emailSubject,
                content: emailBody,
                is_gmail: isGmail,
                scheduledDateTime: scheduledDateTime ? scheduledDateTime.toISOString() : undefined,
            };
            setState((prevState) => ({ ...prevState, loading: true }));
            const response = await sendEmail(mailInfo);

            if (response?.status === true) {
                toast.success(response?.message, {
                    theme: "colored",
                });
                setState((prevState) => ({
                    ...prevState,
                    isComposeEmail: false,
                    isBCCOn: false,
                    isCCOn: false,
                    draftMailData: { ...initialDefaultEmail },
                }));
            } else {
                toast.error(response?.message, {
                    theme: "colored",
                });
            }
        } catch (error) {
            console.log("Error", error);
        } finally {
            setState((prevState) => ({ ...prevState, loading: false }));
        }
    };

    const fetchEmailList = async () => {
        console.log("maildata from fetchEmailList", mailData);
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const listData = await getEmailsData();
            console.log("listData in fetchEmailList", listData);
            if (listData.data && !listData.data.length > 0) {
                setIsNoMailBox(true);
            }
            let updatedListData = listData?.data;
            console.log("updatedListData", updatedListData);
            if (listData?.data || false) {
                setFilterOption((prevState) => {
                    return {
                        ...prevState,
                        emailList: updatedListData || [],
                    };
                });
            }
            if (updatedListData) {
                setState((prevState) => ({
                    ...prevState,
                    draftMailData: { ...prevState.draftMailData, from: updatedListData[0]?.emailAddress },
                }));
            }
        } catch (error) {
            console.log("-error", error);
        } finally {
            setState((prevState) => ({ ...prevState, loading: false }));
        }
    };
    const fetchEmailCampain = async () => {
        try {
            // setState((prevState) => ({ ...prevState, loading: true }));
            const listData = await getEmailCampain();
            if (listData?.data?.response || false) {
                setFilterOption((prevState) => {
                    let updatedListData = listData?.data?.response;
                    return {
                        ...prevState,
                        campainList: updatedListData || [],
                    };
                });
            }
        } catch (error) {
            console.log("-error", error);
        } finally {
            // setState((prevState) => ({ ...prevState, loading: false }));
            setOpenFilter(true);
        }
    };

    const scrollEvent = (e) => {
        if (e?.currentTarget?.contains(e?.target)) {
            const tolerance = 1; // Allowable tolerance in pixels
            const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) <= tolerance;
            if (bottom && isMoreDataToLoad && fetchEmails === false) {
                setState((prevState) => {
                    return {
                        ...prevState,
                        fetchEmails: true,
                        appendProperty: true,
                        currentPageNo: prevState.currentPageNo + 1,
                    };
                });
            }
        }
    };

    const clearScheduledTimeAndCloseModal = () => {
        setOpenDateTimePicker(false);
        setTimeout(() => {
            setState((prevState) => ({ ...prevState, scheduledDateTime: null }));
        }, 1000);
    };

    const handleMailClick = async (mailData) => {
        if (mailData?.isRead === false) {
            mailData.isRead = true;
            await markEmailAsRead(mailData);
        }
        setState((prevState) => ({
            ...prevState,
            mailData: { ...mailData, bodyText: mailData?.bodyText },
            isComposeEmail: false,
        }));
    };

    useEffect(() => {
        if (fetchEmails) {
            fetchEmailListAccordingToType();
        }
    }, [fetchEmails]);

    useEffect(() => {
        fetchEmailList();
        fetchEmailListAccordingToType();
    }, []);
    return (
        <>
            {loading ? (
                <BackdropLoader active={true} />
            ) : isNoMailBox ? (
                <NoMailBoxButton />
            ) : (
                <div className="grid grid-cols-6 gap-0 h-100vh">
                    <div className="mb-4 h-full border-r border-stone-250 bg-white col-span-1 pt-[24px] sm:mb-1">
                        <p
                            className={`mail_service compose bg-[#4873FA] text-white cursor-pointer mx-[24px]`}
                            onClick={() =>
                                setState((prevState) => ({
                                    ...prevState,
                                    isComposeEmail: true,
                                    currentSendType: "new",
                                    draftMailData: { ...initialDefaultEmail },
                                }))
                            }
                        >
                            <Compose />
                            Compose Email
                        </p>
                        <hr />
                        <p
                            className={`mail_service !bg-[#f6f6f6] ${activeState === "engaged" ? " active" : ""} mx-[24px] mt-[12px]`}
                            onClick={() => setActiveStateData("engaged", "inbox")}
                        >
                            <Engaged />
                            Inbox
                        </p>
                        <p
                            className={`mail_service${activeState === "unread" ? " active" : ""} !pl-[12px] mx-[24px]`}
                            onClick={() => setActiveStateData("unread", "unread")}
                        >
                            <UnReadIcon />
                            Unread Replies
                        </p>
                        <p
                            className={`mail_service${activeState === "sent" ? " active" : ""} !pl-[12px] mx-[24px]`}
                            onClick={() => setActiveStateData("sent", "sent")}
                        >
                            <Sent />
                            Sent
                        </p>
                        <hr />
                        <p
                            className={`mail_service${activeState === "important" ? " active" : ""} !pl-[12px] mx-[24px] mt-[6px]`}
                            onClick={() => setActiveStateData("important", "important")}
                        >
                            <ImportantIcon />
                            Important
                        </p>
                        {/* <p className={`mail_service${activeState === "snoozed" ? " active" : ""}`} onClick={() => setActiveStateData("snoozed", "snoozed")}><Snoozed />Snoozed</p> */}
                        {/* <p className={`mail_service${activeState === "reminder" ? " active" : ""}`} onClick={() => setActiveStateData("reminder", "inbox")}><Reminder />Reminders</p> */}
                        {/* <p className={`mail_service${activeState === "schedule" ? " active" : ""}`} onClick={() => setActiveStateData("schedule", "scheduled")}><Scheduled />Scheduled</p> */}
                        {/* <p className={`mail_service${activeState === "archived" ? " active" : ""}`} onClick={() => setActiveStateData("archived", "archived")}><Archived />Archived</p> */}
                    </div>

                    <div className={`mb-4 h-full border-r border-stone-250 sm:mb-1 col-span-2`}>
                        <div className="p-[18px] flex items-center justify-between ">
                            <span className="text-[#050505] text-[16px] font-normal font-['Outfit']">Unibox</span>
                            {/* <span onClick={() => setOpenFilter(true)}><FilterSvg /></span> */}
                            <div className="flex gap-2 items-center mb-1">
                                <span className=" p-[3px] flex items-start rounded	 cursor-pointer" onClick={() => reFetchEmails()}>
                                    <ReloadEmails />
                                </span>
                                <div className="relative">
                                    <span className="p-[3px] flex items-start cursor-pointer" onClick={() => fetchEmailCampain()}>
                                        <FilterSvg />
                                    </span>
                                    <RelativeModal open={openFilter} onClose={() => setOpenFilter(false)}>
                                        <div className="w-full flex items-center border-b-[1px] pb-[20px] border-[#00000026] justify-between">
                                            <span className="text-[#090c05] text-[1.05rem] font-semibold font-['Outfit']">
                                                Filter options
                                            </span>
                                            <button
                                                className="rounded-[8px] text-base flex items-center gap-3 py-1 px-4 border-0 focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-white  text-[#4873FA] bg-white focus:border-white hover:text-[#4873FA]"
                                                onClick={() =>
                                                    setFilterOption((prevState) => ({
                                                        ...prevState,
                                                        campainId: "",
                                                        emailAddress: "",
                                                        dateRang: [null, null],
                                                    }))
                                                }
                                            >
                                                Clear
                                            </button>
                                        </div>
                                        <div className="min-w-[300px]">
                                            <div className="flex flex-col items-center justify-center my-[20px] gap-4">
                                                <div className="font-Outfit text-base w-full text-left text-black">
                                                    <div className="pb-2">Email Campaign</div>
                                                    <SelectDropdown
                                                        options={
                                                            campainList?.length
                                                                ? (campainList || []).map((i) => {
                                                                      return {
                                                                          label: i?.campaignName,
                                                                          value: i?._id,
                                                                      };
                                                                  })
                                                                : []
                                                        }
                                                        selectedOption={campainId}
                                                        onChange={(value) =>
                                                            setFilterOption((prevState) => ({ ...prevState, campainId: value }))
                                                        }
                                                    />
                                                </div>
                                                <div className="font-Outfit text-base w-full text-left text-black">
                                                    <div className="pb-2">Email Account</div>
                                                    <SelectDropdown
                                                        options={
                                                            emailList?.length
                                                                ? (emailList || []).map((i) => {
                                                                      return {
                                                                          label: i?.emailAddress,
                                                                          value: `${i?.emailAddress}_${i?.account === "gmail" ? true : false}`,
                                                                      };
                                                                  })
                                                                : []
                                                        }
                                                        selectedOption={emailAddress}
                                                        onChange={(value) =>
                                                            setFilterOption((prevState) => ({ ...prevState, emailAddress: value }))
                                                        }
                                                    />
                                                </div>
                                                <div className="font-Outfit text-base flex flex-col	w-full text-left text-black">
                                                    <div className="pb-2">Reply Date Range</div>
                                                    <DateRange
                                                        value={dateRang}
                                                        onChange={(value) =>
                                                            setFilterOption((prevState) => ({ ...prevState, dateRang: value }))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex justify-start w-fit mt-2 gap-[16px]">
                                                <button
                                                    className="rounded-[8px] min-w-[142px] flex justify-center text-[1.1rem] items-center gap-3 py-[12px] px-[24px] border focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-white  text-[#090c05] bg-white border-[#090c05] focus:border-white hover:text-[#090c05]"
                                                    onClick={() => setOpenFilter(false)}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="rounded-[8px] min-w-[142px] py-[12px] text-[1.1rem] flex justify-center items-center gap-3 px-[24px] border focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-[#090c05]  text-[#fff] bg-[#090c05] border-[#090c05] focus:border-[#090c05] hover:text-[rgb(255,255,255)]"
                                                    onClick={() => {
                                                        setState((prevState) => ({
                                                            ...initialState,
                                                            activeState: prevState?.activeState,
                                                            apiEndPoint: prevState?.apiEndPoint,
                                                            isFilter: true,
                                                            fetchEmails: true,
                                                        }));
                                                        // fetchEmailListAccordingToType()
                                                        setOpenFilter(false);
                                                    }}
                                                >
                                                    Apply filters
                                                </button>
                                            </div>
                                        </div>
                                    </RelativeModal>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="mailList_container" onScrollCapture={scrollEvent}>
                            {(mailList || []).map((i, ind) => {
                                const isSelected = mailData?.bodyText === i?.bodyText;
                                return (
                                    <div
                                        className={`mail_cover_container cursor-pointer hover:bg-gray-200 ${
                                            i?.isRead ? "bg-[#F6F6F6]" : "bg-white shadow"
                                        }`}
                                        key={ind}
                                        onClick={() => handleMailClick(i)}
                                    >
                                        {i?.campaignName ? (
                                            <div className="flex justify-end">
                                                <div className="campain_container">
                                                    <CampainSvg />
                                                    <span title={i?.campaignName}>{i?.campaignName || ""}</span>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="mail_cover">
                                            <span style={{ backgroundColor: i?.bgColor }}>{i?.shortName ?? ""}</span>
                                            <div>
                                                <p className={`${i?.isRead ? " text-[16px] font-normal" : "sender"}`}>
                                                    {i?.senderName || ""}
                                                </p>
                                                <p className={`max-w-[255px] title`}>{i?.subject || ""}</p>
                                            </div>
                                        </div>
                                        <div className="flex justify-end mr-3">
                                            <span className="time">{i?.sentDateAndTime || ""}</span>
                                        </div>
                                    </div>
                                );
                            })}
                            {fetchEmails && <div className="w-100 flex justify-center">Fetching Email Data ...</div>}
                            {/* <Pagination
                                className="font-Outfit"
                                count={Math.ceil(mailData.length / 10)}
                                page={page}
                                onChange={handleChangePage}
                                showFirstButton
                                showLastButton
                                boundaryCount={1}
                                siblingCount={0}
                                renderItem={(item) => (
                                    <PaginationItem slots={{ first: FirstPaginationIcon, last: LastPaginationIcon }} {...item} />
                                )}
                                sx={{
                                    "& .MuiPaginationItem-text": {
                                        fontFamily: "Outfit",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: 1.43,
                                        color: "#090C05",
                                    },
                                    "& .MuiPaginationItem-text.Mui-selected": {
                                        color: "#fff",
                                        backgroundColor: "black",
                                        "&:hover": {
                                            backgroundColor: "gray",
                                        },
                                    },
                                }}
                            /> */}
                        </div>
                    </div>

                    {isComposeEmail && (
                        <div className="w-full ">
                            <div
                                className="flex flex-row p-[20px] min-w-[640px]  items-center justify-start gap-x-2"
                                onClick={() =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        isComposeEmail: false,
                                        currentSendType: "new",
                                        draftMailData: { ...initialDefaultEmail },
                                    }))
                                }
                            >
                                {/* <Stared /> */}
                                <svg width="10" height="14" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 16L0 8L8 0L9.41667 1.41667L2.83333 8L9.41667 14.5833L8 16Z" fill="#4873FA" />
                                </svg>

                                <span className="cursor-pointer text-[#4873fa] text-[1.2rem] font-normal font-['Outfit']">Back</span>
                            </div>
                            <div className="bg-white min-w-[680px] text-black px-[20px] py-4 flex justify-between items-center">
                                <p
                                    className=" text-[#0d0d0d] text-xl font-normal font-['Outfit'] w-full truncate"
                                    title={mailData?.subject || ""}
                                >
                                    {mailData?.subject || ""}
                                </p>
                                <div className="flex justify-start w-fit mt-2 gap-2">
                                    <button
                                        onClick={() =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                isComposeEmail: true,
                                                currentSendType: "forward",
                                                draftMailData: {
                                                    ...initialDefaultEmail,
                                                    from: mailData?.reciverEmail,
                                                    toEmail: mailData?.senderEmail,
                                                    emailSubject: mailData?.subject,
                                                },
                                            }))
                                        }
                                    >
                                        <ReplySvg iconColor={"#3D3D3D"} />
                                    </button>
                                    <button
                                        onClick={() =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                isComposeEmail: true,
                                                currentSendType: "forward",
                                                draftMailData: {
                                                    ...prevState.draftMailData,
                                                    from: mailData?.reciverEmail,
                                                    emailBody: mailData?.bodyText,
                                                    emailSubject: mailData?.subject,
                                                },
                                            }))
                                        }
                                    >
                                        <ForwardSvg iconColor={"#3D3D3D"} />
                                    </button>
                                </div>
                            </div>
                            {mailData?.senderName && (
                                <div className="flex min-w-[680px] px-[20px] justify-between items-center gap-2">
                                    <div className="flex flex-row justify-start items-center gap-[12px]">
                                        <div className="mail_cover">
                                            <span style={{ backgroundColor: mailData?.bgColor }}>{mailData?.shortName ?? ""}</span>
                                        </div>
                                        {/* <img src={custom5} className='w-[44px] h-[44px] me-5' /> */}
                                        <p className="text-[#0d0d0d] text-[16px] font-medium font-['Outfit'] me-2">
                                            {mailData?.senderName || ""}
                                        </p>
                                    </div>
                                    <div className="flex flex-row items-center justify-start gap-[2px]">
                                        <span className="text-[12px] capitalize">{mailData?.sentDateAndTime?.split(",")[0] || ""}</span>
                                        <span>,</span>
                                        <span className="text-[12px] uppercase">{mailData?.sentDateAndTime?.split(",")[1] || ""}</span>
                                    </div>
                                </div>
                            )}
                            <div className="h-full min-w-[680px] px-[20px] rounded-[8px] border-r border-stone-250  sm:mb-1 p-2 col-span-3">
                                <div class="h-14 px-4 py-[15px] w-full rounded-t-[8px] bg-[#f6f6f6] rounded-tl-lg rounded-tr-lg flex-col justify-start items-start gap-2 inline-flex">
                                    <div class="self-stretch justify-between items-center inline-flex">
                                        <div class="justify-start items-center gap-2.5 flex">
                                            <div class="text-[#090c05] text-base font-medium font-['Outfit']">New Message</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-row justify-between border-1 px-[16px] min-h-[40px] items-end border-b-0 border-stone-250 ">
                                    <div className="flex flex-row justify-start items-center">
                                        <div className=" flex items-center justify-start p-1">
                                            <span className="text-[#b2b2b2] text-base font-normal font-['Outfit'] ">To</span>
                                        </div>
                                        <div className="min-w-[500px] p-1">
                                            <input
                                                className="focus:outline-none border-0 text-[#b2b2b2] text-base font-normal font-['Outfit']  mail_title_text pl-2 h-full"
                                                value={toEmail}
                                                onChange={(e) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        draftMailData: { ...prevState.draftMailData, toEmail: e.target.value },
                                                    }))
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className=" flex justify-end gap-x-3 items-center p-1 pr-2">
                                        <span
                                            className={`cursor-pointer text-[#6c757d] text-base font-normal font-['Outfit'] ${isCCOn ? "font-bold" : ""}`}
                                            onClick={() => setState((prevState) => ({ ...prevState, isCCOn: !isCCOn }))}
                                        >
                                            Cc
                                        </span>
                                        <span
                                            className={`cursor-pointer text-[#6c757d] text-base font-normal font-['Outfit'] ${isBCCOn ? "font-bold" : ""}`}
                                            onClick={() => setState((prevState) => ({ ...prevState, isBCCOn: !isBCCOn }))}
                                        >
                                            Bcc
                                        </span>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-between px-[16px] items-end border-1 border-b-0 border-stone-250 ">
                                    <div className="flex flex-row justify-start pt-[12px] items-end">
                                        <div className=" flex items-center justify-center p-1">
                                            <span className="text-[#b2b2b2] text-base font-normal font-['Outfit'] ">From</span>
                                        </div>
                                        <div className=" p-0 !pb-1">
                                            <SelectDropdown
                                                options={
                                                    emailList?.length
                                                        ? (emailList || []).map((i) => {
                                                              return {
                                                                  label: i?.emailAddress,
                                                                  value: i?.emailAddress,
                                                              };
                                                          })
                                                        : []
                                                }
                                                selectedOption={from}
                                                disabled={currentSendType === "forward"}
                                                onChange={(fromEmail) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        draftMailData: { ...prevState.draftMailData, from: fromEmail },
                                                    }))
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                {isCCOn && (
                                    <div className="grid grid-cols-12 border-1 border-b-0 border-stone-250 ">
                                        <div className="col-span-1 bg-[#E8E7E7] flex items-center justify-center p-1">
                                            <span className="font-bold ">CC :</span>
                                        </div>
                                        <div className="col-span-9 p-0">
                                            <input
                                                className="focus:outline-none border-0 mail_title_text pl-2 h-full"
                                                value={ccEmail}
                                                onChange={(e) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        draftMailData: { ...prevState.draftMailData, ccEmail: e.target.value },
                                                    }))
                                                }
                                            />
                                        </div>
                                    </div>
                                )}

                                {isBCCOn && (
                                    <div className=" flex flex-row justify-start items-center border-1 border-b-0 border-stone-250 ">
                                        <div className=" bg-[#E8E7E7] flex items-center justify-center p-1">
                                            <span className="font-bold ">BCC :</span>
                                        </div>
                                        <div className=" p-0">
                                            <input
                                                className="focus:outline-none border-0 mail_title_text pl-2 h-full"
                                                value={bccEmail}
                                                onChange={(e) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        draftMailData: { ...prevState.draftMailData, bccEmail: e.target.value },
                                                    }))
                                                }
                                            />
                                        </div>
                                    </div>
                                )}

                                <div className="flex flex-row min-h-[40px] justify-start items-end px-[16px] border-1 border-b-0 border-stone-250">
                                    <div className="flex items-center justify-start p-1">
                                        <span className="text-[#b2b2b2] text-base font-normal font-['Outfit']">Subject</span>
                                    </div>
                                    <div className="min-w-[500px] pb-[0.15rem]">
                                        <input
                                            className="focus:outline-none items-center text-[#b2b2b2] text-base font-normal font-['Outfit'] border-0 mail_title_text pl-2 h-full"
                                            value={emailSubject}
                                            onChange={(e) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    draftMailData: { ...prevState.draftMailData, emailSubject: e.target.value },
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="bg-[#F6F6F6]">
                                    <div className="flex items-center mb-5">
                                        <HtmlEditor
                                            value={emailBody}
                                            onChange={(value) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    draftMailData: { ...prevState.draftMailData, emailBody: value },
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-start w-fit mt-2 gap-1">
                                    {" "}
                                    {/* <button
                                        className="rounded-lg py-1 flex items-center gap-3 px-4 border  font-Outfit leading-[1.4] ml-auto   text-[#fff] bg-[#4873FA] border-[#4873FA] hover:text-[#fff]"
                                        onClick={() => {
                                            setState((prevState) => ({ ...prevState, scheduledDateTime: new Date() }));
                                            setOpenDateTimePicker(true);
                                        }}
                                    >
                                        <Alarm />
                                        Schedule Send
                                    </button> */}
                                    <button
                                        className="rounded-[8px] py-1 flex items-center gap-3 px-4 border focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-[#4873FA]  text-[#fff] bg-[#4873FA] border-[#4873FA] focus:border-[#4873FA] hover:text-[#fff]"
                                        onClick={() => mailSend("new")}
                                    >
                                        {/* <ForwardSvg /> */}
                                        Send Now
                                    </button>
                                    {/* <button
                                        className="rounded-[8px] flex items-center gap-3 py-1 px-4 border focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-white  text-[#4873FA] bg-white border-[#4873FA] focus:border-white hover:text-[#4873FA]"
                                        onClick={() =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                isComposeEmail: false,
                                                currentSendType: "new",
                                                draftMailData: { ...initialDefaultEmail },
                                            }))
                                        }
                                    >
                                        Cancel
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    )}
                    {Object.keys(mailData || {})?.length && !isComposeEmail ? (
                        <div className="mb-4 h-full border-r border-stone-250 col-span-3">
                            <div className="flex flex-row p-[20px]  items-center justify-start gap-x-2">
                                {/* <Stared /> */}
                                <svg width="10" height="14" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 16L0 8L8 0L9.41667 1.41667L2.83333 8L9.41667 14.5833L8 16Z" fill="#4873FA" />
                                </svg>

                                <span
                                    className="cursor-pointer text-[#4873fa] text-[1.2rem] font-normal font-['Outfit']"
                                    onClick={() => setState((prevState) => ({ ...prevState, mailData: {} }))}
                                >
                                    Back
                                </span>
                            </div>
                            <div className="bg-white text-black px-[20px] py-4 flex justify-between items-center">
                                <p
                                    className=" text-[#0d0d0d] text-xl font-normal font-['Outfit'] w-full truncate"
                                    title={mailData?.subject || ""}
                                >
                                    {mailData?.subject || ""}
                                </p>
                                <div className="flex justify-start w-fit mt-2 gap-2">
                                    <button
                                        onClick={() =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                isComposeEmail: true,
                                                currentSendType: "forward",
                                                draftMailData: {
                                                    ...initialDefaultEmail,
                                                    from: mailData?.reciverEmail,
                                                    toEmail: mailData?.senderEmail,
                                                    emailSubject: mailData?.subject,
                                                },
                                            }))
                                        }
                                    >
                                        <ReplySvg iconColor={"#3D3D3D"} />
                                    </button>
                                    <button
                                        onClick={() =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                isComposeEmail: true,
                                                currentSendType: "forward",
                                                draftMailData: {
                                                    ...prevState.draftMailData,
                                                    from: mailData?.reciverEmail,
                                                    emailBody: mailData?.bodyText,
                                                    emailSubject: mailData?.subject,
                                                },
                                            }))
                                        }
                                    >
                                        <ForwardSvg iconColor={"#3D3D3D"} />
                                    </button>
                                </div>
                            </div>
                            {/* Mail body display div */}
                            <div className="flex flex-col gap-2 items-start justify-center px-[20px]">
                                <div className="bg-white h-full max-h-[62.5vh] mail_content_conatiner">
                                    <div className="flex justify-between items-center mb-5 gap-2">
                                        <div className="flex flex-row justify-start items-center gap-[12px]">
                                            <div className="mail_cover">
                                                <span style={{ backgroundColor: mailData?.bgColor }}>{mailData?.shortName ?? ""}</span>
                                            </div>
                                            {/* <img src={custom5} className='w-[44px] h-[44px] me-5' /> */}
                                            <div className="text-[#0d0d0d] text-[16px] font-medium font-['Outfit'] me-2">
                                                <p>{mailData?.senderName || ""}</p>
                                                <p className="text-[#8d8b8b] text-[16px] font-medium font-['Outfit'] me-2">
                                                    {mailData?.senderEmail || ""}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex flex-row items-center justify-start gap-[2px]">
                                            <span className="text-[12px] capitalize">{mailData?.sentDateAndTime.split(",")[0] || ""}</span>
                                            <span>,</span>
                                            <span className="text-[12px] uppercase">{mailData?.sentDateAndTime.split(",")[1] || ""}</span>
                                        </div>
                                    </div>
                                    <div
                                        className="text-[14px] break-words"
                                        // dangerouslySetInnerHTML={{
                                        //     __html: toStylelessDocument(mailData?.bodyText),
                                        // }}
                                    >
                                        {ReactHtmlParser(toStylelessDocument(mailData.bodyText))}
                                    </div>
                                </div>
                                <div className="flex justify-start w-fit mt-2 gap-2">
                                    <button
                                        className="rounded-[8px] py-1 flex items-center gap-3 px-4 border focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-[#4873FA]  text-[#fff] bg-[#4873FA] border-[#4873FA] focus:border-[#4873FA] hover:text-[rgb(255,255,255)]"
                                        onClick={() =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                isComposeEmail: true,
                                                currentSendType: "forward",
                                                draftMailData: {
                                                    ...initialDefaultEmail,
                                                    from: mailData?.reciverEmail,
                                                    toEmail: mailData?.senderEmail,
                                                    emailSubject: mailData?.subject,
                                                },
                                            }))
                                        }
                                    >
                                        <ReplySvg iconColor={"white"} />
                                        Reply
                                    </button>
                                    <button
                                        className="rounded-[8px] flex items-center gap-3 py-1 px-4 border focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-white  text-[#4873FA] bg-white border-[#4873FA] focus:border-white hover:text-[#4873FA]"
                                        onClick={() =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                isComposeEmail: true,
                                                currentSendType: "forward",
                                                draftMailData: {
                                                    ...prevState.draftMailData,
                                                    from: mailData?.reciverEmail,
                                                    emailBody: mailData?.bodyText,
                                                    emailSubject: mailData?.subject,
                                                },
                                            }))
                                        }
                                    >
                                        Forward
                                        <ForwardSvg iconColor={"#4873FA"} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : isComposeEmail === false ? (
                        <div className="mb-4 h-full border-r border-stone-250 pt-4 sm:mb-1 p-2 col-span-3">
                            <div className="no_mail_found flex justify-center items-center">
                                <NoPreview />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            )}
            <WarmupModal
                open={openDateTimePicker}
                onClose={clearScheduledTimeAndCloseModal}
                titleClassName="w-full border-b-2 border-gray-300"
                title={<div>Schedule Reply</div>}
            >
                <div className="h-72">
                    <DatePicker
                        shouldCloseOnSelect={false}
                        open={true}
                        timeIntervals={5}
                        showTimeSelect
                        dateFormat="MM-dd-yyyy hh:mm a"
                        startDate={new Date()}
                        selected={new Date(scheduledDateTime || "")}
                        timeFormat="hh:mm a"
                        value={scheduledDateTime}
                        onChange={(value) => setState((prevState) => ({ ...prevState, scheduledDateTime: value || new Date() }))}
                        isClearable={true}
                        maxDate={new Date().setDate(new Date().getDate() + 14)}
                        minDate={new Date()}
                        clearButtonTitle="Reset"
                    />
                </div>
                <div className="flex justify-around w-fit mt-2 gap-6">
                    <button
                        className="rounded-lg py-1 text-base flex items-center gap-3 px-4 border focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-[#4873FA]  text-[#fff] bg-[#4873FA] border-[#4873FA] focus:border-[#4873FA] hover:text-[rgb(255,255,255)]"
                        onClick={() => {
                            setOpenDateTimePicker(false);
                            mailSend("new");
                        }}
                    >
                        Schedule Send
                    </button>
                    <button
                        className="rounded-lg flex text-base items-center gap-3 py-1 px-4  focus:bg-white font-Outfit leading-[1.4] ml-auto border-[#4873FA] border  text-[#4873FA] bg-white hover:text-[#4873FA]"
                        onClick={clearScheduledTimeAndCloseModal}
                    >
                        Cancel
                    </button>
                </div>
            </WarmupModal>
            {/* <WarmupModal
                open={openFilter}
                onClose={() => {
                    setOpenFilter(false);
                }}
                titleClassName="w-full"
                title={
                    <>
                        <div className="w-full flex justify-between">
                            <span>Filter options</span>
                            <button
                                className="rounded-[8px] text-base flex items-center gap-3 py-1 px-4 border-0 focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-white  text-[#4873FA] bg-white focus:border-white hover:text-[#4873FA]"
                                onClick={() =>
                                    setFilterOption((prevState) => ({
                                        ...prevState,
                                        campainId: "",
                                        emailAddress: "",
                                        dateRang: [null, null],
                                    }))
                                }
                            >
                                Clear
                            </button>
                        </div>
                    </>
                }
            >
                <div className="w-full">
                    <div className="font-Outfit text-base	 p-1 text-left text-black">
                        Email Campaign
                        <SelectDropdown
                            options={
                                campainList?.length
                                    ? (campainList || []).map((i) => {
                                          return {
                                              label: i?.campaignName,
                                              value: i?._id,
                                          };
                                      })
                                    : []
                            }
                            selectedOption={campainId}
                            onChange={(value) => setFilterOption((prevState) => ({ ...prevState, campainId: value }))}
                        />
                    </div>
                    <div className="font-Outfit text-base	 p-1 text-left text-black">
                        Email Account
                        <SelectDropdown
                            options={
                                emailList?.length
                                    ? (emailList || []).map((i) => {
                                          return {
                                              label: i?.emailAddress,
                                              value: `${i?.emailAddress}_${i?.account === "gmail" ? true : false}`,
                                          };
                                      })
                                    : []
                            }
                            selectedOption={emailAddress}
                            onChange={(value) => setFilterOption((prevState) => ({ ...prevState, emailAddress: value }))}
                        />
                    </div>
                    <div className="font-Outfit text-base flex flex-col	 p-1 text-left text-black">
                        Reply Date Range
                        <DateRange
                            value={dateRang}
                            onChange={(value) => setFilterOption((prevState) => ({ ...prevState, dateRang: value }))}
                        />
                    </div>
                    <div className="flex justify-start w-fit mt-2 gap-1">
                        <button
                            className="rounded-[8px] py-1 text-base flex items-center gap-3 px-4 border focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-[#4873FA]  text-[#fff] bg-[#4873FA] border-[#4873FA] focus:border-[#4873FA] hover:text-[rgb(255,255,255)]"
                            onClick={() => {
                                setState((prevState) => ({
                                    ...initialState,
                                    activeState: prevState?.activeState,
                                    apiEndPoint: prevState?.apiEndPoint,
                                    isFilter: true,
                                    fetchEmails: true,
                                }));
                                // fetchEmailListAccordingToType()
                                setOpenFilter(false);
                            }}
                        >
                            Save
                        </button>

                        <button
                            className="rounded-[8px] flex text-base items-center gap-3 py-1 px-4 border focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-white  text-[#4873FA] bg-white border-[#4873FA] focus:border-white hover:text-[#4873FA]"
                            onClick={() => setOpenFilter(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </WarmupModal> */}
        </>
    );
};

export default Inbox;
