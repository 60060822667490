import * as React from "react";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { getShortName, indexString } from "@/utils/common";
import Chip from "@mui/material/Chip";
import BusinessIcon from "@mui/icons-material/Business";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import RiskIcon from "@mui/icons-material/AssistantPhotoOutlined";
import WarningOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import { ReactComponent as CopyGrayIcon } from "@/assets/image/search/copy2.svg";
import { ReactComponent as PhoneBlackIcon } from "@/assets/image/search/detail_page_phone.svg";
import { ReactComponent as MailBlackIcon } from "@/assets/image/search/detail_page_mail.svg";
import { toast } from "react-toastify";
import { ReactSVG } from "react-svg";
import TwitterIcon from "@/assets/image/search/twitter.svg";
import FacebookIcon from "@/assets/image/search/facebook.svg";
import LinkedinIcon from "@/assets/image/search/linkedin.svg";
import { Link } from "react-router-dom";

export default function ContactDetailsModal({ open, setOpen, rowData }) {
    const [imageLoaded, setImageLoaded] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                toast.success("Copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
                toast.error("Failed to copy to clipboard!");
            });
    };

    return (
        <div>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <div className="min-w-[800px]">
                    <div className="flex items-center justify-between p-6 border-b border-[#E7E7E7]">
                        <h2 className="text-lg font-semibold">Details</h2>
                        <div onClick={toggleDrawer(false)}>
                            <CloseIcon className="text-dgray-500 cursor-pointer hover:text-dgray-600" />
                        </div>
                    </div>
                    <div className="p-6">
                        <div className="border border-[#00000021] rounded-lg overflow-hidden">
                            <div className="px-5 py-4 bg-[#F6F6F6] border-b border-dgray-800">
                                <p className="font-Outift text-black text-base font-medium">Contact Details</p>
                            </div>
                            <div className="px-4 py-6 border-b border-b border-dgray-800">
                                <div className="flex flex-row items-center gap-4 mb-6">
                                    <div className="flex">
                                        {rowData?.profilePictureUrl ? (
                                            <img src={rowData?.profilePictureUrl} width={60} height={60} alt={rowData?.profilePictureUrl} />
                                        ) : (
                                            <div
                                                className={
                                                    "text-[20px] font-OutfitMedium flex h-[60px] w-[60px] items-center p-1 justify-center rounded-full " +
                                                    [
                                                        " bg-accents-yellow text-black",
                                                        " bg-accents-purple text-black",
                                                        " bg-accents-green text-black",
                                                        " bg-accents-blue text-black",
                                                        " bg-accents-orange text-black",
                                                    ][indexString(rowData?.full_name, 5)]
                                                }
                                            >
                                                {getShortName(rowData?.full_name)}
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="flex gap-2 items-center">
                                            <p className="capitalize text-md font-Outfit text-[#494949]">{rowData?.full_name}</p>
                                            <div className="flex flex-row gap-2 pl-2 pt-0.5">
                                                {typeof rowData.linkedin_url === "string" ? (
                                                    <Link target="_blank" to={"https://" + rowData.linkedin_url}>
                                                        <ReactSVG src={LinkedinIcon} style={{ height: 13, width: 13 }} />
                                                    </Link>
                                                ) : null}
                                                {typeof rowData.twitter_url === "string" ? (
                                                    <Link target="_blank" to={"https://" + rowData.twitter_url}>
                                                        <ReactSVG src={TwitterIcon} style={{ height: 13, width: 13 }} />
                                                    </Link>
                                                ) : null}
                                                {typeof rowData.facebook_url === "string" ? (
                                                    <Link target="_blank" to={"https://" + rowData.facebook_url}>
                                                        <ReactSVG src={FacebookIcon} style={{ height: 13, width: 13 }} />
                                                    </Link>
                                                ) : null}
                                            </div>
                                        </div>
                                        <p className="capitalize text-base font-Outfit text-[#494949] font-extralight">
                                            {rowData?.job_title}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-4">
                                    {rowData?.job_title_role && (
                                        <div className="bg-[#F6F6F6] rounded-[4px] py-1 px-4 min-w-[150px] font-Outfit">
                                            <p className="text-[#5D5D5D] text-[12px]">Department</p>
                                            <h3 className="text-[#050505] font-medium mt-1 text-[14px] capitalize">
                                                {rowData?.job_title_role}
                                            </h3>
                                        </div>
                                    )}
                                    {rowData?.job_title_levels?.length > 0 && (
                                        <div className="bg-[#F6F6F6] rounded-[4px] py-1 px-4 min-w-[150px] font-Outfit">
                                            <p className="text-[#5D5D5D] text-[12px]">Level</p>
                                            <h3 className="text-[#050505] font-medium mt-1 text-[14px] capitalize">
                                                {rowData?.job_title_levels?.join(", ")}
                                            </h3>
                                        </div>
                                    )}
                                    {rowData?.location && (
                                        <div className="bg-[#F6F6F6] rounded-[4px] py-1 px-4 min-w-[150px] font-Outfit">
                                            <p className="text-[#5D5D5D] text-[12px]">Country</p>
                                            <h3 className="text-[#050505] font-medium mt-1 text-[14px] capitalize">
                                                {rowData?.location.split(",")[rowData?.location?.split(",")?.length - 1] || ""}
                                            </h3>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex gap-20">
                                <div className="flex flex-col gap-6 min-w-[280px]">
                                    <div className="p-4 font-Outfit flex flex-col gap-6">
                                        <div className="flex items-center gap-2">
                                            <PhoneBlackIcon /> <p className="text-base font-medium text-stone-950">Phone</p>
                                        </div>
                                        {rowData?.enrichedPhones?.length > 0 && (
                                            <div className="flex flex-col gap-4">
                                                {rowData.enrichedPhones.map((item, key) => {
                                                    return (
                                                        <div key={key} className="flex items-center gap-8 justify-between">
                                                            <div className="flex items-center gap-2">
                                                                <p className="text-base font-light text-stone-950">{item.phone_number}</p>
                                                                <div>
                                                                    {item.status === "valid" ? (
                                                                        <Chip
                                                                            icon={
                                                                                <BusinessIcon className="!text-green-700 !text-[16px] " />
                                                                            }
                                                                            label="Direct dial"
                                                                            size="small"
                                                                            className="!bg-green-100 !text-green-700 !px-2 !py-1"
                                                                            style={{ fontSize: 12, fontFamily: "Outfit", borderRadius: 4 }}
                                                                        />
                                                                    ) : null}
                                                                    {item.status === "hq" ? (
                                                                        <Chip
                                                                            icon={<BusinessIcon className="!text-blue-700 !text-[16px]" />}
                                                                            label="Headquarters"
                                                                            size="small"
                                                                            className="!bg-blue-100 !text-blue-700 !px-2 !py-1"
                                                                            style={{ fontSize: 12, fontFamily: "Outfit", borderRadius: 4 }}
                                                                        />
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <CopyGrayIcon
                                                                className="w-[20px] h-[20px] cursor-pointer"
                                                                onClick={() => copyToClipboard(item.phone_number)}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}

                                        {rowData?.isPhoneFound === false && (
                                            <div className="text-sm text-gray-950">Phone not available</div>
                                        )}
                                    </div>
                                    <div className="p-4 font-Outfit flex flex-col gap-6">
                                        <div className="flex items-center gap-2">
                                            <MailBlackIcon /> <p className="text-base font-medium text-stone-950">Email</p>
                                        </div>
                                        {rowData?.enrichedEmails?.length > 0 && (
                                            <div className="flex flex-col gap-4">
                                                {rowData?.enrichedEmails.map((item, key) => {
                                                    return (
                                                        <div key={key} className="flex items-center gap-8 justify-between">
                                                            <div className="flex items-center gap-2">
                                                                <p className="text-base font-light text-stone-950">{item.email}</p>
                                                                <div>
                                                                    {item.type === "Valid" ? (
                                                                        <Chip
                                                                            icon={
                                                                                <CheckCircleIcon className="!text-green-700 !text-[16px]" />
                                                                            }
                                                                            label="Valid"
                                                                            size="small"
                                                                            className="!bg-green-100 !text-green-700 !px-1 !px-2 !py-1"
                                                                            style={{ fontSize: 12, fontFamily: "Outfit", borderRadius: 4 }}
                                                                        />
                                                                    ) : null}
                                                                    {item.type === "Personal" ? (
                                                                        <Chip
                                                                            icon={
                                                                                <CheckCircleIcon className="!text-blue-700 !text-[16px]" />
                                                                            }
                                                                            label="Personal"
                                                                            size="small"
                                                                            className="!bg-blue-100 !text-blue-700 !px-2 !py-1"
                                                                            style={{ fontSize: 12, fontFamily: "Outfit", borderRadius: 4 }}
                                                                        />
                                                                    ) : null}
                                                                    {item.type === "Risky" ? (
                                                                        <Chip
                                                                            icon={<RiskIcon className="!text-red-700 !text-[16px]" />}
                                                                            label="High Risk"
                                                                            size="small"
                                                                            className="!bg-red-100 !text-red-700 !px-2 !py-1"
                                                                            style={{ fontSize: 12, fontFamily: "Outfit", borderRadius: 4 }}
                                                                        />
                                                                    ) : null}
                                                                    {item.type === "Unknown" ? (
                                                                        <Chip
                                                                            icon={
                                                                                <WarningOutlinedIcon className="!text-yellow-700 !font-[14px]" />
                                                                            }
                                                                            label="Suspicious"
                                                                            size="small"
                                                                            className="!bg-yellow-100 !text-yellow-700 !px-2 !py-1"
                                                                            style={{ fontSize: 12, fontFamily: "Outfit", borderRadius: 4 }}
                                                                        />
                                                                    ) : null}
                                                                    {item.type === "Do not email" ? (
                                                                        <Chip
                                                                            icon={<RiskIcon className="!text-red-700 !font-[14px]" />}
                                                                            label="Do Not Email"
                                                                            size="small"
                                                                            className="!bg-red-100 !text-red-700 !px-2 !py-1"
                                                                            style={{ fontSize: 12, fontFamily: "Outfit", borderRadius: 4 }}
                                                                        />
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <CopyGrayIcon
                                                                className="w-[20px] h-[20px]  cursor-pointer"
                                                                onClick={() => copyToClipboard(item.email)}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        {rowData?.isEmailFound === false && (
                                            <div className="text-sm text-gray-950">Email not available</div>
                                        )}
                                    </div>
                                </div>
                                <div className="p-4 pr-8 flex flex-col gap-6">
                                    <p className="text-base font-medium text-stone-950">Work History</p>
                                    <div className="flex gap-4">
                                        <div className="flex h-[40px] w-[40px] shrink-0 ">
                                            <img
                                                src={`https://logo.clearbit.com/${rowData?.job_company_website}`}
                                                alt="Logo"
                                                className="relative object-contain object-center"
                                                onLoad={() => setImageLoaded(true)}
                                                onError={() => setImageLoaded(false)}
                                                style={{
                                                    display: imageLoaded ? "block" : "none",
                                                }}
                                            />
                                            {imageLoaded === false && (
                                                <div
                                                    className={
                                                        "!text-[10px] font-OutfitMedium flex h-[30px] w-[30px] p-1 items-center justify-center rounded-full" +
                                                        [
                                                            " bg-accents-yellow text-black",
                                                            " bg-accents-purple text-black",
                                                            " bg-accents-green text-black",
                                                            " bg-accents-blue text-black",
                                                            " bg-accents-orange text-black",
                                                        ][indexString(rowData?.job_company_name, 5)]
                                                    }
                                                >
                                                    {rowData?.job_company_name ? getShortName(rowData?.job_company_name) : "-"}
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <p className="capitalize text-base font-medium text-[#050505]">{rowData?.job_company_name}</p>
                                            <p className="capitalize mt-1 text-base text-[#6D6D6D]" style={{ overflowWrap: "anywhere" }}>
                                                {rowData?.industry}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}
