import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function DailyCollectedProfiles({ newProfiles, newProfilesByDays, returningProfilesByDays, duration }) {
    const getDays = () => {
        const lastSevenDays = [];

        for (let i = 1; i <= duration; i++) {
            const date = new Date();
            date.setDate(date.getDate() - i);

            // Format the date as needed (optional)
            const formattedDate = date.toISOString().split("T")[0]; // YYYY-MM-DD format

            lastSevenDays.push(formattedDate);
        }
        return lastSevenDays;
    };

    const legendItems = [
        { color: "bg-accents-blue", label: "New Profiles" },
        { color: "bg-accents-gray", label: "Repeat Profiles" },
    ];

    const data = {
        labels: getDays(),
        datasets: [
            {
                label: "New Profiles",
                data: newProfilesByDays,
                backgroundColor: "#9AB2FF",
                borderColor: "#9AB2FF",
                borderRadius: 0,
                barThickness: 32,
            },
            {
                label: "Repeat Profiles",
                data: returningProfilesByDays,
                backgroundColor: "#E7E7E7",
                borderColor: "#E7E7E7",
                borderRadius: { topLeft: 8, topRight: 8, bottomLeft: 0, bottomRight: 0 },
                barThickness: 32,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                stacked: true,
                ticks: {
                    font: {
                        family: "Outfit",
                        size: 10,
                        weight: "400",
                    },
                    color: "#767676",
                },
                grid: {
                    display: false,
                },
            },
            x: {
                stacked: true,
                ticks: {
                    font: {
                        family: "Outfit",
                        size: 10,
                        weight: "400",
                    },
                    color: "#767676",
                },
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <section className="font-Outfit flex-1 rounded-[8px] border border-[#E7E7E7] p-6">
            <div className="flex items-center justify-between">
                <h2 className="text-[20px] font-medium text-[#050505)]">Daily Collected Profiles</h2>
                <p className="whitespace-nowrap text-[12px] xl:text-[14px] text-accents-blue2">
                    {Math.floor(newProfiles / duration)} Avg new per day
                </p>
            </div>
            <div className="flex flex-col mt-6 max-md:max-w-full">
                <div className="flex flex-col gap-4 md:flex-row md:items-center">
                    {legendItems.map((item, index) => (
                        <div className="flex gap-2 items-center">
                            <div className={`shrink-0 w-3 h-3 ${item.color} rounded-full`} />
                            <div className="whitespace-nowrap text-[12px] xl:text-[14px] text-[#050505]">{item.label}</div>
                        </div>
                    ))}
                </div>
                <div className="mt-6 w-full bg-white">
                    <Bar data={data} options={options} />
                </div>
            </div>
        </section>
    );
}

export default DailyCollectedProfiles;
