import React, { useState, useEffect } from "react";
import TrendCard from "./TrendCard";

const TrendDashboard = ({ newProfiles, returningProfiles }) => {
    const [newProfilePercentage, setNewProfilePercentage] = useState(0);
    const [returningProfilePercentage, setReturningProfilePercentage] = useState(0);

    useEffect(() => {
        const calculatePercentage = () => {
            const totalProfiles = newProfiles + returningProfiles;
            if (totalProfiles) {
                setNewProfilePercentage((newProfiles / totalProfiles) * 100);
                setReturningProfilePercentage((returningProfiles / totalProfiles) * 100);
            } else {
                setNewProfilePercentage(0);
                setReturningProfilePercentage(0);
            }
        };

        calculatePercentage();
    }, [newProfiles, returningProfiles]);

    const trendData = [
        {
            title: "New Profiles",
            value: newProfiles,
            percentage: newProfilePercentage,
            icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/2367b104f9468290ff52a541a12cbe75a319032eccf837e1c1b57447fb29280d?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79",
        },
        {
            title: "Returning Profiles",
            value: returningProfiles,
            percentage: returningProfilePercentage,
            icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ddde74a0e7dc76d030858ca14a9548576c3a17274ade893eb5c173ddd2376cd5?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79",
        },
        {
            title: "Total Site Views",
            value: 0,
            percentage: 0,
            icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/7119c1dc04fa3240f6d6d82daa695959a4619dff1a34d030053a2bb302aec2ba?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79",
        },
        {
            title: "Page Views",
            value: 0,
            percentage: 0,
            icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/7ced85a4b5c0e93e622e0465a55855f9f94481f0b94ec33a2a43552148086a32?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79",
        },
    ];

    return (
        <main className="font-Outfit flex flex-col gap-6 w-full">
            <header className="flex gap-2 items-center text-[18px] xl:text-[20px] font-medium whitespace-nowrap text-[#050505]">
                <h2>Trend</h2>
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/7cbe840346ea3bf8d058abab11710b8410b90ce1477550e59b32d1a253cada2f?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79"
                    className="object-contain shrink-0 w-5 aspect-square"
                    alt=""
                />
            </header>
            <section className="flex gap-5 max-md:flex-col">
                {trendData.map((item, index) => (
                    <TrendCard key={index} {...item} />
                ))}
            </section>
        </main>
    );
};

export default TrendDashboard;
