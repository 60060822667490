import { useState } from "react";
import ActionButtons from "./ActionButtons";
import CompanySize from "@/assets/image/icp-filters/headcount.svg";
import AreaChart from "@/assets/image/icp-filters/corporate_fare.svg";
import Location from "@/assets/image/icp-filters/location_on.svg";
import useICPFilters from "@/hooks/useICPFilters";
import { AccordianComponent } from "@/components/search/AccordianComponents";
import FilterAutocomplete from "@/components/common/FilterAutocomplete";
import { toast } from "react-toastify";
import DepartmentIcon from "@/assets/image/icp-filters/department.svg";
import BuildIcon from "@/assets/image/icp-filters/job_level.svg";
import { countryList, departmentList, industryList, levelList, numberOfEmployeesList } from "@/utils/filter";
import { ReactComponent as EditIcon } from "@/assets/image/edit.svg";

// const filterSections = [
//     { label: 'Company size', id: 'size', src: companySize },
//     { label: 'Industry', id: 'industry', src: areaChart },
//     { label: 'Location', id: 'location', src: location }
// ];

function ICPFilter({ filterOptions, iCPFilters, setICPFilters, level, setLevel, department, setDepartment }) {
    const [filterName, setFilterName] = useState(null);
    const [size, setSize] = useState(null);
    const [industry, setIndustry] = useState(null);
    const [location, setLocation] = useState(null);
    const { createICPFilter } = useICPFilters();

    const filterDuplicateValues = (arr) => {
        return arr.filter((obj, index, self) => self.findIndex((obj2) => obj2?.label == obj?.label) === index);
    };

    const employeesList = filterDuplicateValues([...filterOptions?.size, ...numberOfEmployeesList]);
    const filterIndustry = filterDuplicateValues([...filterOptions?.industry, ...industryList]);
    const locationList = filterDuplicateValues([...filterOptions?.location, ...countryList]);

    const saveICPFilter = async () => {
        if (filterName && (size || industry || location || department || level)) {
            const response = await createICPFilter({
                name: filterName,
                size: size?.label,
                industry: industry?.label,
                location: location?.label,
                department,
                level,
            });
            if (response) {
                toast.success("New ICP Added successfully");
                const tempFilters = iCPFilters;
                tempFilters.push({
                    name: filterName,
                    size: size?.label,
                    industry: industry?.label,
                    location: location?.label,
                    department,
                    level,
                });
                setICPFilters(tempFilters);
            } else {
                toast.error("Failed to add new ICP");
            }
        }
    };

    const discardChanges = () => {
        setFilterName(null);
        setSize(null);
        setIndustry(null);
        setLocation(null);
    };

    return (
        <div className="font-Outfit p-[24px] flex flex-col gap-12">
            <h2 className="text-[18px] xl:text-[20px] text-[#050505] font-medium leading-[1.2]">Edit your ICP filter</h2>
            <div className="flex flex-col gap-10 md:max-w-[1096px]">
                <div className="flex flex-col w-full gap-1">
                    <label htmlFor="filterName" className="flex gap-1 text-[14px] xl:text-[16px] leading-[1.2] text-[#090C05]">
                        <EditIcon />
                        <p>Name</p>
                    </label>
                    <input
                        id="filterName"
                        type="text"
                        className="p-4 w-full border border-[#E7E7E7] text-[14px] rounded-[8px] text-[#090C05] placeholder:text-[#B0B0B0]"
                        required
                        aria-required="true"
                        value={filterName}
                        placeholder="Give your filter a name"
                        onChange={(e) => {
                            setFilterName(e.target.value);
                        }}
                    />
                </div>
                <div className="flex flex-col md:flex-row gap-10 w-full">
                    <div className="w-full">
                        <p className="mb-6 text-[#888] text-[14px] xl:text-[16px] leading-[1.2]">Company Filters</p>
                        <div className="border rounded-[8px] border-[#E7E7E7]">
                            <div className="border-b border-[#E7E7E7]">
                                <AccordianComponent
                                    title="Headcount"
                                    icon={CompanySize}
                                    isAddBorder={false}
                                    // expand={isFromSavedFiltersPage && country?.length}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            clearTrigger={false}
                                            value={size}
                                            options={employeesList}
                                            onFilterChange={(v) => {
                                                setSize(v);
                                            }}
                                        />
                                    </div>
                                </AccordianComponent>
                            </div>
                            <div className="border-b border-[#E7E7E7]">
                                <AccordianComponent
                                    title="Industry"
                                    icon={AreaChart}
                                    isAddBorder={false}
                                    // expand={isFromSavedFiltersPage && country?.length}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            clearTrigger={false}
                                            value={industry}
                                            options={filterIndustry}
                                            onFilterChange={(v) => {
                                                setIndustry(v);
                                            }}
                                        />
                                    </div>
                                </AccordianComponent>
                            </div>
                            <div>
                                <AccordianComponent
                                    title="Location"
                                    icon={Location}
                                    isAddBorder={false}
                                    // expand={isFromSavedFiltersPage && country?.length}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            clearTrigger={false}
                                            value={location}
                                            options={locationList}
                                            onFilterChange={(v) => {
                                                setLocation(v);
                                            }}
                                        />
                                    </div>
                                </AccordianComponent>
                            </div>
                            {/* <FilterSection label="Company Size" src={CompanySize} options={filterOptions.size} setOption={setSize} />
                            <FilterSection label="Industry" src={AreaChart} options={filterOptions.industry} setOption={setIndustry} />
                            <FilterSection label="Location" src={Location} options={filterOptions.location} setOption={setLocation} /> */}
                        </div>
                    </div>
                    <div className="w-full">
                        <p className="mb-6 text-[#888] text-[14px] xl:text-[16px] leading-[1.2]">Job Filters</p>
                        <div className="border rounded-[8px] border-[#E7E7E7]">
                            <div className="border-b border-[#E7E7E7]">
                                <AccordianComponent
                                    title="Job Level"
                                    icon={BuildIcon}
                                    isAddBorder={false}
                                    // expand={isFromSavedFiltersPage && level?.length}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            multiple={true}
                                            clearTrigger={false}
                                            value={level}
                                            options={levelList}
                                            onFilterChange={(value) => {
                                                setLevel(value);
                                            }}
                                        />
                                    </div>
                                </AccordianComponent>
                            </div>
                            <div>
                                <AccordianComponent
                                    title="Department"
                                    icon={DepartmentIcon}
                                    isAddBorder={false}
                                    // expand={isFromSavedFiltersPage && jobDepartment?.length}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            multiple={true}
                                            value={department}
                                            options={departmentList}
                                            onFilterChange={(selectedDepartment) => {
                                                setDepartment(selectedDepartment);
                                                console.log(department);
                                            }}
                                        />
                                    </div>
                                </AccordianComponent>
                            </div>
                            {/* <FilterSection label="Company Size" src={CompanySize} options={filterOptions.size} setOption={setSize} />
                            <FilterSection label="Industry" src={AreaChart} options={filterOptions.industry} setOption={setIndustry} />
                            <FilterSection label="Location" src={Location} options={filterOptions.location} setOption={setLocation} /> */}
                        </div>
                    </div>
                </div>
                <ActionButtons saveICPFilter={saveICPFilter} discardChanges={discardChanges} />
            </div>
        </div>
    );
}

export default ICPFilter;
