import { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import useBulk from "@/hooks/useBulk";
import ConfirmDialog from "@/components/bulk/ConfirmDialog";
import FileUploaderWidget from "@/components/bulk/FileUploaderWidget";
import { ReactComponent as CsvSVGIcon } from "@/assets/image/bulk/csv.svg";
import { ReactComponent as ContactSVGIcon } from "@/assets/image/bulk/contact.svg";
import { ReactComponent as TableSearchSVG } from "@/assets/image/bulk/table_search.svg";
import { ReactComponent as TableLinkedinSVG } from "@/assets/image/bulk/table_linkedin.svg";
import { ReactComponent as InformationSVG } from "@/assets/image/bulk/info.svg";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { useQuery } from "@tanstack/react-query";
import AlertDialog from "../common/AlertDialog";
import useKompassRouter from "@/hooks/useKompassRouter";
import { ENRICHMENT_TYPE, KompassString, S3_URL } from "@/utils/constants";
import { goToTop, trimKeys } from "@/utils/common";
import MainLoader from "../MainLoader";
import { useNavigate } from "react-router-dom";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export default function BulkUploadWidget({ currentWidgetIndex, setCurrentWidgetIndex, userInfo }) {
    const { bulkEnrichByLinkedin, bulkEnrichBySearch } = useBulk();
    const [enrichOption, setEnrichOption] = useState(0); // 0: csv/xls/xlsx, 1: linkedin profile
    const [visibleConfirmDlg, setVisibleConfirmDlg] = useState(false);
    const [alertConfirmDlg, setVisibleAlertDlg] = useState(false);
    const [listName, setListName] = useState("");
    const [linkedinUrls, setLinkedinUrls] = useState([]);
    const [attachedFileInfo, setAttachedFileInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isEmailIncluded, setEmailIncluded] = useState(true);
    const [isPhoneIncluded, setPhoneIncluded] = useState(true);
    const getUsageData = useHTTPRequest("/credits/getUserCreditsUsed?", "GET");
    const { kompassNavigate } = useKompassRouter();

    const { data } = useQuery({
        queryKey: ["CreditsUsage"],
        queryFn: () => getUsageData(),
    });

    const alertMessage = () => {
        const emailPrice = userInfo?.arePersonalEmailsPriority ? 100 : 50;
        const phonePrice = 200;

        if (isEmailIncluded && !isPhoneIncluded) {
            return `${emailPrice} credits for each found email will be charged.`;
        }
        if (!isEmailIncluded && isPhoneIncluded) {
            return `${phonePrice} credits for each found phone will be charged.`;
        }
        if (isEmailIncluded && isPhoneIncluded) {
            return `${phonePrice} credits for each found phone  & ${emailPrice} credits for each found email will be charged.`;
        }
    };

    const options = [
        {
            icon: "csv",
            header: "I have a CSV to Upload",
            content: "Upload your own list of contacts and we’ll match profiles with accurate information",
        },
        {
            icon: "contact",
            header: "List of LinkedIn URL’s",
            content: "Give us a list of LinkedIn URL’s and we’ll find accurate information for each profile",
        },
    ];

    useEffect(() => {
        setAttachedFileInfo(null);
    }, [currentWidgetIndex]);

    const onOptionSelected = (option) => {
        setEnrichOption(option);
    };

    const onFinishClicked = async () => {
        if (listName?.length === 0) {
            toast.error(KompassString.BukUploadError("fill the list name"), {
                theme: "colored",
            });
        } else if (!linkedinUrls && currentWidgetIndex === 1) {
            toast.error(KompassString.BukUploadError("enter linkedin urls"), {
                theme: "colored",
            });
        } else if (!attachedFileInfo && currentWidgetIndex === 2) {
            toast.error(KompassString.BukUploadError("select a file"), {
                theme: "colored",
            });
        } else if (linkedinUrls?.length === 0 && enrichOption === 1) {
            toast.error(KompassString.BukUploadError("provide the LinkedIn URLs"), {
                theme: "colored",
            });
        } else if (attachedFileInfo?.inputs?.length > 500 && enrichOption === 0) {
            toast.error("Uploaded file contains more than 500 entries", {
                theme: "colored",
            });
        } else if (linkedinUrls?.length > 25 && enrichOption === 1) {
            toast.error("It is possible to enrich max 25 profiles at a time", {
                theme: "colored",
            });
        } else if (attachedFileInfo?.inputs?.length === 0 && enrichOption === 0) {
            toast.error(KompassString.BukUploadError("attach the File"), {
                theme: "colored",
            });
        } else if (!isEmailIncluded && !isPhoneIncluded) {
            toast.error("Please choose which field to enrich", {
                theme: "colored",
            });
        } else {
            if (attachedFileInfo?.inputs?.length > data?.data?.emails?.available) {
                setVisibleAlertDlg(true);
                setVisibleConfirmDlg(false);
            } else {
                setVisibleConfirmDlg(true);
            }
        }
    };

    const onBulkConfirmed = async () => {
        goToTop();
        setLoading(true);

        let enrichType;
        if (isEmailIncluded && !isPhoneIncluded) enrichType = ENRICHMENT_TYPE.EMAIL_ONLY;
        if (!isEmailIncluded && isPhoneIncluded) enrichType = ENRICHMENT_TYPE.PHONE_ONLY;
        if (isEmailIncluded && isPhoneIncluded) enrichType = ENRICHMENT_TYPE.ALL;
        if (!isEmailIncluded && !isPhoneIncluded) return;

        if (enrichOption === 0) {
            if (attachedFileInfo?.bulkType === "linkedin") {
                await bulkEnrichByLinkedin({
                    contactTitle: listName,
                    inputs: attachedFileInfo?.inputs?.map((input) => ({ url: input })),
                    enrichType,
                });
            }
            if (attachedFileInfo?.bulkType === "search") {
                await bulkEnrichBySearch({
                    contactTitle: listName,
                    inputs: attachedFileInfo?.inputs,
                    enrichType,
                });
            }
        } else if (enrichOption === 1) {
            await bulkEnrichByLinkedin({
                contactTitle: listName,
                inputs: linkedinUrls?.map((url) => ({ url })),
                enrichType,
            });
        }

        setTimeout(() => {
            setLoading(false);
            setCurrentWidgetIndex(3);
        }, 3000);
    };

    const extractLinkedinUrlsFromText = (value) => {
        setLinkedinUrls(value.split("\n"));
    };

    const handleFileContent = (fileName, bulkType, fileContent) => {
        let attachedInfo = {
            name: fileName,
            content: fileContent,
            bulkType,
        };

        const inputs = [];
        if (bulkType === "linkedin") {
            for (const input of fileContent) {
                const trimmedInput = trimKeys(input);
                inputs.push(trimmedInput?.LinkedinURLs);
            }
        }
        if (bulkType === "search") {
            for (const input of fileContent) {
                const trimmedInput = trimKeys(input);
                inputs.push({
                    name: trimmedInput?.Name,
                    company: trimmedInput?.Company,
                    location: trimmedInput?.Location,
                });
            }
        }
        attachedInfo["inputs"] = inputs;

        setAttachedFileInfo(attachedInfo);
    };

    const navigate = useNavigate();

    return (
        <>
            {loading && <MainLoader />}

            <div className="w-full  flex flex-col items-center justify-center mr-[0px]">
                <div className="flex flex-col w-full rounded-md my-6 ">
                    <div className="flex flex-col font-Outfit text-lef text-[#090c05] text-xl font-medium font-['Outfit'] leading-normal">
                        What format is your contact list in?
                    </div>
                    <div className=" w-full flex flex-col md:flex-row font-Outfit mt-10 text-center text-lg justify-start gap-4">
                        {options.map((option, index) => {
                            return (
                                <div
                                    onClick={() => {
                                        onOptionSelected(index);
                                    }}
                                    className="w-[224px] group justify-start rounded-t-lg gap-3 inline-flex bg-white border-[1px] !border-b-0 border-[#E6E6E6] px-4 py-3 transition-all duration-300 cursor-pointer hover:bg-[#000] aria-selected:bg-[#000]"
                                    aria-selected={index === enrichOption}
                                >
                                    {index === 0 ? (
                                        <svg
                                            className="shrink-0 group-aria-selected:fill-current group-aria-selected:text-white group-hover:fill-current group-hover:text-white transition-all duration-300"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <mask
                                                id="mask0_2335_13229"
                                                style={{ maskType: "alpha" }}
                                                maskUnits="userSpaceOnUse"
                                                x="0"
                                                y="0"
                                                width="20"
                                                height="20"
                                            >
                                                <rect width="20" height="20" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_2335_13229)">
                                                <path
                                                    d="M9.25 16V8.875L7.0625 11.0625L6 10L10 6L14 10L12.9375 11.0625L10.75 8.875V16H9.25ZM4 6V4.5C4 4.0875 4.14694 3.73438 4.44083 3.44063C4.73472 3.14688 5.08806 3 5.50083 3H14.5058C14.9186 3 15.2708 3.14688 15.5625 3.44063C15.8542 3.73438 16 4.0875 16 4.5V6H14.5V4.5H5.5V6H4Z"
                                                    fill="currentColor"
                                                />
                                            </g>
                                        </svg>
                                    ) : (
                                        <svg
                                            className="shrink-0 group-aria-selected:fill-current group-aria-selected:text-white group-hover:fill-current group-hover:text-white transition-all duration-300"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <mask
                                                id="mask0_2335_13234"
                                                style={{ maskType: "alpha" }}
                                                maskUnits="userSpaceOnUse"
                                                x="0"
                                                y="0"
                                                width="20"
                                                height="20"
                                            >
                                                <rect width="20" height="20" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_2335_13234)">
                                                <path
                                                    d="M6 7.5V6H17V7.5H6ZM6 10.75V9.25H17V10.75H6ZM6 14V12.5H17V14H6ZM3.75 7.5C3.55556 7.5 3.38194 7.42361 3.22917 7.27083C3.07639 7.11806 3 6.94097 3 6.73958C3 6.53819 3.07639 6.36458 3.22917 6.21875C3.38194 6.07292 3.55903 6 3.76042 6C3.96181 6 4.13542 6.07187 4.28125 6.21562C4.42708 6.35937 4.5 6.5375 4.5 6.75C4.5 6.94444 4.42812 7.11806 4.28437 7.27083C4.14062 7.42361 3.9625 7.5 3.75 7.5ZM3.75 10.75C3.55556 10.75 3.38194 10.6736 3.22917 10.5208C3.07639 10.3681 3 10.191 3 9.98958C3 9.78819 3.07639 9.61458 3.22917 9.46875C3.38194 9.32292 3.55903 9.25 3.76042 9.25C3.96181 9.25 4.13542 9.32188 4.28125 9.46563C4.42708 9.60938 4.5 9.7875 4.5 10C4.5 10.1944 4.42812 10.3681 4.28437 10.5208C4.14062 10.6736 3.9625 10.75 3.75 10.75ZM3.75 14C3.55556 14 3.38194 13.9236 3.22917 13.7708C3.07639 13.6181 3 13.441 3 13.2396C3 13.0382 3.07639 12.8646 3.22917 12.7188C3.38194 12.5729 3.55903 12.5 3.76042 12.5C3.96181 12.5 4.13542 12.5719 4.28125 12.7156C4.42708 12.8594 4.5 13.0375 4.5 13.25C4.5 13.4444 4.42812 13.6181 4.28437 13.7708C4.14062 13.9236 3.9625 14 3.75 14Z"
                                                    fill="currentColor"
                                                />
                                            </g>
                                        </svg>
                                    )}

                                    <div className="flex flex-col gap-2">
                                        <div className="font-Outfit text-base font-normal font-['Outfit'] leading-snug select-none self-stretch text-left text-gray-950 transition-all  duration-300 group-hover:text-white  group-aria-selected:text-white">
                                            {option.header}
                                        </div>
                                        {/* <div className="font-Outfit select-none text-left text-xl font-light text-gray-950 transition-all duration-300 group-hover:text-white group-aria-selected:text-white">
                                                {option.content}
                                            </div> */}
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {enrichOption === 0 && currentWidgetIndex === 1 && (
                        <div className="flex flex-col gap-8 border border-[#e7e7e7] p-6 rounded-tr-lg rounded-bl-lg rounded-br-lg ">
                            <div className="flex flex-col gap-4">
                                <p className="font-Outfit text-left  text-[#050505] text-xl font-semibold font-['Outfit'] leading-normal">
                                    What KompassAI Needs
                                </p>
                                <div className="flex flex-col gap-2 font-Outfit text-[#5d5d5d] text-base font-normal font-['Outfit'] leading-tight">
                                    <p>Please upload your contact list in CSV format.</p>
                                    <p>In order to match an accurate list of people with your data we suggest that each row have either:</p>
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row gap-4 md:gap-8 w-full md:space-between">
                                <TableSearchSVG />
                                <div className="my-auto flex items-center justify-center">
                                    <p className="font-Outfit text-[#050505] text-base font-normal font-['Outfit'] leading-tight">or</p>
                                </div>
                                <TableLinkedinSVG />
                            </div>
                            <hr class="border-t border-[#e7e7e7]" />
                            <div className="flex flex-col">
                                <p className="font-Outfit text-left text-[#050505] text-xl font-semibold font-['Outfit'] leading-normal mb-2">
                                    Use our template for the most accurate results
                                </p>
                                <span className="font-Outfit text-left text-[#5d5d5d] text-base font-normal font-['Outfit'] leading-tight mb-4">
                                    Download our CSV template for correct column format.
                                </span>
                                <button
                                    onClick={() => {
                                        window.open(S3_URL.KOMPASS_BULK_TEMPLATE, "_blank");
                                    }}
                                    className=" w-fit border border-[#d1d1d1] py-2 font-OutfitMedium px-4 flex items-center gap-2 text-black  whitespace-nowrap rounded-lg text-sm "
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_1348_8973" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                            <rect width="20" height="20" fill="#D9D9D9" />
                                        </mask>
                                        <g mask="url(#mask0_1348_8973)">
                                            <path
                                                d="M10 13L6 9L7.0625 7.9375L9.25 10.125V3H10.75V10.125L12.9375 7.9375L14 9L10 13ZM5.49417 16C5.08139 16 4.72917 15.8531 4.4375 15.5594C4.14583 15.2656 4 14.9125 4 14.5V13H5.5V14.5H14.5V13H16V14.5C16 14.9125 15.8531 15.2656 15.5592 15.5594C15.2653 15.8531 14.9119 16 14.4992 16H5.49417Z"
                                                fill="#1C1B1F"
                                            />
                                        </g>
                                    </svg>
                                    Kompass AI CSV Template
                                </button>
                                <div class="h-[33px] w-fit mt-[52px] p-2 bg-[#f6f6f6] rounded-lg flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div class="flex-col justify-center items-start gap-2 flex">
                                        <div class="text-[#090c05] text-sm font-normal font-['Outfit'] leading-[16.80px]">
                                            Note: You will only be charged for accurate matches.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {enrichOption === 0 && currentWidgetIndex === 2 && (
                        <div className="w-full flex flex-col items-center justify-center text-gray-950">
                            <div className="flex w-full flex-col border border-[#e7e7e7] p-6 rounded-tr-lg rounded-bl-lg rounded-br-lg gap-8">
                                <div className="flex flex-col gap-2">
                                    <span className="font-Outfit text-left text-[#050505] text-base font-normal font-['Outfit'] leading-tight">
                                        List name
                                    </span>
                                    <input
                                        onChange={(e) => {
                                            setListName(e.currentTarget.value);
                                        }}
                                        className="outline-none max-w-[428px] py-3 pl-4 pr-6 font-Outfit text-base w-full placeholder:text-stone-950 placeholder:opacity-40 placeholder:font-light border-[e7e7e7] border bg-white rounded-[8px]"
                                        placeholder="Enter list name"
                                    />
                                </div>
                                <hr className="border-[#e7e7e7]"></hr>
                                <div className="flex flex-col gap-2">
                                    <div className="flex items-center justify-between gap-1 font-Outfit ">
                                        <span className="text-base font-normal font-outfit">Or upload your file (Maximum 1000)</span>
                                        <span className="text-gray-500 text-base font-outfit">
                                            Uploader supports CSV, TXT, XLS, or XLSX
                                        </span>
                                    </div>
                                    {attachedFileInfo ? (
                                        <FileUploaderWidget attachedFileName={attachedFileInfo?.name} />
                                    ) : (
                                        <FileUploaderWidget onFileContent={handleFileContent} />
                                    )}
                                    <div className="flex flex-row items-center gap-1">
                                        <InformationSVG />

                                        <span className="text-[#888888] text-xs font-normal font-['Outfit'] leading-none">
                                            If your file contains multiple columns, we’ll automatically detect the one containing the email
                                            addresses
                                        </span>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-4 mt-8">
                                    <div className="flex flex-row items-center gap-1 font-Outfit text-base">
                                        <Checkbox
                                            checked={isEmailIncluded}
                                            onChange={(event) => {
                                                setEmailIncluded(event.currentTarget.checked);
                                            }}
                                            className="w-5 h-5"
                                            size="small"
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: "#888888" }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: "currentColor" }} fontSize="small" />}
                                        />
                                        <span className={isEmailIncluded ? "text-current" : "text-[#888888]"}>Email</span>
                                    </div>
                                    <div className="flex flex-row items-center gap-1 font-Outfit text-base">
                                        <Checkbox
                                            checked={isPhoneIncluded}
                                            onChange={(event) => {
                                                setPhoneIncluded(event.currentTarget.checked);
                                            }}
                                            className="w-5 h-5"
                                            size="small"
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: "#888888" }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon style={{ color: "currentColor" }} fontSize="small" />}
                                        />
                                        <span className={isPhoneIncluded ? "text-current" : "text-[#888888]"}>Phone</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {enrichOption === 1 && (
                        <div className="border border-[#e7e7e7] p-6 rounded-tr-lg rounded-bl-lg rounded-br-lg ">
                            <div className="flex flex-col gap-2 ">
                                <span className="font-Outfit text-left text-[#050505] text-base font-normal font-['Outfit'] leading-tight">
                                    List name
                                </span>
                                <input
                                    onChange={(e) => {
                                        setListName(e.currentTarget.value);
                                    }}
                                    className="outline-none max-w-[428px] py-3 pl-4 pr-6 font-Outfit text-base w-full placeholder:text-stone-950 placeholder:opacity-40 placeholder:font-light border-[e7e7e7] border bg-white rounded-[8px]"
                                    placeholder="Enter list name"
                                />
                            </div>
                            <div className="flex flex-col gap-2 mt-8">
                                <div className="flex justify-between items-center gap-1">
                                    <span className="text-base font-normal font-Outfit ">Enter the Linkedin URLs (Maximum 25)</span>
                                    <span className="text-base font-Outfit text-gray-500">One per line</span>
                                </div>
                                <textarea
                                    onChange={(e) => {
                                        extractLinkedinUrlsFromText(e.currentTarget.value);
                                    }}
                                    className="outline-none resize-none font-Outfit py-3 pl-4 pr-6 text-base w-full placeholder:text-stone-950 placeholder:opacity-40 placeholder:font-light border-stone-250 border bg-white rounded-[8px]"
                                    placeholder={`https://www.linkedin.com/in/maikal-yamauchi/\nhttps://www.linkedin.com/in/imogen-low/\n....`}
                                    rows={7}
                                />
                            </div>
                            <div className="flex flex-row gap-4 mt-8">
                                <div className="flex flex-row items-center gap-1 font-Outfit text-base">
                                    <Checkbox
                                        checked={isEmailIncluded}
                                        onChange={(event) => {
                                            setEmailIncluded(event.currentTarget.checked);
                                        }}
                                        className="w-5 h-5"
                                        size="small"
                                        icon={<CheckBoxOutlineBlankIcon style={{ color: "#888888" }} fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon style={{ color: "currentColor" }} fontSize="small" />}
                                    />
                                    <span className={isEmailIncluded ? "text-current" : "text-[#888888]"}>Email</span>
                                </div>
                                <div className="flex flex-row items-center gap-1 font-Outfit text-base">
                                    <Checkbox
                                        checked={isPhoneIncluded}
                                        onChange={(event) => {
                                            setPhoneIncluded(event.currentTarget.checked);
                                        }}
                                        className="w-5 h-5"
                                        size="small"
                                        icon={<CheckBoxOutlineBlankIcon style={{ color: "#888888" }} fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon style={{ color: "currentColor" }} fontSize="small" />}
                                    />
                                    <span className={isPhoneIncluded ? "text-current" : "text-[#888888]"}>Phone</span>
                                </div>
                            </div>
                            <div class="h-[33px] w-fit mt-[32px] p-2 bg-[#f6f6f6] rounded-lg flex-col justify-start items-start gap-2.5 inline-flex">
                                <div class="flex-col justify-center items-start gap-2 flex">
                                    <div class="text-[#090c05] text-sm font-normal font-['Outfit'] leading-[16.80px]">
                                        Note: You will only be charged for accurate matches.
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="flex flex-col gap-4">
                        {enrichOption === 0 && currentWidgetIndex === 1 && (
                            <div className="flex flex-row mt-10 justify-start gap-4">
                                <Button
                                    onClick={() => {
                                        setCurrentWidgetIndex(0);
                                        navigate("/bulkenrich");
                                    }}
                                    className="bg-white w-[110px] border border-[#d1d1d1] font-Outfit rounded-lg text-[#888888] text-base font-normal font-['Outfit'] leading-tight tracking-tight py-2 px-6 normal-case "
                                >
                                    Go back
                                </Button>
                                <Button
                                    onClick={() => {
                                        setCurrentWidgetIndex(2);
                                    }}
                                    className="w-[110px] text-white bg-[#050505] font-Outfit rounded-lg py-2 px-6 normal-case font-normal text-base leading-tight"
                                >
                                    Next
                                </Button>
                            </div>
                        )}

                        {enrichOption === 0 && currentWidgetIndex === 2 && (
                            <div className="flex flex-row mt-10 justify-start gap-4">
                                <Button
                                    onClick={() => {
                                        setCurrentWidgetIndex(1);
                                    }}
                                    className="bg-white w-[110px] border border-[#d1d1d1] font-Outfit rounded-lg text-[#888888] text-base font-normal font-['Outfit'] leading-tight tracking-tight py-2 px-6 normal-case "
                                >
                                    Go back
                                </Button>
                                <Button
                                    onClick={onFinishClicked}
                                    className="w-[110px] text-white bg-[#050505] font-Outfit rounded-lg py-2 px-6 normal-case font-normal text-base leading-tight"
                                >
                                    Finish
                                </Button>
                            </div>
                        )}
                        {enrichOption === 1 && (
                            <div className="flex flex-row mt-10 justify-start gap-4">
                                <Button
                                    onClick={() => {
                                        setCurrentWidgetIndex(0);
                                        navigate("/bulkenrich");
                                    }}
                                    className="bg-white w-[110px] border border-[#d1d1d1] font-Outfit rounded-lg text-[#888888] text-base font-normal font-['Outfit'] leading-tight tracking-tight py-2 px-6 normal-case "
                                >
                                    Go back
                                </Button>
                                <Button
                                    onClick={onFinishClicked}
                                    className="w-[110px] text-white bg-[#050505] font-Outfit rounded-lg py-2 px-6 normal-case font-normal text-base leading-tight"
                                >
                                    Finish
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <ConfirmDialog
                open={visibleConfirmDlg}
                message={alertMessage()}
                handleClose={() => {
                    setVisibleConfirmDlg(false);
                }}
                handleOK={() => {
                    setVisibleConfirmDlg(false);
                    onBulkConfirmed();
                }}
            />
            <AlertDialog
                open={alertConfirmDlg}
                message={KompassString.Asking4Credits}
                handleClose={() => {
                    setCurrentWidgetIndex(3);
                    setVisibleAlertDlg(false);
                }}
                handleOK={() => {
                    setVisibleAlertDlg(false);
                    kompassNavigate("/settings?tab=1");
                }}
            />
        </>
    );
}
