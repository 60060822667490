import React from "react";
import { useNavigate } from "react-router-dom";

function SearchProspectButton({ level, department, filterProps }) {
    const navigate = useNavigate();

    const goToKompassSearch = () => {
        const companyFilter = [];
        const industryFilter = [];
        const sizeFilter = [];
        console.log(filterProps);
        filterProps.forEach((obj) => {
            if (obj.companyName) companyFilter.push(obj.companyName);
            if (obj.companyIndustry) industryFilter.push(obj.companyIndustry);
            if (obj.employees) {
                let size = obj.employees.replace(" to ", "-");
                sizeFilter.push(size);
            }
        });
        navigate("/search", {
            state: { level, department, companyFilter, industryFilter, sizeFilter, isRedirectedFromWeb: true },
        });
    };
    return (
        <button
            className="font-Outfit whitespace-nowrap h-[40px] flex justify-center items-center px-6 text-[14px] xl:text-[16px] text-white capitalize rounded-[8px] bg-stone-950 max-md:px-5"
            onClick={goToKompassSearch}
        >
            Search Prospects
        </button>
    );
}

export default SearchProspectButton;
