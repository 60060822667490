import useAuth from "@/hooks/useAuth";

const useBulk = () => {
    const { getAuthToken } = useAuth();

    const bulkEnrichByLinkedin = async (params) => {
        console.log("===============bulkEnrichByLinkedin===============");
        const authToken = await getAuthToken();
        try {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/bulk/linkedin`, true);
            xhr.setRequestHeader("Authorization", `Bearer ${authToken}`);
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.send(JSON.stringify(params));
        } catch (e) {
            console.log(e);
        }
    };

    const bulkEnrichByLinkedinFetch = async (params) => {
        console.log("===============bulkEnrichByLinkedinFetch===============");
        let response = null;
        const authToken = await getAuthToken();

        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/bulk/linkedin`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(params),
            });

            // Check if the response is successful
            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                const data = await _resData.json();
                throw new Error(data.message);
            }
        } catch (e) {
            console.log(e.message);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const bulkEnrichBySearch = async (params) => {
        console.log("===============bulkEnrichBySearch===============");
        const authToken = await getAuthToken();
        try {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/bulk/search`, true);
            xhr.setRequestHeader("Authorization", `Bearer ${authToken}`);
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.send(JSON.stringify(params));
        } catch (e) {
            console.log(e);
        }
    };

    const bulkEnrichResults = async () => {
        console.log("===============bulkEnrichResult===============");
        // console.log(`params: ${JSON.stringify(params)}`);
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/bulk/result`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const bulkEnrichResultItem = async (itemId) => {
        console.log("===============bulkEnrichResultItem===============");
        // console.log(`params: ${JSON.stringify(params)}`);
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/bulk/result/${itemId}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const bulkEnrichOutputs = async (itemId) => {
        console.log("===============bulkEnrichOutputs===============");
        // console.log(`params: ${JSON.stringify(params)}`);
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/bulk/result/${itemId}`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log("ERROR in bulkEnrichOutputs", e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const bulkEnrichDeleteResult = async (itemId) => {
        console.log("===============bulkEnrichDeleteResult===============");
        // console.log(`params: ${JSON.stringify(params)}`);
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/bulk/result/${itemId}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    return {
        bulkEnrichBySearch,
        bulkEnrichByLinkedin,
        bulkEnrichByLinkedinFetch,
        bulkEnrichResults,
        bulkEnrichResultItem,
        bulkEnrichOutputs,
        bulkEnrichDeleteResult,
    };
};

export default useBulk;
