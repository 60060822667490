import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import Papa from "papaparse";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { CircularProgress, Pagination, PaginationItem, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import { visuallyHidden } from "@mui/utils";
import { kompassColors } from "@/theme/palette";
import MainLoader from "../MainLoader";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { ReactComponent as DeleteIcon } from "@/assets/image/bulk/delete.svg";
import { ReactComponent as SuccessIcon } from "@/assets/image/bulk/success.svg";
import { ReactComponent as DownloadIcon } from "@/assets/image/bulk/download.svg";
import { ReactComponent as ResultIcon } from "@/assets/image/bulk/result.svg";
import useBulk from "@/hooks/useBulk";
import FirstPaginationIcon from "@/components/icons/FirstPaginationIcon";
import LastPaginationIcon from "@/components/icons/LastPaginationIcon";
import SortIcon from "@/components/icons/SortIcon";
import CRMPushMenu from "../common/CRMElements/CRMPushMenu";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

// Helper functions
const csvDownload = async (fileName, content) => {
    const csvContent = [],
        headerItem = ["No"];
    headerItem.push("Linkedin Url");
    headerItem.push("Name", "Company", "Location");
    headerItem.push("Personal Emails");
    headerItem.push("Work Emails");
    headerItem.push("Risky Emails");
    headerItem.push("Unknown Emails");
    headerItem.push("Do not Emails");
    headerItem.push("Valid Phone Numbers");
    headerItem.push("HQ Phone Numbers");

    csvContent.push(headerItem);

    content.forEach((item, index) => {
        const record = [];

        record.push(index + 1);
        record.push(item?.linkedinUrl);
        record.push(item?.name, item?.companyName, item?.location);
        record.push(item?.["Personal Emails"]);
        record.push(item?.["Work Emails"]);
        record.push(item?.["Risky Emails"]);
        record.push(item?.["Unknown Emails"]);
        record.push(item?.["Do not Emails"]);
        record.push(
            item?.["Valid Phone Numbers"] ? item["Valid Phone Numbers"] : item?.phoneNumbers?.map((phone) => `\t${phone}`)?.join(",")
        );
        record.push(item?.["HQ Phone Numbers"]);
        csvContent.push(record);
    });

    const csv = Papa.unparse(csvContent);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", `${fileName}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const formattedDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = `${date.getDate().toString().padStart(2, "0")} ${date.toLocaleString("default", {
        month: "short",
    })} ${date.getFullYear()}`;
    return formattedDate;
};

function descendingComparator(a, b, orderBy) {
    let valueA, valueB;

    switch (orderBy) {
        case "status":
            valueA = getStatusValue(a);
            valueB = getStatusValue(b);
            break;
        default:
            valueA = a[orderBy];
            valueB = b[orderBy];
            break;
    }

    if (valueB == null && valueA == null) {
        return 0;
    } else if (valueB == null) {
        return -1;
    } else if (valueA == null) {
        return 1;
    } else if (valueB < valueA) {
        return -1;
    } else if (valueB > valueA) {
        return 1;
    } else {
        return 0;
    }
}

function getStatusValue(row) {
    if (row.error) {
        return 0; // Failed
    } else if (row.completed) {
        return 2; // Completed
    } else {
        return 1; // Processing
    }
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedArray = array.map((el, index) => [el, index]);
    stabilizedArray.sort((a, b) => {
        const orderResult = comparator(a[0], b[0]);
        if (orderResult !== 0) return orderResult;
        return a[1] - b[1];
    });
    return stabilizedArray.map((el) => el[0]);
}

// Head cells for the table
const headCells = [
    {
        id: "contactTitle",
        numeric: false,
        disablePadding: true,
        label: "Name",
    },
    {
        id: "numberOfContacts",
        numeric: false,
        disablePadding: true,
        label: "Total Records",
    },
    {
        id: "createdAt",
        numeric: false,
        disablePadding: true,
        label: "Date",
    },
    {
        id: "fileUploadType",
        numeric: false,
        disablePadding: true,
        label: "File Upload Type",
    },
    {
        id: "creditsUsed",
        numeric: false,
        disablePadding: true,
        label: "Credits Used",
    },
    {
        id: "status",
        numeric: false,
        disablePadding: true,
        label: "Status",
    },
    {
        id: "actions",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        sortable: false,
    },
];

// EnhancedTableHead component
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, numSelected, onSelectAllClick, rowCount } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const theme = useTheme();

    return (
        <TableHead
            sx={{
                "& .MuiTableCell-sizeMedium": {
                    padding: "3px 7px",
                    fontFamily: "Outfit",
                    fontSize: 13,
                    color: kompassColors.black,
                },
                "& .MuiTableRow-head": {
                    bgcolor: "#F6F6F6",
                },
            }}
        >
            <TableRow
                sx={{
                    "& .MuiTableCell-root:first-child": {
                        borderTopLeftRadius: "8px",
                    },
                    "& .MuiTableCell-root:last-child": {
                        borderTopRightRadius: "8px",
                    },
                    "& .MuiTableCell-root": {
                        borderColor: kompassColors.light,
                    },
                }}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < props.rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        sx={{
                            "&.Mui-checked": {
                                color: kompassColors.black,
                            },
                            "&.MuiCheckbox-indeterminate": {
                                color: kompassColors.black,
                                fill: kompassColors.black,
                            },
                            "& .MuiSvgIcon-root": {
                                fontSize: 16,
                            },
                        }}
                        inputProps={{
                            "aria-label": "select all results",
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align="left" padding="none">
                        {headCell.sortable === false ? (
                            headCell.label
                        ) : (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={SortIcon}
                                sx={{ display: "flex", alignItems: "center", gap: 1 }}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

// EnhancedTableToolbar component
function EnhancedTableToolbar(props) {
    const { numSelected, setSelected, onDownloadSelected, isDisabled, selectedData, downloadLoading, setCurrentWidgetIndex } = props;
    const navigate = useNavigate();

    return (
        <>
            <div className="flex items-center mt-5">
                <div className="font-Outfit font-semibold">{numSelected} selected</div>
                {numSelected > 0 ? (
                    <div className="flex items-center gap-4">
                        <button
                            onClick={() => {
                                setSelected([]);
                            }}
                            className="px-2 text-base text-center underline text-blue-500 cursor-pointer font-Outfit disabled:text-stone-350"
                        >
                            Clear All
                        </button>
                    </div>
                ) : null}
                <div className="flex flex-row items-center justify-start ml-auto gap-4">
                    <button
                        onClick={() => {
                            setCurrentWidgetIndex(1);
                            navigate("/bulkenrich/upload");
                        }}
                        className=" bg-[#050505] py-2 font-OutfitMedium px-4 flex items-center gap-2 text-white  whitespace-nowrap rounded-lg text-sm "
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="contact_page">
                                <mask id="mask0_2316_5673" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                    <rect id="Bounding box" width="20" height="20" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_2316_5673)">
                                    <path
                                        id="contact_page_2"
                                        d="M10.0026 11.668C10.4609 11.668 10.8533 11.5048 11.1797 11.1784C11.5061 10.852 11.6693 10.4596 11.6693 10.0013C11.6693 9.54297 11.5061 9.15061 11.1797 8.82422C10.8533 8.49783 10.4609 8.33464 10.0026 8.33464C9.54427 8.33464 9.15191 8.49783 8.82552 8.82422C8.49913 9.15061 8.33594 9.54297 8.33594 10.0013C8.33594 10.4596 8.49913 10.852 8.82552 11.1784C9.15191 11.5048 9.54427 11.668 10.0026 11.668ZM6.66927 15.0013H13.3359V14.5221C13.3359 14.1888 13.2457 13.8832 13.0651 13.6055C12.8845 13.3277 12.6345 13.1194 12.3151 12.9805C11.954 12.8277 11.5825 12.7096 11.2005 12.6263C10.8186 12.543 10.4193 12.5013 10.0026 12.5013C9.58594 12.5013 9.18663 12.543 8.80469 12.6263C8.42274 12.7096 8.05122 12.8277 7.6901 12.9805C7.37066 13.1194 7.12066 13.3277 6.9401 13.6055C6.75955 13.8832 6.66927 14.1888 6.66927 14.5221V15.0013ZM15.0026 18.3346H5.0026C4.54427 18.3346 4.15191 18.1714 3.82552 17.8451C3.49913 17.5187 3.33594 17.1263 3.33594 16.668V3.33464C3.33594 2.8763 3.49913 2.48394 3.82552 2.15755C4.15191 1.83116 4.54427 1.66797 5.0026 1.66797H11.6693L16.6693 6.66797V16.668C16.6693 17.1263 16.5061 17.5187 16.1797 17.8451C15.8533 18.1714 15.4609 18.3346 15.0026 18.3346ZM15.0026 16.668V7.3763L10.9609 3.33464H5.0026V16.668H15.0026Z"
                                        fill="white"
                                    />
                                </g>
                            </g>
                        </svg>
                        Upload Contacts
                    </button>
                    <button
                        onClick={() => {
                            setCurrentWidgetIndex(1);
                            navigate("/bulkenrich/validate-emails");
                        }}
                        className=" bg-[#050505] py-2 font-OutfitMedium px-4 flex items-center gap-2 text-white  whitespace-nowrap rounded-lg text-sm "
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.2969 18.3359L9.75521 14.7943L10.9219 13.6276L13.2969 16.0026L18.0052 11.2943L19.1719 12.4609L13.2969 18.3359ZM10.0052 9.16927L16.6719 5.0026H3.33854L10.0052 9.16927ZM10.0052 10.8359L3.33854 6.66927V15.0026H7.63021L9.29688 16.6693H3.33854C2.88021 16.6693 2.48785 16.5061 2.16146 16.1797C1.83507 15.8533 1.67188 15.4609 1.67188 15.0026V5.0026C1.67188 4.54427 1.83507 4.15191 2.16146 3.82552C2.48785 3.49913 2.88021 3.33594 3.33854 3.33594H16.6719C17.1302 3.33594 17.5226 3.49913 17.849 3.82552C18.1753 4.15191 18.3385 4.54427 18.3385 5.0026V8.6276L16.6719 10.2943V6.66927L10.0052 10.8359Z"
                                fill="white"
                            />
                        </svg>
                        Validate Emails
                    </button>
                    <CRMPushMenu selected={selectedData} profileType={"bulk"} isDisabled={isDisabled} />
                    <button
                        onClick={() => {
                            onDownloadSelected();
                        }}
                        className=" border border-[#d1d1d1] py-2 font-OutfitMedium px-4 flex items-center gap-2 text-black  whitespace-nowrap rounded-lg text-sm "
                        disabled={isDisabled || numSelected === 0}
                    >
                        {downloadLoading ? (
                            <CircularProgress size={16} color="inherit" />
                        ) : (
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_1348_8973" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                    <rect width="20" height="20" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_1348_8973)">
                                    <path
                                        d="M10 13L6 9L7.0625 7.9375L9.25 10.125V3H10.75V10.125L12.9375 7.9375L14 9L10 13ZM5.49417 16C5.08139 16 4.72917 15.8531 4.4375 15.5594C4.14583 15.2656 4 14.9125 4 14.5V13H5.5V14.5H14.5V13H16V14.5C16 14.9125 15.8531 15.2656 15.5592 15.5594C15.2653 15.8531 14.9119 16 14.4992 16H5.49417Z"
                                        fill="#1C1B1F"
                                    />
                                </g>
                            </svg>
                        )}{" "}
                        Download results
                    </button>
                </div>
            </div>
        </>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

// BulkResultRow component
function BulkResultRow(props) {
    const { row, isItemSelected, labelId, handleClick, handleDeleteItem, handleDownloadItem } = props;
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    // Use `row` directly instead of `rowData`

    const fetchIntervalTime = (row) => {
        if (row?.progress && row.progress && row.progress < 50) return 10000;
        return 5000;
    };

    const { data: item } = useQuery({
        queryKey: ["bulkEnrichResultItem", row?._id],
        queryFn: () => props.bulkEnrichResultItem(row?._id),
        enabled: !!row?._id && !row?.completed && !row?.error,
        refetchInterval: fetchIntervalTime(row),
        refetchIntervalInBackground: true,
        onSuccess: (data) => {
            // Update the row in the parent component's state
            props.setTableData((prev) =>
                prev.map((tableItem) => (tableItem?._id === data.data._id ? { ...tableItem, ...data.data } : tableItem))
            );
        },
        onError: (error) => {
            console.error("Query error:", error);
        },
    });

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <>
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row._id}
                selected={false}
                sx={{
                    cursor: "pointer",
                    background: "white",
                    "& .MuiTableCell-root": {
                        border: "none",
                        borderTop: props.index === 0 ? "none" : 1,
                        borderColor: kompassColors.light,
                    },
                }}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                            "aria-labelledby": labelId,
                        }}
                        sx={{
                            "&.Mui-checked": {
                                color: kompassColors.blue,
                            },
                            "&.MuiCheckbox-indeterminate": {
                                color: kompassColors.blue,
                                fill: kompassColors.blue,
                            },
                            "& .MuiSvgIcon-root": {
                                fontSize: 16,
                            },
                        }}
                        onClick={(event) => handleClick(event, row._id)}
                    />
                </TableCell>
                <TableCell component="th" id={labelId} scope="row" padding="none">
                    <div className="flex flex-row items-center gap-2">
                        <div className="flex flex-col">
                            <span className=" text-[#494949] text-base font-normal font-['Outfit'] leading-tight max-w-[272px]">
                                {row?.contactTitle}
                            </span>
                        </div>
                    </div>
                </TableCell>
                <TableCell padding="none" align="left">
                    <p className="text-base font-light text-stone-950">{row?.numberOfContacts} contacts</p>
                </TableCell>
                <TableCell padding="none" align="left">
                    <div className="text-base font-light text-stone-950">{formattedDate(row?.createdAt)}</div>
                </TableCell>
                <TableCell padding="none" align="left">
                    <div className="flex items-center gap-2">
                        <Tooltip title="Call">
                            <IconButton>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_1348_9052" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                        <rect width="20" height="20" fill="#D9D9D9" />
                                    </mask>
                                    <g mask="url(#mask0_1348_9052)">
                                        <path
                                            d="M15.8931 16.9792C14.2126 16.8542 12.6188 16.441 11.1119 15.7396C9.60494 15.0382 8.25425 14.0903 7.0598 12.8958C5.86536 11.7014 4.92439 10.3542 4.23689 8.85417C3.54939 7.35417 3.13619 5.76389 2.9973 4.08333C2.96953 3.79167 3.05487 3.53819 3.25334 3.32292C3.45168 3.10764 3.69966 3 3.9973 3H6.83064C7.06675 3 7.27161 3.07292 7.44522 3.21875C7.61883 3.36458 7.73341 3.54861 7.78897 3.77083L8.28897 5.97917C8.31675 6.15972 8.30633 6.33333 8.25772 6.5C8.20911 6.66667 8.12925 6.80556 8.01814 6.91667L5.9973 8.95833C6.27508 9.48611 6.59453 9.99306 6.95564 10.4792C7.31675 10.9653 7.71932 11.4235 8.16334 11.8538C8.58043 12.2707 9.02508 12.6562 9.4973 13.0104C9.96953 13.3646 10.4695 13.6806 10.9973 13.9583L13.0598 11.9583C13.1709 11.8472 13.3098 11.7674 13.4765 11.7188C13.6431 11.6701 13.8167 11.6597 13.9973 11.6875L16.2265 12.1667C16.4626 12.2361 16.6501 12.3576 16.789 12.5312C16.9279 12.7049 16.9973 12.9097 16.9973 13.1458V15.9792C16.9973 16.2768 16.8862 16.5248 16.664 16.7231C16.4417 16.9216 16.1848 17.0069 15.8931 16.9792ZM5.3098 7.5L6.76814 6.04167L6.41064 4.5H4.53897C4.60841 5.02778 4.70564 5.53819 4.83064 6.03125C4.95564 6.52431 5.11536 7.01389 5.3098 7.5ZM12.4765 14.6667C12.964 14.8644 13.4608 15.0215 13.9671 15.1379C14.4733 15.2543 14.9834 15.3472 15.4973 15.4167V13.5417L13.9348 13.2083L12.4765 14.6667Z"
                                            fill={row?.fileUploadType === "call" ? "#050505" : "#b0b0b0"}
                                        />
                                    </g>
                                </svg>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Email">
                            <IconButton>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_1348_9065" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                        <rect width="20" height="20" fill="#D9D9D9" />
                                    </mask>
                                    <g mask="url(#mask0_1348_9065)">
                                        <path
                                            d="M3.5 16C3.0875 16 2.73438 15.8531 2.44063 15.5592C2.14688 15.2653 2 14.9119 2 14.4992V5.49417C2 5.08139 2.14688 4.72917 2.44063 4.4375C2.73438 4.14583 3.0875 4 3.5 4H16.5C16.9125 4 17.2656 4.14694 17.5594 4.44083C17.8531 4.73472 18 5.08806 18 5.50083V14.5058C18 14.9186 17.8531 15.2708 17.5594 15.5625C17.2656 15.8542 16.9125 16 16.5 16H3.5ZM10 11L3.5 7.27083V14.5H16.5V7.27083L10 11ZM10 9.22917L16.5 5.5H3.5L10 9.22917ZM3.5 7.27083V5.5V14.5V7.27083Z"
                                            fill={row?.fileUploadType === "email" ? "#050505" : "#b0b0b0"}
                                        />
                                    </g>
                                </svg>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Validated Email">
                            <IconButton>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.2969 18.3359L9.75521 14.7943L10.9219 13.6276L13.2969 16.0026L18.0052 11.2943L19.1719 12.4609L13.2969 18.3359ZM10.0052 9.16927L16.6719 5.0026H3.33854L10.0052 9.16927ZM10.0052 10.8359L3.33854 6.66927V15.0026H7.63021L9.29688 16.6693H3.33854C2.88021 16.6693 2.48785 16.5061 2.16146 16.1797C1.83507 15.8533 1.67188 15.4609 1.67188 15.0026V5.0026C1.67188 4.54427 1.83507 4.15191 2.16146 3.82552C2.48785 3.49913 2.88021 3.33594 3.33854 3.33594H16.6719C17.1302 3.33594 17.5226 3.49913 17.849 3.82552C18.1753 4.15191 18.3385 4.54427 18.3385 5.0026V8.6276L16.6719 10.2943V6.66927L10.0052 10.8359Z"
                                        fill={row?.fileUploadType === "validated_Email" ? "#050505" : "#b0b0b0"}
                                    />
                                </svg>
                            </IconButton>
                        </Tooltip>
                    </div>
                </TableCell>
                <TableCell padding="none" align="left">
                    <p className="text-base font-light text-stone-950">{row?.creditsUsed}</p>
                </TableCell>
                <TableCell padding="none" align="left">
                    <div className="flex flex-row items-center justify-start">
                        {row?.completed && (
                            <IconButton onClick={() => {}} disabled>
                                <SuccessIcon />
                            </IconButton>
                        )}
                        {!row?.completed && !row?.error && (
                            <IconButton disabled>
                                <CircularProgress size="1.2rem" className="mr-2" />
                            </IconButton>
                        )}
                        <p className="text-base font-light text-stone-950">
                            {row?.error ? `Failed • ${row?.message}` : row?.completed ? "Completed" : `Processing ${row?.progress} %`}
                        </p>
                    </div>
                </TableCell>
                <TableCell padding="none" align="left">
                    <IconButton
                        disabled={row?.error || (!row?.completed && !row?.error)}
                        onClick={() => {
                            handleDownloadItem(row);
                        }}
                    >
                        <DownloadIcon />
                    </IconButton>
                    <IconButton
                        disabled={!row?.completed && !row?.error}
                        onClick={() => {
                            handleDeleteItem(row._id);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    );
}

// BulkResultTable component
export default function BulkResultTable({ setCurrentWidgetIndex }) {
    const { bulkEnrichResults, bulkEnrichDeleteResult, bulkEnrichOutputs, bulkEnrichResultItem } = useBulk();
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [visibleDeleteDialog, handleVisibleDeleteDialog] = useState(false);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(1);
    const [isDisabledPushAndSave, setIsDisabledPushAndSave] = useState(false);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("createdAt");
    const rowsPerPage = 5;
    let currentDeleteId = null;

    useEffect(() => {
        const reload = async () => {
            setLoading(true);
            const response = await bulkEnrichResults();

            if (response?.status) {
                setTableData(response?.data);
            } else {
                toast.error(response?.message, { theme: "colored" });
            }
            setLoading(false);
        };
        reload();
    }, []);

    useEffect(() => {
        const isDisableButtonsForExport = () => {
            const noCompletedOrErroredData = tableData.filter((tableItem) => tableItem.error || !tableItem.completed);

            return noCompletedOrErroredData.some((tableItem) => {
                return selected.includes(tableItem._id);
            });
        };

        setIsDisabledPushAndSave(isDisableButtonsForExport());
    }, [selected.length, tableData]);

    const reload = async () => {
        setLoading(true);
        const response = await bulkEnrichResults();
        if (response?.status) {
            setTableData(response?.data);

            const maxPage = Math.max(0, Math.ceil(response.data.length / rowsPerPage) - 1);

            if (page > maxPage) {
                setPage(maxPage);
            }
        } else {
            toast.error(response?.message, { theme: "colored" });
        }
        setLoading(false);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = tableData.map((n) => n._id);
            setSelected(newSelected);
            return;
        } else {
            setSelected([]);
        }
    };

    const handleDeleteSelected = async (confirmed) => {
        if (!(confirmed === true)) {
            handleVisibleDeleteDialog(true);
        } else {
            setLoading(true);
            const response = await bulkEnrichDeleteResult(currentDeleteId);
            setLoading(false);
            if (response?.status) {
                toast.success(response?.message, { theme: "colored" });
                setSelected([]);
                await reload();
            } else {
                toast.error(response?.message, { theme: "colored" });
            }
        }
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const onDownloadSelected = async () => {
        setDownloadLoading(true);
        for (const itemId of selected) {
            try {
                const item = tableData.find((item) => {
                    return item._id === itemId;
                });
                if (item) {
                    const content = await bulkEnrichOutputs(itemId);
                    await csvDownload(item?.contactTitle, content?.data);
                }
            } catch (error) {
                toast.error(error?.message || "Something went wrong, try again");
            } finally {
                setDownloadLoading(false);
            }
        }
    };

    const handleDeleteItem = (id) => {
        currentDeleteId = id;
        handleVisibleDeleteDialog(true);
    };

    const handleDownloadItem = async (rowData) => {
        try {
            const response = await bulkEnrichOutputs(rowData?._id);
            await csvDownload(rowData?.contactTitle, response?.data);
        } catch (error) {
            toast.error(error?.message ? error?.message : "Something went wrong", {
                theme: "colored",
            });
        }
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const sortedData = useMemo(() => {
        return stableSort(tableData, getComparator(order, orderBy));
    }, [tableData, order, orderBy]);

    const visibleRows = useMemo(
        () => sortedData.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage),
        [page, sortedData, rowsPerPage]
    );

    return (
        <>
            {loading && <MainLoader />}
            <Box sx={{ width: "100%", mt: "10px" }}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    setSelected={setSelected}
                    onDownloadSelected={onDownloadSelected}
                    isDisabled={isDisabledPushAndSave}
                    selectedData={selected.map((id) => tableData.find((item) => item._id === id))}
                    downloadLoading={downloadLoading}
                    setCurrentWidgetIndex={setCurrentWidgetIndex}
                />
                <div className="border rounded-[8px] border-stone-250 mt-5">
                    <TableContainer sx={{ borderRadius: "8px" }}>
                        <Table
                            sx={{
                                minWidth: {
                                    xs: 300,
                                    lg: 750,
                                },
                                borderCollapse: "separate",
                            }}
                            aria-labelledby="tableTitle"
                            size={"medium"}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={tableData.length}
                                selected={selected}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody
                                sx={{
                                    "& .MuiTableCell-sizeMedium": {
                                        padding: "5px 8px",
                                        fontFamily: "Outfit",
                                        fontSize: 14,
                                        border: "1px solid #E8E7E7",
                                    },
                                }}
                            >
                                {visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row._id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <BulkResultRow
                                            key={row._id} // Ensure unique key
                                            isItemSelected={isItemSelected}
                                            setTableData={setTableData}
                                            row={row}
                                            labelId={labelId}
                                            handleClick={handleClick}
                                            handleDeleteItem={handleDeleteItem}
                                            handleDownloadItem={handleDownloadItem}
                                            bulkEnrichResultItem={bulkEnrichResultItem}
                                            index={index}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className="flex justify-center w-full pt-10 pb-20">
                    <Pagination
                        className="font-Outfit"
                        count={Math.ceil(tableData.length / rowsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                        boundaryCount={1}
                        siblingCount={0}
                        renderItem={(item) => <PaginationItem slots={{ first: FirstPaginationIcon, last: LastPaginationIcon }} {...item} />}
                        sx={{
                            "& .MuiPaginationItem-text": {
                                fontFamily: "Outfit",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: 1.43,
                                color: "#090C05",
                            },
                            "& .MuiPaginationItem-text.Mui-selected": {
                                color: "#fff",
                                backgroundColor: "black",
                                "&:hover": {
                                    backgroundColor: "gray",
                                },
                            },
                        }}
                    />
                </div>
            </Box>
            <ConfirmDeleteDialog
                open={visibleDeleteDialog}
                handleClose={() => {
                    handleVisibleDeleteDialog(false);
                    currentDeleteId = null;
                }}
                handleYes={async () => {
                    handleDeleteSelected(true);
                    handleVisibleDeleteDialog(false);
                    setLoading(true);

                    const response = await bulkEnrichDeleteResult(currentDeleteId);
                    let tempSelected = selected;
                    tempSelected = tempSelected.filter((elem) => elem !== currentDeleteId);
                    setSelected(tempSelected);
                    if (response?.status) {
                        await reload();
                    } else {
                        toast.error(response?.message, { theme: "colored" });
                    }

                    setLoading(false);
                    currentDeleteId = null;
                }}
            />
        </>
    );
}
