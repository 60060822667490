import React from "react";
import { Toolbar, Checkbox } from "@mui/material";
import { ReactComponent as DeleteIcon } from "@/assets/image/email-validation/trash-alternative.svg";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import { RefreshOutlined } from "@mui/icons-material/";
import PropTypes from "prop-types";
import { kompassColors } from "@/theme/palette";

function ValidationTableToolbar(props) {
    const { numSelected, onSelectAllClick, rowCount, handleDelete, handleDownload, handleRefresh, loading } = props;

    return (
        <div className="mt-3 w-full border-stone-250 pb-3">
            <Toolbar
                sx={{
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    "@media (min-width: 600px)": {
                        paddingLeft: "12px",
                        paddingRight: "0px",
                    },
                }}
            >
                <div className="flex w-full flex-row items-center justify-between">
                    <div className="flex w-full flex-row items-center justify-between gap-2">
                        <div className="font-Outfit flex flex-row items-center">
                            <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                disabled={loading}
                                onChange={onSelectAllClick}
                                inputProps={{
                                    "aria-label": "select all desserts",
                                }}
                                sx={{
                                    color: kompassColors.textLight,
                                    "&.Mui-checked": {
                                        color: kompassColors.blue,
                                    },
                                    "&.MuiCheckbox-indeterminate": {
                                        color: kompassColors.blue,
                                    },
                                    "&.MuiCheckbox-root": {
                                        padding: 0,
                                        "@media (min-width: 540px)": {
                                            padding: "9px",
                                        },
                                    },
                                }}
                            />
                            <div className="font-OutfitMedium flex select-none items-center text-sm xs:text-base">
                                {numSelected > 0 ? `${numSelected}  selected` : null}
                            </div>
                        </div>
                        <div className="font-Outfit  flex flex-row justify-items-center gap-2 text-sm">
                            <RefreshOutlined
                                onClick={handleRefresh}
                                className="cursor-pointer self-center !transition-all hover:rotate-[20deg] hover:fill-[#4873fa]"
                            ></RefreshOutlined>
                            <button
                                className={
                                    "group flex items-center gap-2 rounded-[8px] border px-2 py-1 text-base font-bold capitalize transition-all xs:px-5 xs:py-2" +
                                    (numSelected > 0 && !loading
                                        ? " border-red-300 text-red-500 hover:bg-red-500 hover:text-white"
                                        : " border-stone-350 bg-stone-350 text-white opacity-50")
                                }
                                disabled={loading || numSelected <= 0}
                                onClick={() => {
                                    handleDelete();
                                }}
                            >
                                Delete
                                <DeleteIcon
                                    className={
                                        "h-5 w-5 select-none" +
                                        (numSelected > 0 && !loading ? " stroke-red-500 group-hover:stroke-white" : " white stroke-current")
                                    }
                                />
                            </button>
                            <button
                                className={
                                    "flex items-center gap-2 rounded-[8px] border px-2 py-1 text-base font-bold	capitalize text-white transition-all xs:px-5 xs:py-2" +
                                    (numSelected === 1 && !loading
                                        ? " border-stone-950 bg-stone-950 hover:bg-white hover:text-stone-950"
                                        : " border-stone-350 bg-stone-350 opacity-50")
                                }
                                disabled={loading || numSelected !== 1}
                                onClick={() => {
                                    handleDownload();
                                }}
                            >
                                Download results
                                <DownloadIcon className={numSelected === 1 && !loading ? "  group-hover:fill-black" : ""} />
                            </button>
                        </div>
                    </div>
                </div>
            </Toolbar>
        </div>
    );
}

ValidationTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default ValidationTableToolbar;
