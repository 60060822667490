import { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "@/context/AuthContext";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import TotalCreditsUsedComponent from "@/components/home/TotalCreditsUsedComponent";
import CreditsUsedByProductComponent from "@/components/home/CreditsUsedByProductComponent";
import ProspectSearchComponent from "@/components/home/ProspectSearchComponent";
import SuggestedComponent from "@/components/home/SuggestedComponent";
import SummaryComponent from "@/components/home/SummaryComponent";
import useBillingStore from "@/store/useBillingStore";
import useDashboard from "@/hooks/useDashboard";
import MainTitleBar from "@/components/MainTitleBar";

export default function HomeScene() {
    const { userInfo, isTeamMember, isTeamOwner } = useContext(AuthContext);
    const { getDashboard, getMyTeamInfo } = useDashboard();
    const { selectedPrices, setRedirect, redirect } = useBillingStore();
    const [data, setData] = useState(null);
    const [teamInfo, setTeamInfo] = useState({});
    const [totalCreditsUsedPeriodFilter, setTotalCreditsUsedPeriodFilter] = useState("THIS_MONTH");
    const [totalCreditsUsedProductFilter, setTotalCreditsUsedProductFilter] = useState("All Products");
    const [creditsUsedByProductPeriodFilter, setCreditsUsedByProductPeriodFilter] = useState("THIS_MONTH");
    const [creditsUsedByPeriod, setCreditsUsedByPeriod] = useState([]);
    const [creditsUsedByPeriodByProduct, setCreditsUsedByPeriodByProduct] = useState([]);

    const getMyTeamInfoRef = useRef();
    getMyTeamInfoRef.current = getMyTeamInfo;
    const getDashboardRef = useRef();
    getDashboardRef.current = getDashboard;

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const response = await getDashboardRef.current({});
            setData(response.data);
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async (filters) => {
            const response = await getDashboardRef.current(filters);
            setCreditsUsedByPeriodByProduct(response?.data?.creditsUsedByPeriodByProduct);
        };
        fetchData({
            creditsUsedByProductPeriodFilter,
        });
    }, [creditsUsedByProductPeriodFilter]);

    useEffect(() => {
        const fetchData = async (filters) => {
            const response = await getDashboardRef.current(filters);
            setCreditsUsedByPeriod(response?.data?.creditsUsedByPeriod);
        };
        fetchData({ totalCreditsUsedPeriodFilter, totalCreditsUsedProductFilter });
    }, [totalCreditsUsedPeriodFilter, totalCreditsUsedProductFilter]);

    useEffect(() => {
        if (redirect) {
            navigate(redirect, {
                state: Object.values(selectedPrices).filter((val) => val !== null),
            });
            setRedirect("");
        }
    }, [navigate, redirect, selectedPrices, setRedirect]);

    useEffect(() => {
        const fetchTeamData = async () => {
            const response = await getMyTeamInfoRef.current();
            setTeamInfo(response.data);
        };
        fetchTeamData();
    }, []);

    return (
        <MainLayout>
            <MainTitleBar>
                <p>Welcome to KompassAI, {userInfo?.userName}!</p>
            </MainTitleBar>

            <div className="w-full bg-white">
                <BaseContainer>
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-9 2xl:grid-cols-4">
                        <div className="mb-4 h-full border-r border-stone-250 bg-white pt-4 sm:mb-1 lg:col-span-2 2xl:col-span-1">
                            <div className="flex flex-col items-start justify-start">
                                <SummaryComponent
                                    creditsUsage={data?.creditsUsageInCurrentSubscription ?? {}}
                                    planInfo={data?.planInfo ?? {}}
                                    teamsInfo={data?.teamsInfo ?? {}}
                                    isTeamMember={isTeamMember}
                                    isTeamOwner={isTeamOwner}
                                    teamInfo={teamInfo}
                                    navigate={navigate}
                                />
                                <hr className="mb-11 mt-6 h-px w-full border-0 bg-stone-250" />
                            </div>
                        </div>
                        <div className="bg-white lg:col-span-5 2xl:col-span-2 lg:mb-4 lg:pt-4 ">
                            {data && (
                                <div className="flex flex-col items-start justify-start gap-y-4">
                                    <TotalCreditsUsedComponent
                                        creditsData={creditsUsedByPeriod}
                                        setPeriodFilter={setTotalCreditsUsedPeriodFilter}
                                        periodFilter={totalCreditsUsedPeriodFilter}
                                        setProductFilter={setTotalCreditsUsedProductFilter}
                                        productFilter={totalCreditsUsedProductFilter}
                                    />
                                    <CreditsUsedByProductComponent
                                        creditsData={creditsUsedByPeriodByProduct}
                                        setFilter={setCreditsUsedByProductPeriodFilter}
                                        filter={creditsUsedByProductPeriodFilter}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="mb-4 bg-white lg:col-span-2 2xl:col-span-1 lg:pt-4">
                            <div className="flex flex-col items-start justify-start gap-y-4 md:gap-4">
                                <ProspectSearchComponent />

                                <SuggestedComponent isTeamMember={isTeamMember} teamsInfo={data?.teamsInfo ?? {}} navigate={navigate} />
                            </div>
                        </div>
                    </div>
                </BaseContainer>
            </div>
        </MainLayout>
    );
}
