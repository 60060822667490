import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { motion, AnimatePresence } from "framer-motion";
import MainLayout from "@/components/MainLayout";
import KompassSearchContactTable from "@/components/search/SearchContactTable";
import { ContactFilterComponent } from "@/components/search/SearchFilterComponent";
import { Backdrop } from "@mui/material";
import { ReactComponent as GoogleIcon } from "@/assets/image/search/chrome.svg";
import ProspectsIcon from "@/assets/image/search/find_prospects.svg";
import BookmarkIcon from "@/assets/image/search/bookmark_fill.svg";
import { ReactComponent as DoubleArrow } from "@/assets/image/double_arrow.svg";
import { ReactComponent as DoubleArrowRight } from "@/assets/image/double_arrow_2.svg";
import CRMUploadResultsTable from "@/components/common/CRMElements/CRMUploadTable";
import useCRMPushResultStore from "@/store/useCRMPushResultStore";
import MainTitleBar from "@/components/MainTitleBar";
import { getAllQueryParams } from "@/utils/common";
import { KompassFiltersProvider } from "@/hooks/useKompassFilters";
import { ScrollArea } from "@/components/common/ScrollArea";

export default function KompassSearchScene() {
    const extensionId = "imkmjfajnjfpfkdojdmabcphojonjjjf";
    const [currentTab, setCurrentTab] = useState(0);
    const { show, loading: crmLoading } = useCRMPushResultStore();
    const [loading, setLoading] = useState(false);
    const [contactFilter, setContactFilter] = useState(null);
    const [selected, setSelected] = useState([]);
    const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
    const [filtersOpen, setFiltersOpen] = useState(true);
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [openRowIndex, setOpenRowIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const extensionLogoUrl = "chrome-extension://imkmjfajnjfpfkdojdmabcphojonjjjf/logo.svg";

    const TABS = [
        { label: "Find Prospects", icon: ProspectsIcon },
        { label: "Saved Searches", icon: BookmarkIcon },
    ];
    // The status for installation of chrome extension
    const [crxStatus, setCrxStatus] = useState(null);



    useEffect(() => {
        const checkIfExtensionInstalled = async () => {
            try {
                const res = await fetch(extensionLogoUrl, { method: "HEAD" });

                setIsExtensionInstalled(res.ok);
            } catch (e) {
                setIsExtensionInstalled(false);
            }
        };
        checkIfExtensionInstalled();
    }, []);

    useEffect(() => {
        let timeoutId;

        const handleMessage = (event) => {
            const { action, params } = event.data;

            if (action === `kompassai-extension-ping`) {
                setCrxStatus({
                    isInstalled: true,
                    timestamp: params.timestamp,
                });

                // Clear any previous timeout and set a new one
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    setCrxStatus(null); // Mark as uninstalled after 10 seconds of no ping
                }, 10 * 1000);
            }
        };

        window.addEventListener(`message`, handleMessage);

        // Clean up the event listener and timeout on unmount
        return () => {
            window.removeEventListener(`message`, handleMessage);
            clearTimeout(timeoutId);
        };
    }, []); // No need for crxStatus in dependencies since we're handling it internally

    useEffect(() => {
        // Handle search query params
        const queryParams = getAllQueryParams(window.location.search);
        if (Object.keys(queryParams).length) {
            setContactFilter({
                name: [queryParams?.name],
                companyName: [queryParams?.companyName],
                nameExactMatch: true,
            });
            // Update the URL without causing a page reload
            const url = new URL(window.location.href);
            for (const key of Object.keys(queryParams)) {
                url.searchParams.delete(key);
            }
            window.history.replaceState(null, "", url.toString());
        }
    }, []); // Run once on mount



    return (
        <KompassFiltersProvider
            onSearchByFilter={(filter) => setContactFilter(filter)}
            isFiltersSubpage={currentTab > 0}
            setCurrentTab={setCurrentTab}
        >
            <MainLayout loading={loading || crmLoading}>
                <>
                    <AnimatePresence>
                        {show && (
                            <motion.div
                                className="w-full"
                                initial={{ opacity: 0, zIndex: 11 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <Backdrop
                                    open
                                    className="!absolute"
                                    sx={{
                                        backgroundColor: "rgba(0,0,0,0.18)",
                                        backdropFilter: "blur(2px)",
                                        zIndex: 99999,
                                    }}
                                >
                                    <CRMUploadResultsTable></CRMUploadResultsTable>
                                </Backdrop>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </>
                <MainTitleBar>
                    <div className="flex flex-col justify-between sm:flex-row sm:items-end  gap-[4px]">
                        <p>Search Kompass</p>
                        <div className="flex flex-row gap-2">
                            {isExtensionInstalled ? (
                                <a
                                    href="https://www.linkedin.com/profile/preview?locale=en_US"
                                    className="main-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <p className="font-Outfit leading-[1.4] font-normal">Launch Chrome Extension</p>
                                    <GoogleIcon className="ml-1 h-5 w-5 fill-current text-white" />
                                </a>
                            ) : (
                                <a
                                    href="https://chromewebstore.google.com/detail/kompassai/imkmjfajnjfpfkdojdmabcphojonjjjf?hl=en&authuser=2"
                                    className="main-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <p className="font-Outfit leading-[1.4] font-normal">Install Chrome Extension</p>
                                    <GoogleIcon className="ml-1 h-5 w-5 fill-current text-white" />
                                </a>
                            )}
                        </div>
                    </div>
                </MainTitleBar>
                <div className="grid w-full grid-cols-1 lg:grid-cols-12 lg:h-[calc(100vh-80px)] relative">
                    <div className={`col-span-1 lg:col-span-3 border-r border-stone-250  bg-white relative flex-col ${filtersOpen ? "flex" : "hidden"}`}>
                        <div className="mt-1 flex w-full flex-row gap-2 self-start border-b border-stone-250">
                        {TABS.map((value, index) => (
    <div
        key={index}
        className={`font-OutfitMedium flex w-full cursor-pointer flex-row items-center justify-center gap-[4px] ${currentTab === index ? "border-b-2 text-blue-500" : "text-stone--950"} border-blue-500 px-1 py-2 text-center text-sm `}
        onClick={() => {
            setCurrentTab(index);
        }}
    >
        <ReactSVG
            src={value.icon}
            beforeInjection={(svg) => {
                // Common fill and size
                svg.setAttribute("style", "width: 20px");
                if (value.icon.includes('bookmark')) {
                    svg.setAttribute("fill", currentTab === index ? "#3b82f6" : "#000"); 
                } else if (value.icon.includes('prospects')) {
                    const path = svg.querySelector('g path');
                    if (path) {
                        path.setAttribute("fill", currentTab === index ? "#3b82f6" : "#000");
                    }
                }
            }}
        />
        {value.label}
    </div>
))}

                        </div>
                        <div onClick={() => setFiltersOpen(false)} className="absolute cursor-pointer lg:block hidden lg:right-[-24px] 2xl:right-[-27px] top-0 bg-black rounded-r-md p-[3px] py-2 ">
                            <DoubleArrow className=" h-5 w-5 " />
                        </div>
                        <ContactFilterComponent
                            isFiltersSubpage={currentTab === 0}
                            setIsSearchClicked={setIsSearchClicked}
                            setOpenRowIndex={setOpenRowIndex}
                        />
                    </div>
                    <div className={`col-span-1 h-full rounded-lg ${filtersOpen ? "lg:col-span-9 " : "lg:col-span-12"} `}>
                        <KompassSearchContactTable
                            filter={contactFilter}
                            selected={selected}
                            setSelected={setSelected}
                            filtersOpen={filtersOpen}
                            setOpenRowIndex={setOpenRowIndex}
                            openRowIndex={openRowIndex}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            isSearchClicked={isSearchClicked}
                            setIsSearchClicked={setIsSearchClicked}
                        />
                    </div>
                    <div onClick={() => setFiltersOpen(true)} className={`absolute cursor-pointer lg:block hidden left-0 top-0 bg-black rounded-r-md p-[3px] py-2 ${filtersOpen && "lg:hidden"}`}>
                        <DoubleArrowRight className=" h-5 w-5 " />
                    </div>
                </div>
            </MainLayout>
        </KompassFiltersProvider>
    );
}