import { InputBase } from "@mui/material";
import React, { useState, forwardRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { styled } from "@mui/material/styles";
import { kompassColors } from "@/theme/palette";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        position: "relative",
        backgroundColor: "white",
        border: "1px solid",
        borderColor: kompassColors.light,
        borderRadius: 6,
        fontSize: 13,
        fontFamily: "Outfit",
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 6,
            borderColor: kompassColors.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

const DateRange = ({ value, onChange }) => {
    // const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = value && value?.length ? value : [null, null];

    const ExampleCustomInput = forwardRef(({ value, onClick, className }, ref) => (
        <BootstrapInput value={value} className={className} onClick={onClick} ref={ref} />
    ));

    return (
        <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            customInput={<ExampleCustomInput className="w-full" />}
            onChange={(update) => {
                // setDateRange(update);
                onChange(update);
            }}
            isClearable={true}
        />
    );
};

export default DateRange;
