import { useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toast } from "react-toastify";
import useAuth from "@/hooks/useAuth";
import { useAvailableIntegrations } from "@/hooks/useAvailableIntegrations";
import useCRMPushResultStore from "@/store/useCRMPushResultStore";
import { MenuItem, MenuList, Accordion, AccordionSummary, AccordionDetails, Grow, Popper, ClickAwayListener, styled } from "@mui/material";

const StyledMenu = styled(React.forwardRef((props, ref) => <MenuList ref={ref} {...props} />))(() => {
    return {
        padding: 0,
        "& .MuiMenuItem-root": {
            fontFamily: "Outfit",
            display: "flex",
            alignItems: "center",
            gap: 4,
            marginTop: 4,
            minWidth: 180,
            padding: 0,
            overflow: "hidden",
            boxShadow: "none",
            backgroundColor: "white",
        },
    };
});

function CRMPushMenu({ selected, profileType, isDisabled = false }) {
    const { toggleShow, setResults, setUploads, setTarget, setTargetEntity, setProfileType, toggleLoading } = useCRMPushResultStore();

    const [integrationMenu, setIntegrationMenu] = useState("");
    const anchorRef = React.useRef();
    const availableIntegrations = useAvailableIntegrations();
    const navigate = useNavigate();
    const numSelected = selected.length;
    const { getAuthToken } = useAuth();

    const handleIntegrationPush = async (func, targetEntity, target, ui = "table") => {
        const filteredData =
            profileType === "profile_list" || profileType === "profile_search"
                ? selected.filter((item) => {
                      return (
                          (item?.validPhones && item.validPhones.length > 0) ||
                          (item?.personalEmails && item.personalEmails.length > 0) ||
                          (item?.workEmails && item.workEmails.length > 0) ||
                          (item?.doNotEmails && item.doNotEmails.length > 0) ||
                          (item?.riskyEmails && item.riskyEmails.length > 0)
                      );
                  })
                : selected;

        setUploads(filteredData);
        toggleLoading();
        setProfileType(profileType);
        setTarget(target);
        setTargetEntity(targetEntity);

        let res;

        if (profileType === "profile_list" || profileType === "profile_search") {
            res = await func(filteredData, profileType, await getAuthToken());
        } else {
            const ids = filteredData.map((item) => {
                return item.kompassProfileId ? item.kompassProfileId : item._id;
            });
            res = await func(ids, profileType, await getAuthToken());
        }

        toggleLoading();
        // in table case, almost all errors are handled and if not,
        // empty table is shown, so there is no need for extra handling
        if (ui === "table") {
            toggleShow();
        } else if (ui === "notification") {
            if (res.success) {
                toast.success("Successfully sent data to webhooks, check Zapier for results");
                if (res.extraMessage) {
                    toast.info(res.extraMessage);
                }
            } else {
                toast.error("There was some error handling request, try again later");
            }
            return;
        }

        const parsed = JSON.parse(res.body);
        setResults(parsed);
    };

    return (
        <>
            <div
                ref={anchorRef}
                className={
                    "font-Outfit flex cursor-pointer select-none flex-row items-center gap-1 rounded-md border px-4 py-2 text-sm bg-white" +
                    (numSelected > 0 && !isDisabled
                        ? " border-stone-500 text-stone-950"
                        : " border-stone-500 text-stone-350 cursor-not-allowed pointer-events-none")
                }
                onClick={() => {
                    if (!selected.length || isDisabled) return;
                    const filteredData = selected.filter(
                        (item) =>
                            item?.isEmailFound ||
                            item?.isPhoneFound ||
                            (item?.validPhones && item.validPhones.length > 0) ||
                            (item?.personalEmails && item.personalEmails.length > 0) ||
                            (item?.workEmails && item.workEmails.length > 0) ||
                            (item?.doNotEmails && item.doNotEmails.length > 0) ||
                            (item?.riskyEmails && item.riskyEmails.length > 0)
                    );

                    if (filteredData.length <= 0 && profileType !== "bulk") {
                        toast.error("There is no data to save. Only enriched records can be pushed to CRM", {
                            theme: "colored",
                        });
                        return;
                    }
                    setIntegrationMenu(!integrationMenu);
                }}
            >
                Push to CRM
            </div>
            <Popper
                open={integrationMenu}
                anchorEl={anchorRef.current}
                sx={{ zIndex: 10 }}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
                        }}
                    >
                        <ul>
                            <ClickAwayListener disableReactTree={true} onClickAway={() => setIntegrationMenu(false)}>
                                <StyledMenu
                                    sx={{
                                        padding: 0,
                                        marginTop: "4px",
                                        boxShadow: "0 1px 4px 2px rgb(0 0 0 / 0.1)",
                                    }}
                                    className="overflow-hidden rounded-lg bg-white"
                                >
                                    {availableIntegrations.map((item) => {
                                        return item.available ? (
                                            <>
                                                <MenuItem
                                                    sx={{
                                                        padding: 0,
                                                        margin: 0,
                                                        boxShadow: "0 1px 2px 4px rgb(0 0 0 / 0.1)",
                                                    }}
                                                >
                                                    <Accordion
                                                        sx={{
                                                            borderRadius: 0,
                                                            boxShadow: "none",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <AccordionSummary
                                                            sx={{
                                                                borderRadius: 0,
                                                                boxShadow: "none",
                                                            }}
                                                            expandIcon={<ExpandMoreIcon />}
                                                        >
                                                            Push to {item.label}
                                                        </AccordionSummary>
                                                        <AccordionDetails
                                                            sx={{
                                                                padding: 0,
                                                                backgroundColor: "#3b82f6",
                                                                color: "white",
                                                            }}
                                                            className="flex flex-col gap-1 "
                                                        >
                                                            {item.pushOptions.map((option) => {
                                                                return (
                                                                    <button
                                                                        className="flex w-full gap-1 px-6 py-2 transition-all hover:bg-[#126dff]"
                                                                        onClick={() => {
                                                                            handleIntegrationPush(
                                                                                option.handler,
                                                                                option.targetEntity,
                                                                                item.label,
                                                                                item.ui
                                                                            );
                                                                            setIntegrationMenu(false);
                                                                        }}
                                                                    >
                                                                        {option.label}
                                                                    </button>
                                                                );
                                                            })}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </MenuItem>
                                            </>
                                        ) : (
                                            <Accordion
                                                sx={{
                                                    borderRadius: 0,
                                                    boxShadow: "none",
                                                }}
                                                onClick={() => navigate("/integrate")}
                                            >
                                                <AccordionSummary
                                                    sx={{
                                                        borderRadius: 0,
                                                        boxShadow: "none",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    {item.label === "Zapier" ? "Setup Zapier" : `Connect to ${item.label}`}
                                                </AccordionSummary>
                                            </Accordion>
                                        );
                                    })}
                                </StyledMenu>
                            </ClickAwayListener>
                        </ul>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

export default CRMPushMenu;
