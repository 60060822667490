import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useQuery, useMutation, useIsMutating } from "@tanstack/react-query";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { Button } from "@material-tailwind/react";
import * as Yup from "yup";
import StatusBadge from "./StatusBadge";
import { useState } from "react";
import StyledInput from "./StyledInput";

function SingleEmailValidation() {
    const [visible, setVisible] = useState(false);
    const isMutating = useIsMutating();
    const initialValues = {
        email: "",
    };
    const singleEmailValidation = useHTTPRequest("/email-validation/", "POST");
    const getUsageData = useHTTPRequest("/credits/getUserCreditsUsed?", "GET");

    const validationSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email address").required("Email is required"),
    });
    const handleSubmit = async (values) => {
        if (creditsData) {
            if (creditsData?.data?.available <= 0) {
                toast.error("You don't have enough email validation credits, please refill your credits");
                return;
            }
            ValidateSingleMutation(JSON.stringify(values));
            setVisible(true);
        }
    };

    const { data: creditsData } = useQuery({
        queryKey: ["CreditsUsage"],
        queryFn: () => getUsageData(),
    });
    const { data, mutateAsync: ValidateSingleMutation } = useMutation({
        mutationFn: singleEmailValidation,
    });

    return (
        <div className="flex w-full flex-col items-center justify-center gap-8 pb-[85px] pt-[74px]">
            <div className="flex flex-col gap-1 rounded-md ">
                <div className="font-OutfitBold flex max-w-[520px] flex-col self-center text-center text-3xl font-extrabold">
                    Verify any email address with the most complete email checker
                </div>
                <div className="font-OutfitLight flex w-fit flex-col self-center text-center text-base font-light">Email verifier</div>
            </div>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({ setFieldValue, values, errors }) => (
                    <Form className="flex w-full flex-col gap-8 md:max-w-[954px]">
                        <div className="flex w-full flex-col gap-1">
                            <div className="font-Outfit flex flex-col text-left text-base font-medium">Email</div>
                            <div className="flex w-full flex-row items-center bg-white ">
                                <StyledInput errors={errors} fieldName={"email"}></StyledInput>
                            </div>
                            <ErrorMessage
                                name="email"
                                component="div"
                                className="font-Outfit leading-tight text-sm font-normal text-red-600"
                            />
                            {data?.response && visible && <StatusBadge email={data?.response}></StatusBadge>}
                        </div>
                        <div className="flex flex-wrap justify-center text-center">
                            <Button
                                type="submit"
                                className="font-Outfit flex h-12 w-[250px] items-center justify-center rounded-md bg-black text-base font-bold capitalize leading-md text-white"
                            >
                                {!!isMutating && <CircularProgress style={{ width: "20px", height: "20px" }} />}
                                <span>Verify</span>
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default SingleEmailValidation;
