import { useContext, useEffect, useRef, useState } from "react";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import { AuthContext } from "@/context/AuthContext";
import useIPsDeanonymization from "@/hooks/useIPsDeanonymization";
import { WEBSITE_VISITORS_REPORT_ITEMS } from "@/utils/constants";
import LeftBar from "@/components/webpage_visitors/LeftBar";
import WebsiteReport from "@/components/webpage_visitors/WebsiteReport";
import AccountDetails from "@/components/webpage_visitors/AccountDetails";
import NavigationTabs from "@/components/webpage_visitors/NavigationTabs";
import Analytics from "@/components/webpage_visitors/analytics/Analytics";
import ProfileExport from "@/components/webpage_visitors/profile_export/ProfileExport";
import ICPFilter from "@/components/webpage_visitors/target_profiles/ICPFilter";
import ExportButton from "@/components/webpage_visitors/profile_export/ExportButton";
import useICPFilters from "@/hooks/useICPFilters";
import useExportProfiles from "@/hooks/useExportProfiles";
import SearchProspectButton from "@/components/webpage_visitors/SearchProspectButton";
import { Button, Menu, MenuItem } from "@mui/material";

const menuButtonStyles = {
    fontFamily: "Outfit",
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: "normal",
    borderRadius: "8px",
    borderWidth: "1px",
    borderColor: "#0000001A",
    height: "32px",
    paddingLeft: "12px",
    paddingRight: "12px",
    color: "#0D0D0D",
    whiteSpace: "nowrap",
    "&:hover": {
        borderColor: "#0000005A",
        backgroundColor: "#FFFFFF",
    },
};

const menuItemStyles = {
    fontFamily: "Outfit",
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: "normal",
};

export default function WebsiteVisitorsScene() {
    const [currentTab, setCurrentTab] = useState(WEBSITE_VISITORS_REPORT_ITEMS.TOP_COMPANIES_BY_VISITS);
    const [loading, setLoading] = useState(false);
    const { userInfo } = useContext(AuthContext);
    const [enrichedData, setEnrichedData] = useState([]);
    const [allowedDomains, setAllowedDomains] = useState([]);
    const [summary, setSummary] = useState("");
    const [tabOpen, setTabOpen] = useState("Analytics");
    const [dateRange, setDateRange] = useState("week");
    const [selectDateTag, setSelectDateTag] = useState("Past week");
    const [analyzedData, setAnalyzedData] = useState([]);
    const [filterOptions, setFilterOptions] = useState({ size: [], industry: [], location: [] });
    const [iCPFilters, setICPFilters] = useState([]);
    const [selectedICPFilter, setSelectedICPFilter] = useState("Select ICP Filter");
    const [csvs, setCsvs] = useState([]);
    const enrichedDataRef = useRef([]);
    const visitorsDataRef = useRef([]);
    const [level, setLevel] = useState(null);
    const [department, setDepartment] = useState(null);
    const [filterProps, setFilterProps] = useState([]);
    const [anchorDateEl, setAnchorDateEl] = useState(null);
    const [anchorICPFilterEl, setAnchorICPFilterEl] = useState(null);

    const { enrichIP, createUserEntry, getWebsiteVisitors, updateAllowedDomains } = useIPsDeanonymization();

    const { getICPFilters } = useICPFilters();
    const getICPFiltersRef = useRef();
    getICPFiltersRef.current = getICPFilters;

    const { getExportedProfiles } = useExportProfiles();
    const getExportedProfilesRef = useRef();
    getExportedProfilesRef.current = getExportedProfiles;

    const getWebsiteVisitorsRef = useRef();
    getWebsiteVisitorsRef.current = getWebsiteVisitors;

    const updateAllowedDomainsRef = useRef();
    updateAllowedDomainsRef.current = updateAllowedDomains;

    const enrichIPRef = useRef();
    enrichIPRef.current = enrichIP;

    const createUserEntryRef = useRef();
    createUserEntryRef.current = createUserEntry;

    const handleDateButtonClick = (event) => {
        setAnchorDateEl(event.currentTarget);
    };

    const handleDateButtonClose = () => {
        setAnchorDateEl(null);
    };

    const handleICPFilterButtonClick = (event) => {
        setAnchorICPFilterEl(event.currentTarget);
    };

    const handleICPFilterButtonClose = () => {
        setAnchorICPFilterEl(null);
    };

    const changeDateRange = (tag) => {
        setDateRange(tag);
        setSelectDateTag(tag !== "All" ? "Past " + tag : tag);
        handleDateButtonClose();
    };

    const handleFilterSelect = (filter) => {
        setSelectedICPFilter(filter.name);
        handleICPFilterButtonClose();
    };

    useEffect(() => {
        const fetchICPFilters = async () => {
            try {
                const response = await getICPFiltersRef.current();
                console.log("ICP response: ", response);
                if (response?.filters) {
                    setICPFilters(response.filters);
                }
            } catch (e) {
                console.log(e);
            }
        };
        fetchICPFilters();
    }, []);

    useEffect(() => {
        const fetchCSVData = async () => {
            try {
                const response = await getExportedProfilesRef.current();
                if (response?.profiles) {
                    response.profiles.sort((a, b) => b.created_at - a.created_at);
                    setCsvs(response.profiles);
                }
            } catch (e) {
                console.log(e);
            }
        };
        fetchCSVData();
    }, [dateRange, currentTab, selectedICPFilter]);

    useEffect(() => {
        const fetchCSVData = async () => {
            const userId = userInfo?._id;
            const oneWeekAgo = new Date();
            const oneMonthAgo = new Date();
            const oneYearAgo = new Date();
            oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
            const oneWeekAgoTimestamp = oneWeekAgo.getTime();
            oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);
            const oneMonthAgoTimestamp = oneMonthAgo.getTime();
            oneYearAgo.setDate(oneYearAgo.getDate() - 365);
            const oneYearAgoTimestamp = oneYearAgo.getTime();
            if (userId) {
                setLoading(true);
                try {
                    const query = new URLSearchParams({
                        userId,
                    }).toString();
                    if (visitorsDataRef.current.length === 0) {
                        await createUserEntryRef.current();
                        const response = await getWebsiteVisitorsRef.current({ query });
                        if (response) {
                            // enriching the ips not enriched
                            const ipChecked = {};
                            visitorsDataRef.current = response.parsedIPs;
                            setAllowedDomains(response.allowedDomains);

                            let isBalanceZero = false;

                            for (const obj of visitorsDataRef.current) {
                                if (!obj.enrichedData && !ipChecked[obj.ip] && !isBalanceZero) {
                                    const response = await enrichIPRef.current({ ip: obj.ip, userId });

                                    if (response?.message === "Company IP was enriched successfully.") {
                                        obj.enrichedData = response.data;
                                        ipChecked[obj.ip] = response.data;
                                    } else if (response?.message === "You don't have enough credits to enrich the ip.") {
                                        isBalanceZero = true;
                                    }
                                } else if (ipChecked[obj.ip]) {
                                    obj.enrichedData = ipChecked[obj.ip];
                                }
                            }
                        }
                    }

                    if (visitorsDataRef.current) {
                        // Populating ICP Filter Options
                        const newSize = [];
                        const newIndustry = [];
                        const newLocation = [];

                        visitorsDataRef.current.forEach((obj) => {
                            const size = obj.enrichedData?.companyDetails?.size;
                            const industry = obj.enrichedData?.companyDetails?.industry;
                            const location = obj.enrichedData?.ipDetails?.location?.name;

                            if (size?.length > 0 && !newSize.includes(size)) {
                                newSize.push(size);
                            }

                            if (industry?.length > 0 && !newIndustry.includes(industry)) {
                                newIndustry.push(industry);
                            }

                            if (location?.length > 0 && !newLocation.includes(location)) {
                                newLocation.push(location);
                            }
                        });

                        // converting all elements to objects
                        newSize.map((label, index) => {
                            return (newSize[index] = { label });
                        });

                        newIndustry.map((label, index) => {
                            return (newIndustry[index] = { label });
                        });

                        newLocation.map((label, index) => {
                            return (newLocation[index] = { label });
                        });

                        if (newSize.length > 0 || newIndustry.length > 0 || newLocation.length > 0) {
                            setFilterOptions({
                                size: newSize,
                                industry: newIndustry,
                                location: newLocation,
                            });
                        }

                        let filteredDataByDate;
                        if (dateRange === "week") {
                            filteredDataByDate = visitorsDataRef.current.filter((element) => element.created_at >= oneWeekAgoTimestamp);
                        }
                        if (dateRange === "month") {
                            filteredDataByDate = visitorsDataRef.current.filter((element) => element.created_at >= oneMonthAgoTimestamp);
                        }
                        if (dateRange === "year") {
                            filteredDataByDate = visitorsDataRef.current.filter((element) => element.created_at >= oneYearAgoTimestamp);
                        }
                        if (dateRange === "all") {
                            filteredDataByDate = visitorsDataRef.current;
                        }

                        if (selectedICPFilter) {
                            if (selectedICPFilter.size) {
                                filteredDataByDate = filteredDataByDate.filter(
                                    (element) => element.enrichedData?.companyDetails?.size === selectedICPFilter.size
                                );
                            }
                            if (selectedICPFilter.industry) {
                                filteredDataByDate = filteredDataByDate.filter(
                                    (element) => element.enrichedData?.companyDetails?.industry === selectedICPFilter.industry
                                );
                            }
                            if (selectedICPFilter.location) {
                                filteredDataByDate = filteredDataByDate.filter(
                                    (element) => element.enrichedData?.ipDetails?.location?.name === selectedICPFilter.location
                                );
                            }
                        }

                        let groupedData;

                        const groupedByCompany = filteredDataByDate.reduce((accumulator, item) => {
                            const company = item.enrichedData?.companyDetails?.name;

                            if (company) {
                                if (!accumulator[company + "_" + item.domain]) {
                                    accumulator[company + "_" + item.domain] = {
                                        domain: item.domain,
                                        companyDetails: item.enrichedData.companyDetails,
                                        ipDetails: item.enrichedData.ipDetails,
                                        visitors: [],
                                        visits: 0,
                                    };
                                }

                                if (!accumulator[company + "_" + item.domain].visitors.includes(item.ip))
                                    accumulator[company + "_" + item.domain].visitors.push(item.ip);
                                accumulator[company + "_" + item.domain].visits += 1;
                            }

                            return accumulator;
                        }, {});
                        if (currentTab === WEBSITE_VISITORS_REPORT_ITEMS.TOP_COMPANIES_BY_VISITS) {
                            groupedData = groupedByCompany;
                        }

                        if (currentTab === WEBSITE_VISITORS_REPORT_ITEMS.INDUSTRIES) {
                            const groupedByIndustry = filteredDataByDate.reduce((accumulator, item) => {
                                const industry = item.enrichedData?.companyDetails?.industry;

                                if (industry) {
                                    if (!accumulator[industry + "_" + item.domain]) {
                                        accumulator[industry + "_" + item.domain] = {
                                            domain: item.domain,
                                            industry,
                                            companies: [],
                                            countries: [],
                                            locations: [],
                                            visitors: [],
                                            visits: 0,
                                        };
                                    }

                                    if (
                                        !accumulator[industry + "_" + item.domain].companies.includes(
                                            item.enrichedData?.companyDetails?.name
                                        )
                                    ) {
                                        accumulator[industry + "_" + item.domain].companies.push(item.enrichedData?.companyDetails?.name);
                                    }

                                    if (
                                        !accumulator[industry + "_" + item.domain].countries.includes(
                                            item.enrichedData?.companyDetails?.country
                                        )
                                    ) {
                                        accumulator[industry + "_" + item.domain].countries.push(
                                            item.enrichedData?.companyDetails?.country
                                        );
                                    }

                                    if (
                                        !accumulator[industry + "_" + item.domain].locations.includes(
                                            item.enrichedData?.ipDetails?.location?.name
                                        )
                                    ) {
                                        accumulator[industry + "_" + item.domain].locations.push(
                                            item.enrichedData?.ipDetails?.location?.name
                                        );
                                    }

                                    if (!accumulator[industry + "_" + item.domain].visitors.includes(item.ip))
                                        accumulator[industry + "_" + item.domain].visitors.push(item.ip);
                                    accumulator[industry + "_" + item.domain].visits += 1;
                                }

                                return accumulator;
                            }, {});
                            groupedData = groupedByIndustry;
                        }

                        if (currentTab === WEBSITE_VISITORS_REPORT_ITEMS.EMPLOYEE_RANGES) {
                            const groupedByEmployeeRange = filteredDataByDate.reduce((accumulator, item) => {
                                const employeeRange = item.enrichedData?.companyDetails?.size;

                                if (employeeRange) {
                                    if (!accumulator[employeeRange + "_" + item.domain]) {
                                        accumulator[employeeRange + "_" + item.domain] = {
                                            domain: item.domain,
                                            employeeRange,
                                            companies: [],
                                            countries: [],
                                            visitors: [],
                                            visits: 0,
                                        };
                                    }

                                    if (
                                        !accumulator[employeeRange + "_" + item.domain].companies.includes(
                                            item.enrichedData?.companyDetails?.name
                                        )
                                    ) {
                                        accumulator[employeeRange + "_" + item.domain].companies.push(
                                            item.enrichedData?.companyDetails?.name
                                        );
                                    }

                                    if (
                                        !accumulator[employeeRange + "_" + item.domain].countries.includes(
                                            item.enrichedData?.companyDetails?.country
                                        )
                                    ) {
                                        accumulator[employeeRange + "_" + item.domain].countries.push(
                                            item.enrichedData?.companyDetails?.country
                                        );
                                    }

                                    if (!accumulator[employeeRange + "_" + item.domain].visitors.includes(item.ip))
                                        accumulator[employeeRange + "_" + item.domain].visitors.push(item.ip);
                                    accumulator[employeeRange + "_" + item.domain].visits += 1;
                                }

                                return accumulator;
                            }, {});
                            groupedData = groupedByEmployeeRange;
                        }

                        if (currentTab === WEBSITE_VISITORS_REPORT_ITEMS.LOCATIONS) {
                            const groupedByLocation = filteredDataByDate.reduce((accumulator, item) => {
                                if (
                                    item.enrichedData?.ipDetails?.location?.city &&
                                    item.enrichedData?.ipDetails?.location?.region &&
                                    item.enrichedData?.ipDetails?.location?.country
                                ) {
                                    const employeeLocation =
                                        item.enrichedData?.ipDetails?.location?.city +
                                        ", " +
                                        item.enrichedData?.ipDetails?.location?.region +
                                        ", " +
                                        item.enrichedData?.ipDetails?.location?.country;

                                    if (!accumulator[employeeLocation + "_" + item.domain]) {
                                        accumulator[employeeLocation + "_" + item.domain] = {
                                            domain: item.domain,
                                            employeeLocation,
                                            companies: [],
                                            industries: [],
                                            visitors: [],
                                            visits: 0,
                                        };
                                    }

                                    if (
                                        !accumulator[employeeLocation + "_" + item.domain].companies?.includes(
                                            item.enrichedData?.companyDetails?.name
                                        )
                                    ) {
                                        accumulator[employeeLocation + "_" + item.domain].companies?.push(
                                            item.enrichedData?.companyDetails?.name
                                        );
                                    }

                                    if (
                                        !accumulator[employeeLocation + "_" + item.domain].industries?.includes(
                                            item.enrichedData?.companyDetails?.industry
                                        )
                                    ) {
                                        accumulator[employeeLocation + "_" + item.domain].industries?.push(
                                            item.enrichedData?.companyDetails?.industry
                                        );
                                    }

                                    if (!accumulator[employeeLocation + "_" + item.domain].visitors?.includes(item.ip))
                                        accumulator[employeeLocation + "_" + item.domain].visitors?.push(item.ip);
                                    accumulator[employeeLocation + "_" + item.domain].visits += 1;
                                }

                                return accumulator;
                            }, {});
                            groupedData = groupedByLocation;
                        }

                        enrichedDataRef.current = Object.values(groupedData);

                        let uniqueIPs = [];
                        filteredDataByDate.forEach((obj) => {
                            if (!uniqueIPs.includes(obj.ip)) {
                                uniqueIPs.push(obj.ip);
                            }
                        });
                        const totalIps = uniqueIPs.length;
                        const matchedIps = Object.values(groupedByCompany).reduce((acc, domainData) => {
                            return acc + domainData.visitors?.length;
                        }, 0);
                        const matchedPercentage = totalIps ? ((matchedIps / totalIps) * 100).toFixed(2) : 0;
                        const summaryString = `After analyzing ${totalIps.toLocaleString()} visitor IPs${dateRange === "week" ? " last week" : dateRange === "month" ? " last month" : dateRange === "year" ? " last year" : ""}, we matched ${matchedPercentage}% to companies.`;
                        setSummary(summaryString);
                        setEnrichedData(enrichedDataRef.current);
                    }
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchCSVData();
    }, [userInfo?._id, dateRange, currentTab, selectedICPFilter]);

    const updateDomains = async (newDomains) => {
        const userId = userInfo?._id;

        if (userId) {
            const response = await updateAllowedDomainsRef.current({
                userId,
                domain: newDomains,
            });

            if (response === "Domains list updated successfully.") {
                setAllowedDomains(newDomains);
            }
        }
    };

    return (
        <MainLayout loading={loading}>
            <MainTitleBar>
                <p>Website Intent</p>
            </MainTitleBar>
            <NavigationTabs setTabOpen={setTabOpen} tabOpen={tabOpen} />
            {tabOpen === "Website Visitors" && (
                <div className="flex flex-col md:items-center md:flex-row md:justify-between gap-5 p-[24px] border-b border-[#E7E7E7]">
                    <div className="flex flex-col gap-2">
                        <h2 className="text-[18px] xl:text-[20px] text-[#050505] font-medium leading-[1.2]">Top companies visitors</h2>
                        <p className="text-[14px] xl:text-[16px] text-[#5D5D5D] leading-none">
                            The top visitors-companies (based on total pageviews) that visited your website{" "}
                            {dateRange === "week"
                                ? " in the last 7 days"
                                : dateRange === "month"
                                  ? " in the last one month"
                                  : dateRange === "year"
                                    ? " in the last one year"
                                    : ""}
                            .
                        </p>
                    </div>
                    {/* Select Date Range Section */}
                    <div className="font-Outfit flex flex-col gap-6 lg:flex-row lg:items-center">
                        <div className="flex gap-4 lg:items-center">
                            <div>
                                <Button
                                    variant="outlined"
                                    onClick={handleDateButtonClick}
                                    sx={menuButtonStyles}
                                    endIcon={
                                        <svg
                                            className="h-5 w-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M5.23 7.21a.75.75 0 011.06-.02L10 10.72l3.71-3.53a.75.75 0 111.04 1.08l-4 3.75a.75.75 0 01-1.04 0l-4-3.75a.75.75 0 01-.02-1.06z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    }
                                >
                                    {selectDateTag}
                                </Button>
                                <Menu
                                    anchorEl={anchorDateEl}
                                    open={Boolean(anchorDateEl)}
                                    onClose={handleDateButtonClose}
                                    MenuListProps={{
                                        "aria-labelledby": "date-select-button",
                                    }}
                                >
                                    <MenuItem sx={menuItemStyles} onClick={() => changeDateRange("week")}>
                                        Past 7 Days
                                    </MenuItem>
                                    <MenuItem sx={menuItemStyles} onClick={() => changeDateRange("month")}>
                                        Past 30 Days
                                    </MenuItem>
                                    <MenuItem sx={menuItemStyles} onClick={() => changeDateRange("year")}>
                                        Past Year
                                    </MenuItem>
                                    <MenuItem sx={menuItemStyles} onClick={() => changeDateRange("all")}>
                                        All Data
                                    </MenuItem>
                                </Menu>
                            </div>
                            <div>
                                <Button
                                    variant="outlined"
                                    onClick={handleICPFilterButtonClick}
                                    sx={menuButtonStyles}
                                    endIcon={
                                        <svg
                                            className="h-5 w-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M5.23 7.21a.75.75 0 011.06-.02L10 10.72l3.71-3.53a.75.75 0 111.04 1.08l-4 3.75a.75.75 0 01-1.04 0l-4-3.75a.75.75 0 01-.02-1.06z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    }
                                >
                                    {selectedICPFilter}
                                </Button>
                                <Menu
                                    anchorEl={anchorICPFilterEl}
                                    open={Boolean(anchorICPFilterEl)}
                                    onClose={handleICPFilterButtonClose}
                                    MenuListProps={{
                                        "aria-labelledby": "icp-filter-button",
                                    }}
                                >
                                    {iCPFilters && iCPFilters.length > 0 ? (
                                        iCPFilters.map((filter, index) => (
                                            <MenuItem key={index} sx={menuItemStyles} onClick={() => handleFilterSelect(filter)}>
                                                {filter.name}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem sx={menuItemStyles} disabled>
                                            No Filters Available
                                        </MenuItem>
                                    )}
                                </Menu>
                            </div>
                        </div>

                        <div className="flex gap-2">
                            <SearchProspectButton level={level} department={department} filterProps={filterProps} />
                            <ExportButton data={analyzedData} />
                        </div>
                    </div>
                </div>
            )}
            <div className={tabOpen === "Website Visitors" ? "w-full h-full grid grid-cols-1 lg:grid-cols-[auto_1fr] search-table" : ""}>
                {tabOpen === "Website Visitors" && (
                    <div className="h-full col-span-1 bg-white p-[24px] lg:overflow-y-scroll border-r border-[#E7E7E7]">
                        <LeftBar currentTab={currentTab} setCurrentTab={setCurrentTab} summary={summary} />
                    </div>
                )}
                <div className={`h-full w-full col-span-1 bg-white ${tabOpen === "Website Visitors" ? "lg:overflow-y-scroll" : ""}`}>
                    {tabOpen === "Analytics" && <Analytics data={visitorsDataRef.current} allowedDomains={allowedDomains} />}
                    {tabOpen === "Website Visitors" && (
                        <WebsiteReport
                            data={enrichedData}
                            currentTab={currentTab}
                            setAnalyzedData={setAnalyzedData}
                            analyzedData={analyzedData}
                            filterProps={filterProps}
                            setFilterProps={setFilterProps}
                        />
                    )}
                    {tabOpen === "Profile Exports" && <ProfileExport data={analyzedData} csvs={csvs} />}
                    {tabOpen === "Target Customer Persona" && (
                        <ICPFilter
                            filterOptions={filterOptions}
                            iCPFilters={iCPFilters}
                            setICPFilters={setICPFilters}
                            level={level}
                            setLevel={setLevel}
                            department={department}
                            setDepartment={setDepartment}
                        />
                    )}
                    {tabOpen === "Account Details" && (
                        <AccountDetails userId={userInfo?._id} allowedDomains={allowedDomains} updateDomains={updateDomains} />
                    )}
                </div>
            </div>
        </MainLayout>
    );
}
