import React, { useMemo } from "react";
import { Affix } from "rsuite";
import { PRODUCT_PRICE_BY_TYPE, PRODUCT_TYPE } from "@/utils/constants";
import { formatNumberWithCommas, formatNumberWithLetters } from "@/utils/common";

export default function PricingTable({ tiers, annual, products, topPosition }) {
    const container = React.useRef();

    const productPrices = useMemo(() => {
        return products.reduce((result, product) => {
            result[product.key] = product["creditsPerProduct"];
            return result;
        }, {});
    }, [products]);

    return (
        <div className="w-full mt-24 md:mt-40 flex flex-col hidden 3xs:block">
            <Affix
                top={topPosition || 0}
                container={() => {
                    return container.current;
                }}
            >
                <div ref={container} className="flex w-full justify-between border-b border-black bg-white">
                    <div className="font-OutfitMedium mb-2 xl:mb-4 2xl:mb-6 mr-6 xl:mr-10 2xl:mr-20 flex-1 self-end text-base xs:text-md md:text-xl lg:text-2xl xl:text-3xl 2xl:text-[2.125rem] xl:leading-[2.55rem]">
                        Total monthly usage limits by product
                    </div>
                    <div className="flex rounded-lg border border-b-0 border-black">
                        {products.map((product) => {
                            const price_obj =
                                product.variations.length > 1
                                    ? product.variations.find((x) => x.interval === (annual ? "year" : "month"))
                                    : product.variations[0];
                            return (
                                <div
                                    className={`${
                                        tiers[product.key].theme.main
                                    } flex h-full 2xs:w-[80px] xs:w-[104px] md:w-[143px] lg:w-[183px] xl:w-[243px] 2xl:w-[253px] flex-col items-center justify-center border-r-[1px] border-gray-950 p-2 md:p-4 xl:p-8`}
                                >
                                    {product.key === "free" && (
                                        <>
                                            <div className="font-OutfitSemiBold text-sm xs:text-md md:text-2xl lg:text-4xl mb-auto !leading-[1.2]">
                                                Credits per Product
                                            </div>
                                            <div className="w-full py-3 bg-gray-950 rounded-[8px]">
                                                <p className="font-Outfit text-white text-md leading-[1.4] text-center">Get Started</p>
                                            </div>
                                        </>
                                    )}
                                    {product.key !== "free" && (
                                        <>
                                            <div className="font-OutfitSemiBold text-sm xs:text-md md:text-2xl lg:text-4xl leading-[1.2]">
                                                {product.name}
                                            </div>
                                            <div className="font-Outfit flex items-end text-black mb-10">
                                                <span className="font-Outfit mr-1 mt-2 text-sm md:text-md lg:text-2xl leading-[1.2] text-black">
                                                    ${price_obj.price / 100}
                                                </span>
                                                <span className="font-OutfitBold text-xs md:text-sm">{annual ? "/year" : "/month"}</span>
                                            </div>
                                            <div className="w-full py-3 bg-gray-950 rounded-[8px]">
                                                <p className="font-Outfit text-white text-md leading-[1.4] text-center">
                                                    {formatNumberWithLetters(price_obj.credits)} Credits
                                                </p>
                                            </div>
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Affix>
            {/* Benefits table */}
            <div ref={container} className="my-15 flex w-full flex-col ">
                {Object.entries(PRODUCT_TYPE).map(([key, value]) => {
                    return (
                        <div
                            key={key}
                            className="my-2 grid w-full 2xs:grid-cols-[auto_repeat(4,80px)] xs:grid-cols-[auto_repeat(4,104px)] md:grid-cols-[auto_repeat(4,143px)] lg:grid-cols-[auto_repeat(4,183px)] xl:grid-cols-[auto_repeat(4,243px)] 2xl:grid-cols-[auto_repeat(4,253px)] gap-y-3 odd:bg-bluegrey-100"
                        >
                            <div className="mr-auto font-Outfit my-auto px-2 py-2 md:px-8 md:py-6 text-sm xs:text-base md:text-md xl:text-[1.5rem] text-stone-950">
                                {value}
                            </div>
                            {Object.keys(tiers).map((tier) => {
                                const priceObj = productPrices[tier]?.find((product) => product.name === value);

                                if (tier === "free")
                                    return (
                                        <div className="font-Outfit flex items-center justify-center px-5 py-3 text-[#050505] text-[16px] lg:text-[20px] xl:text-[22px]">
                                            {formatNumberWithCommas(PRODUCT_PRICE_BY_TYPE[key])}
                                        </div>
                                    );
                                return (
                                    <div className="font-Outfit flex items-center justify-center px-5 py-3 text-[#050505] text-[16px] lg:text-[20px] xl:text-[22px]">
                                        {formatNumberWithCommas(priceObj?.value)}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
