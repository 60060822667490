import React, { useEffect, useState } from "react";
import { useKompassFilters } from "@/hooks/useKompassFilters";
import useKompassSearch from "@/hooks/useKompassSearch";
import { Country, State } from "country-state-city";
import { countryList, departmentList, industryList, levelList, numberOfEmployeesList, revenueList, subDepartmentList } from "@/utils/filter";
import { ReactSVG } from "react-svg";
import SearchIcon from "@/assets/image/team/search.svg";
import { toast } from "react-toastify";

export default function FlexSearch({ handleSearchByFilter, setIsLoading, setIsSearchClicked }) {
    const [flexSearchInput, setFlexSearchInput] = useState(null);

    const { getFiltersByFlexSearch } = useKompassSearch();
    const {
        setIndustryName,
        setRevenue,
        setNumberOfEmployees,
        setLevel,
        setTitle,
        setCountry,
        setStatesList,
        setPersonalLocation,
        setCompanyLocation,
        setDept,
        setSubDepartment,
        setHqContinent,
        setHqCountry,
        setContinent,
        planInfo,
    } = useKompassFilters();

    const countryListForIsoCode = Country.getAllCountries().map((country) => ({
        label: country.name,
        value: country.isoCode,
    }));
    const getIsoCode = (country) => {
        const countryObject = countryListForIsoCode.find((obj) => obj.label.toLowerCase() === country?.toLowerCase());
        return countryObject?.value;
    };

    const handleCountryChange = (selectedCountries) => {
        setStatesList([]);
        // find the country label from list
        const countryLabelValuePairs = countryList.filter((country) => selectedCountries.includes(country.value)).map((country) => ({...country, status: "include"}));
        setCountry(countryLabelValuePairs);
        if (selectedCountries.length > 0) {
            const allStates = selectedCountries.flatMap((country) => {
                const countryIsoCode = getIsoCode(country);
                return State.getStatesOfCountry(countryIsoCode).map((state) => ({
                    label: state.name,
                    value: state.name,
                }));
            });

            setStatesList(allStates);
        } else {
            setStatesList([]);
        }
    };

    // watch all relevant states and trigger handleSearch when ready
    const handleFlexSearch = async () => {
        if (!flexSearchInput) {
            toast.error("Please enter a search term");
            return;
        }

        setIsLoading(true);
        setIsSearchClicked(true);
        const res = await getFiltersByFlexSearch(flexSearchInput);
        if (res.status) {
            setIndustryName(industryList.filter((industry) => res.data.response.industry.includes(industry.value)).map((industry) => ({...industry, status: "include"})));
            setRevenue(revenueList.filter((revenue) => res.data.response.job_company_inferred_revenue.includes(revenue.value)).map((revenue) => ({...revenue, status: "include"})));
            setNumberOfEmployees(numberOfEmployeesList.filter((numberOfEmployees) => res.data.response.job_company_size.includes(numberOfEmployees.value)).map((numberOfEmployees) => ({...numberOfEmployees, status: "include"})));
            setLevel(levelList.filter((level) => res.data.response.job_title_levels.includes(level.value)).map((level) => ({...level, status: "include"})));
            setDept(departmentList.filter((department) => res.data.response.job_title_role.includes(department.value)).map((department) => ({...department, status: "include"})));
            setSubDepartment(subDepartmentList.filter((subDepartment) => res.data.response.job_title_sub_role.includes(subDepartment.value)).map((subDepartment) => ({...subDepartment, status: "include"})));
            setTitle(res.data.response.job_title);
            handleCountryChange(res.data.response.location_country);
            setPersonalLocation(res.data.response.location_name);
            setCompanyLocation(res.data.response.job_company_location_name);

            const resData = res.data.response;

            const isEmpty = (value) =>
                value === undefined ||
                value === null ||
                (typeof value === "string" && value.trim() === "") ||
                (Array.isArray(value) && value.length === 0) ||
                (typeof value === "object" && Object.keys(value).length === 0);

            const currentJob = {
                ...(resData.job_title && !isEmpty(resData.job_title) ? { job_title: resData.job_title } : {}),
                ...(industryList.some((industry) => industry.value === res.data.response.industry) && !isEmpty(resData.industry)
                    ? { industry: resData.industry }
                    : {}),
                ...(resData.job_title_levels && !isEmpty(resData.job_title_levels) ? { job_title_levels: resData.job_title_levels } : {}),
                ...(resData.job_title_role && !isEmpty(resData.job_title_role) ? { job_title_role: resData.job_title_role } : {}),
                ...(resData.job_title_sub_role && !isEmpty(resData.job_title_sub_role)
                    ? { job_title_sub_role: resData.job_title_sub_role }
                    : {}),
                ...(resData.job_company_size && !isEmpty(resData.job_company_size) ? { job_company_size: resData.job_company_size } : {}),
            };

            const pastJob = {
                ...(resData.job_company_location_continent && !isEmpty(resData.job_company_location_continent)
                    ? { "experience.company.location.continent": resData.job_company_location_continent }
                    : {}),
                ...(resData.job_company_location_country && !isEmpty(resData.job_company_location_country)
                    ? { "experience.company.location.country": resData.job_company_location_country }
                    : {}),
            };

            const params = {
                ...(resData.location_name && !isEmpty(resData.location_name) ? { location_name: resData.location_name } : {}),
                ...(Object.keys(currentJob).length ? { currentJob } : {}),
                ...(resData.job_company_inferred_revenue && !isEmpty(resData.job_company_inferred_revenue)
                    ? { job_company_inferred_revenue: resData.job_company_inferred_revenue }
                    : {}),
                ...(resData.job_company_location_name && !isEmpty(resData.job_company_location_name)
                    ? { job_company_location_name: resData.job_company_location_name }
                    : {}),
                ...(resData.location_continent && !isEmpty(resData.location_continent)
                    ? { location_continent: resData.location_continent }
                    : {}),
                ...(resData.location_country && !isEmpty(resData.location_country) ? { location_country: resData.location_country } : {}),
                ...(Object.keys(pastJob).length ? { pastJob } : {}),
            };

            await handleSearchByFilter({ params: { ...params, subscriptionName: planInfo.name } });
        }
        setIsLoading(false);
    };

    return (
        <>
            <div className=" flex gap-2 items-center w-full px-8 mt-[25px]">
                <div className="relative w-full">
                    <input
                        onChange={(e) => setFlexSearchInput(e.target.value)}
                        value={flexSearchInput}
                        type="search"
                        placeholder="Flex Search"
                        className="font-Outfit border border-stone-250 rounded-lg w-full py-2 px-2.5 pl-10 text-sm outline-none mx-auto text-dgray-900 placeholder-dgray-500"
                    />
                    <ReactSVG
                     src={SearchIcon}
                     beforeInjection={(svg) => {
                        svg.setAttribute("style", "width: 18px; height: 18px;");
                    }}
                     className="absolute top-[8px] 2xl:top-[10px] left-3" />
                </div>
                <button
                    onClick={handleFlexSearch}
                    className="bg-black py-2 font-OutfitMedium px-4 text-white whitespace-nowrap rounded-lg text-sm "
                >
                    Flex Search
                </button>
            </div>
        </>
    );
}
