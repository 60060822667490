import useKompassRouter from "@/hooks/useKompassRouter";
import { useEffect, useState } from "react";

export default ({ firstName, lastName, title, company }) => {
    const [initial, setInitial] = useState("");
    const { kompassNavigate } = useKompassRouter();
    useEffect(() => {
        let name = firstName + " " + lastName;
        const nameParts = name?.split(" ");
        const initials = nameParts.map((part) => part.charAt(0)).join("");
        setInitial(initials.toUpperCase());
    }, []);
    return (
        <div>
            <div className="flex flex-row justify-start gap-5 w-full">
                <div className="flex items-center justify-center md:h-[125px] md:w-[125px] h-[90px] w-[90px] min-w-[90px] bg-white max-lg:rounded-[10px] rounded-[15px] shadow-custom2">
                    <h1 className="p-4 font-[700] md:text-[50px] text-[30px]">{initial}</h1>
                </div>
                <div className="flex flex-col ">
                    <h1 className="font-[600] text-[16px] md:text-[25px]">{`${firstName} ${lastName}'s Email & Phone Number`}</h1>
                    <p className="font-[500] text-[12px] md:text-[17px] text-dgray-700 ">{`${title} at ${company.name}.`}</p>
                    <button className="px-8 hidden md:block py-2.5 text-sm rounded-md w-fit bg-primary-blue text-white mt-auto font-semibold">{`View ${firstName}'s contact data for free`}</button>
                </div>
            </div>
            <button
                onClick={() => kompassNavigate("/signin")}
                className="px-8 md:hidden py-2.5 text-sm rounded-md w-fit bg-primary-blue text-white mt-7 font-semibold"
            >{`View ${firstName}'s contact data for free`}</button>
        </div>
    );
};
