import { useEffect, useState, useRef } from "react";
import TrendDashboard from "./TrendDashboard";
import DailyCollectedProfiles from "./DailyCollectedProfiles";
import VisitorsAnalytics from "./VisitorsAnalytics";
import SelectDropdown from "@/components/common/SelectDropdown";

function Analytics({ data, allowedDomains }) {
    const [newProfiles, setNewProfiles] = useState(0);
    const [returningProfiles, setReturingProfiles] = useState(0);
    const [newProfilesByDays, setNewProfilesByDays] = useState([]);
    const [returningProfilesByDays, setReturningProfilesByDays] = useState([]);
    const [duration, setDuration] = useState(7);
    const [domain, setDomain] = useState(allowedDomains[0]);
    const [domainOptions, setDomainOptions] = useState([]);
    const newProfilesRef = useRef(0);
    const returningProfilesRef = useRef(0);

    useEffect(() => {
        const setProfileNumbers = () => {
            if (!data) return;

            const oneDurationAgo = new Date();
            oneDurationAgo.setDate(oneDurationAgo.getDate() - duration);
            const oneDurationAgoTimestamp = oneDurationAgo.getTime();
            const ipsInDateRange = [];

            const newData = data.filter((obj) => obj.domain === domain);

            const tempDomains = [];
            allowedDomains.forEach((domain) => {
                tempDomains.push({ label: domain, value: domain });
            });
            setDomainOptions(tempDomains);

            newData.forEach((obj) => {
                if (!ipsInDateRange.includes(obj.ip) && obj.created_at >= oneDurationAgoTimestamp) {
                    ipsInDateRange.push(obj.ip);
                }
            });

            const returningIPs = [];

            newData.forEach((obj) => {
                if (ipsInDateRange.includes(obj.ip) && !returningIPs.includes(obj.ip) && obj.created_at < oneDurationAgoTimestamp) {
                    returningIPs.push(obj.ip);
                }
            });

            const newIPs = ipsInDateRange.filter((ip) => !returningIPs.includes(ip));

            returningProfilesRef.current = returningIPs.length;
            newProfilesRef.current = newIPs.length;

            setNewProfiles(newProfilesRef.current);
            setReturingProfiles(returningProfilesRef.current);

            const lastDurationData = newData.filter((obj) => obj.created_at >= oneDurationAgo);
            lastDurationData.sort((a, b) => a.created_at - b.created_at);

            const newDataFrequencies = new Array(duration).fill(0);
            const returningDataFrequencies = new Array(duration).fill(0);
            const newIPsTraversed = {};
            const returningIPsTraversed = {};
            const now = new Date().toISOString().split("T")[0];

            lastDurationData.forEach((obj) => {
                if (newIPs.includes(obj.ip)) {
                    const date = new Date(obj.created_at).toISOString().split("T")[0];
                    const nowDateObj = new Date(now);
                    const createdAtDateObj = new Date(date);
                    if (!newIPsTraversed[obj.ip]) {
                        const dayIndex = Math.floor((nowDateObj - createdAtDateObj) / (1000 * 60 * 60 * 24)) - 1;
                        newDataFrequencies[dayIndex]++;
                        newIPsTraversed[obj.ip] = date;
                    } else if (newIPsTraversed[obj.ip] < date) {
                        const dayIndex = Math.floor((nowDateObj - createdAtDateObj) / (1000 * 60 * 60 * 24)) - 1;
                        returningDataFrequencies[dayIndex]++;
                        newIPsTraversed[obj.ip] = date.slice(0, 10);
                    }
                } else if (returningIPs.includes(obj.ip)) {
                    const date = new Date(obj.created_at).toISOString().split("T")[0];
                    const nowDateObj = new Date(now);
                    const createdAtDateObj = new Date(date);
                    if (!returningIPsTraversed[obj.ip]) {
                        const dayIndex = Math.floor((nowDateObj - createdAtDateObj) / (1000 * 60 * 60 * 24)) - 1;
                        returningDataFrequencies[dayIndex]++;
                        returningIPsTraversed[obj.ip] = date;
                    } else if (returningIPsTraversed[obj.ip] < date) {
                        const dayIndex = Math.floor((nowDateObj - createdAtDateObj) / (1000 * 60 * 60 * 24)) - 1;
                        returningDataFrequencies[dayIndex]++;
                        returningIPsTraversed[obj.ip] = date;
                    }
                }
            });

            setNewProfilesByDays(newDataFrequencies);
            setReturningProfilesByDays(returningDataFrequencies);
        };

        setProfileNumbers();
    }, [duration, domain, allowedDomains, data]);

    return (
        <>
            <div className="flex flex-wrap gap-10 justify-between items-center mb-12 p-[24px] border-b border-[#E7E7E7]">
                <div className="flex flex-col gap-2">
                    <h2 className="text-[18px] xl:text-[20px] text-[#050505] font-medium leading-[1.2]">Profile Export</h2>
                    <p className="text-[14px] xl:text-[16px] text-[#5D5D5D] md:whitespace-nowrap leading-none">
                        Analysis of your website traffic for the past {duration === 7 ? "week" : "month"} for {domain}
                    </p>
                </div>
                <div className="flex gap-4 flex-col md:flex-row">
                    <SelectDropdown options={domainOptions} selectedOption={domain} defaultValue={domain} onChange={setDomain} />
                    <SelectDropdown
                        options={[
                            { label: "Past 7 days", value: 7 },
                            { label: "Past 30 days", value: 30 },
                        ]}
                        selectedOption={duration}
                        defaultValue={duration}
                        onChange={setDuration}
                    />
                </div>
            </div>
            <div className="p-[24px]">
                <TrendDashboard newProfiles={newProfiles} returningProfiles={returningProfiles} />
                <div className="mt-12 lg:w-3/4">
                    <DailyCollectedProfiles
                        newProfiles={newProfiles}
                        newProfilesByDays={newProfilesByDays}
                        returningProfilesByDays={returningProfilesByDays}
                        duration={duration}
                    />
                    {/* <VisitorsAnalytics /> */}
                </div>
            </div>
        </>
    );
}

export default Analytics;
