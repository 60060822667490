import React from "react";

function ActionButtons({ saveICPFilter, discardChanges }) {
    return (
        <div className="flex flex-col gap-6 justify-start">
            <div>
                <h2 className="text-[18px] xl:text-[20px] text-[#050505] leading-[1.2]">Apply filter to Integration(s)</h2>
                <p className="mt-4 text-[14px] xl:text-[16px] text-[#5D5D5D] leading-[1.2]">
                    If applied, only the leads that match your ICP will sync to the integration(s) you choose, else all profiles will sync
                    to the integration(s).
                </p>
            </div>
            <div className="flex flex-col sm:flex-row gap-6 items-center">
                <button
                    className="bg-white border border-[#E7E7E7] text-[#050505] text-[14px] xl:text-[16px] leading-[1.2] rounded-[8px] w-[355px] h-[48px] flex items-center justify-center"
                    onClick={discardChanges}
                >
                    Discard Changes
                </button>
                <button
                    className="text-white bg-[#090C05] rounded-[8px] w-[355px] text-[14px] xl:text-[16px] leading-[1.2] w-[355px] h-[48px] items-center justify-center"
                    onClick={saveICPFilter}
                >
                    Save ICP Filter
                </button>
            </div>
        </div>
    );
}

export default ActionButtons;
