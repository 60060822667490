import useIPsDeanonymization from "@/hooks/useIPsDeanonymization";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import IpCreditSlider from "./IPCreditSlider";
import { CircularProgress } from "@mui/material";

const options = [
    0, 5000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 55000, 60000, 65000, 70000, 75000, 80000, 85000, 90000, 95000,
    100000,
];

export default function AccountDetails({ userId, allowedDomains, updateDomains }) {
    const [availableCredits, setAvailableCredits] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [usedCredits, setUsedCredits] = useState(0);
    const [priceIndex, setPriceIndex] = useState(0);
    const [newCreditsAmount, setNewCreditsAmount] = useState(0);
    const [isAllocating, setIsAllocating] = useState(false);
    const { getAvailableCredits, allocateCredits } = useIPsDeanonymization();
    const getAvailableCreditsRef = useRef();
    getAvailableCreditsRef.current = getAvailableCredits;

    useEffect(() => {
        setIsLoading(true);

        const getCredits = async () => {
            const credits = await getAvailableCreditsRef.current();
            setIsLoading(false);
            setAvailableCredits(credits?.availableCredits || 0);
            setUsedCredits(credits?.usedCredits || 0);
        };
        getCredits();
    }, []);

    useEffect(() => {
        setNewCreditsAmount(options[priceIndex]);
    }, [priceIndex]);

    const handleAllocateCredits = async () => {
        const params = { amount: newCreditsAmount };
        setIsAllocating(true);

        const response = await allocateCredits(params);

        setIsAllocating(false);

        if (response.status) {
            toast.success("Credits allocated succesfully");
            setIsLoading(true);
            const credits = await getAvailableCredits();
            setIsLoading(false);
            setAvailableCredits(credits?.availableCredits || 0);
        } else {
            toast.error(response?.message || "Error while allocating credits. Please try again");
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            domain: "",
        },
        validationSchema: Yup.object({
            domain: Yup.string()
                .matches(/^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,11}?$/, "Invalid domain")
                .required("Required"),
        }),
        onSubmit: async (values) => {
            if (!allowedDomains.includes(values.domain.trim())) {
                const updatedDomains = [...allowedDomains, values.domain.trim()];
                await updateDomains(updatedDomains);
            } else {
                console.log("Domain already exists");
            }
            formik.resetForm();
        },
    });

    const handleDomainDelete = async (domain) => {
        const updatedDomains = allowedDomains.filter((d) => d !== domain);
        await updateDomains(updatedDomains);
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Enter") {
                formik.submitForm();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [formik]);

    return (
        <div className="font-Outfit">
            <div className="flex flex-col gap-2 mb-4 p-[24px] border-b border-[#E7E7E7]">
                <h2 className="text-[18px] xl:text-[20px] text-[#050505] font-medium leading-[1.2]">Website Visitor Report Setup</h2>
                <p className="text-[14px] xl:text-[16px] text-[#5D5D5D] leading-none">
                    To enable the IP deanonymization feature, please copy the script from the field above and paste it into the head tag of
                    the website where IP parsing should be enabled. Additionally, to ensure security, you should add the domain of this
                    website to the list of allowed domains in the field above.
                </p>
            </div>
            <div className="p-[24px]">
                <div className="flex flex-col gap-2 mb-10 text-[#050505]">
                    <label className="text-[14px] xl:text-[16px]">
                        <span className="font-semibold ">Script Tag </span>(copy the script and paste it into the head tag of the website
                        where IP parsing should be enabled)
                    </label>
                    <textarea
                        className="max-w-[800px] p-4 rounded-[8px] border border-stone-250 focus:outline-none opacity-90 text-[12px] xl:text-[14px] resize-none"
                        value={`<script async src="${process.env.REACT_APP_WEB_TRAFFIC_IP_ENDPOINT}/collect-data?userId=${userId}"></script>`}
                        readOnly
                    />
                </div>
                <div className="flex flex-col gap-2 mb-12">
                    <label className="text-[14px] xl:text-[16px]">
                        <span className="font-semibold">Allowed domains </span>(add domain of the website where IP parsing should be enabled
                        to the list of allowed domains)
                    </label>
                    {allowedDomains && allowedDomains.length > 0 && (
                        <div className="flex gap-3 max-w-[800px]">
                            {allowedDomains.map((domain) => (
                                <div
                                    key={domain}
                                    className="relative bg-[#f6f6f6] rounded-[4px] text-[12px] xl:text-[14px] text-[#050505] px-3 py-1.5"
                                >
                                    <p>{domain}</p>
                                    <button
                                        className="absolute right-[-3px] top-[-11px]"
                                        onClick={async () => await handleDomainDelete(domain)}
                                    >
                                        x
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                    <input
                        value={formik.values.domain}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="text"
                        name="domain"
                        placeholder="domain.com"
                        className="max-w-[800px] px-4 py-2 rounded-[8px] border border-stone-250 focus:outline-none opacity-90 text-[12px] xl:text-[14px] font-light leading-[1.4] resize-none	"
                    />
                </div>
                <div className="mb-10">
                    <p className="font-Outfit text-[18px] xl:text-[20px] font-semibold mb-6">Credits Summary</p>
                    <div className="flex flex-col md:flex-row gap-6">
                        <div className="w-full flex flex-col gap-2 max-w-[536px] px-6 py-4 border border-[#E7E7E7] rounded-[8px]">
                            {isLoading && <p className="text-[14px] xl:text-[16px] font-bold text-[#888]">Loading...</p>}
                            {!isLoading && <p className="text-[24px] font-bold text-[#191919]">{availableCredits}</p>}
                            <hr className="border-stone-250"></hr>
                            <p className="text-[14px] xl:text-[16px] text-[#888]">Remaining credits</p>
                        </div>
                        <div className="w-full flex flex-col gap-2 max-w-[536px] px-6 py-4 border border-[#E7E7E7] rounded-[8px]">
                            {isLoading && <p className="text-[14px] xl:text-[16px] font-bold text-[#888]">Loading...</p>}
                            {!isLoading && <p className="text-[24px] font-bold text-[#191919]">{usedCredits}</p>}
                            <hr className="border-stone-250"></hr>
                            <p className="text-[14px] xl:text-[16px] text-[#888]">Used credits</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col">
                    <p className="font-Outfit text-[18px] xl:text-[20px] font-semibold mb-6">Allocate Credits for Website Visitors</p>
                    <div className="px-3">
                        <IpCreditSlider options={options} state={[priceIndex, setPriceIndex]} />
                    </div>
                    <button
                        className="bg-black py-4 w-full max-w-[485px] text-[14px] xl:text-[16px] text-center text-white rounded-[8px] mt-10 flex items-center justify-center"
                        onClick={handleAllocateCredits}
                        disabled={isAllocating}
                    >
                        {isAllocating ? <CircularProgress size={20} color="inherit" /> : "Allocate Credits"}
                    </button>
                </div>
            </div>
        </div>
    );
}
