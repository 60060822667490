import React from "react";

const TrendCard = ({ title, value, percentage, icon }) => {
    return (
        <div className="flex flex-col w-3/12 max-md:w-full rounded-[8px] border border-[#E7E7E7] p-4 ">
            <div className="flex gap-2 items-center w-full justify-between whitespace-nowrap pb-4 border-b border-[#E7E7E7]">
                <p className="text-[24px] font-bold text-[#050505]">{value}</p>
                <div className="flex gap-0.5 justify-center items-center p-1 text-blue-500 bg-blue-500 bg-opacity-10 rounded-[4px]">
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/5caf3258c58ee79af695a413135c56b37a3b42f5559ee7e59e71df7933ee4801?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79"
                        className="object-contain shrink-0 w-2 aspect-square"
                        alt=""
                    />
                    <p className="text-[12px] xl:text-[14px]">{percentage.toFixed(2)}%</p>
                </div>
            </div>
            <p className="text-[14px] xl:text-[16px] text-[#050505] whitespace-nowrap pt-4 border-t border-[#E7E7E7]">{title}</p>
        </div>
    );
};

export default TrendCard;
