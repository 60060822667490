import React from "react";
import { Button } from "@material-tailwind/react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import BlueSwitch from "../team/BlueSwitch";
import { ReactComponent as ContactsSVGIcon } from "@/assets/image/icons/icon-contacts.svg";
import { ReactComponent as AccountSettingSVGIcon } from "@/assets/image/icons/icon-account-settings.svg";
import { ReactComponent as FindPageSVGIcon } from "@/assets/image/icons/icon-find-in-page.svg";
import { ReactComponent as MailSVGIcon } from "@/assets/image/icons/icon-mail.svg";
import { ReactComponent as InfoSVGIcon } from "@/assets/image/icons/Info.svg";
import { ReactComponent as TeamIcon } from "@/assets/image/team/team.svg";

import SelectDropdown from "@/components/common/SelectDropdown";
import { Divider } from "@mui/material";
import useTeam from "@/hooks/useTeam";
import { useEffect, useState, useCallback, useContext } from "react";

const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Email is required"),
    password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
});

export default function OtherSettingTabComponent() {
    const { getTeamMembers, changeOwner } = useTeam();

    const initialValues = {
        email: "anna.a@pinedev.eu",
        password: "",
        role: "Director",
        member: null,
    };

    const handleSubmit = (values, { setSubmitting }) => {
        console.log(values);

        // console.log("Form submitted:", values);
        setSubmitting(false);
    };

    const roleOptions = ["All Type Email", "Email Type 1", "Email Type 2"];

    const [members, setMembers] = useState([]);

    const [newTeamOwner, setNewTeamOwner] = useState(null);

    useEffect(() => {
        (async () => {
            const response = await getTeamMembers({
                status: "ACTIVE",
            });
            setMembers([
                ...members,
                ...response.data
                    .map((member) => {
                        return {
                            value: member.userId,
                            label: member.name,
                        };
                    })
                    .filter((member) => members.findIndex((m) => m.value === member.value) === -1),
            ]);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ setFieldValue, values }) => (
                <Form className="p-6">
                    <div className="mb-4 inline-flex w-full items-center justify-start gap-2.5">
                        <div className="ml-4 inline-flex shrink grow basis-0 flex-col items-start justify-start gap-2">
                            <div className="font-Outfit leading-loose self-stretch text-3xl font-normal text-black">Other settings</div>
                            <div className="font-Outfit leading-tight self-stretch text-sm font-normal text-zinc-600">
                                Manage your profile and preferences here.
                            </div>
                        </div>
                        <Button type="submit" className="flex items-center justify-center rounded-md bg-stone-950 px-3 py-2">
                            <div className="font-Outfit leading-tight text-sm font-bold uppercase tracking-tight text-white">
                                Save changes
                            </div>
                        </Button>
                    </div>

                    <div className="flex flex-col gap-4 ">
                        <div className="flex w-full flex-col items-center justify-start gap-2 rounded-xl border border-stone-250 bg-white p-6">
                            <div className="mb-8 flex w-full flex-row items-center gap-2.5">
                                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-stone-150 p-2.5">
                                    <div className="relative flex h-5 w-5 flex-col items-start justify-start">
                                        <AccountSettingSVGIcon className="relative h-5 w-5 fill-current text-blue-500" />
                                    </div>
                                </div>
                                <div className="inline-flex w-full shrink grow basis-0 flex-col items-start justify-start">
                                    <div className="font-Outfit leading-relaxed self-stretch text-xl font-normal text-black">
                                        Contact preferences
                                    </div>
                                    <div className="font-Outfit leading-tight text-right text-sm font-normal text-stone-350">
                                        Enable SAML single sign on for your entire team
                                    </div>
                                </div>
                            </div>

                            <div className="flex w-full flex-col items-start justify-start gap-y-6">
                                <div className="flex w-full  flex-col items-start justify-center gap-2 lg:w-3/5">
                                    <div className="inline-flex items-center justify-between self-stretch">
                                        <div className="font-Outfit leading-snug text-base font-medium text-black">Email type</div>
                                    </div>

                                    <SelectDropdown
                                        options={roleOptions}
                                        selectedOption={values.role}
                                        onChange={(value) => setFieldValue("role", value)}
                                    />
                                </div>

                                <Divider className="w-full" />

                                <div className="flex flex-row items-center gap-2">
                                    <InfoSVGIcon className="relative h-4 w-4 fill-current text-stone-350" />
                                    <div className="font-Outfit leading-none text-xs font-normal text-black opacity-60">
                                        You may set your contact lookup preferences type ty ONLY search for professional OR personal emails.
                                        You will then only be charged a lookup if we find the corresponding type.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex w-full flex-col items-center justify-start gap-2 rounded-xl border border-stone-250 bg-white p-6">
                            <div className="mb-8 flex w-full flex-row items-center gap-2.5">
                                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-stone-150 p-2.5">
                                    <div className="relative flex h-5 w-5 flex-col items-start justify-start">
                                        <FindPageSVGIcon className="relative h-5 w-5 fill-current text-blue-500" />
                                    </div>
                                </div>
                                <div className="inline-flex w-full shrink grow basis-0 flex-col items-start justify-start">
                                    <div className="font-Outfit leading-relaxed self-stretch text-xl font-normal text-black">GDPR</div>
                                    <div className="font-Outfit leading-tight text-right text-sm font-normal text-stone-350">
                                        Enable SAML single sign on for your entire team
                                    </div>
                                </div>
                            </div>

                            <div className="flex w-full flex-col items-start justify-start gap-y-6">
                                <div className="flex w-full  flex-col items-start justify-center gap-2 lg:w-3/5">
                                    <div className="inline-flex items-center justify-between self-stretch">
                                        <div className="font-Outfit leading-snug text-base font-medium text-black">EU profiles</div>
                                        <div className="font-Outfit leading-tight text-right text-sm font-normal text-stone-350">
                                            Filter EU profiles from search results
                                        </div>
                                    </div>
                                    <div className="inline-flex items-center justify-start gap-2 self-stretch rounded-3xl bg-white">
                                        <BlueSwitch />
                                    </div>
                                </div>

                                <Divider className="w-full" />

                                <div className="flex flex-row items-center gap-2">
                                    <InfoSVGIcon className="relative h-4 w-4 fill-current text-stone-350" />
                                    <div className="font-Outfit leading-none text-xs font-normal text-stone-350">
                                        Filters EU profiles from KompassAI search based on available location data. Direct lookups EU
                                        Citizens via extention, api, files can still occur.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex w-full flex-col items-center justify-start gap-2 rounded-xl border border-stone-250 bg-white p-6">
                            <div className="mb-8 flex w-full flex-row items-center gap-2.5">
                                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-stone-150 p-2.5">
                                    <div className="relative flex h-5 w-5 flex-col items-start justify-start">
                                        <MailSVGIcon className="relative h-5 w-5 fill-current text-blue-500" />
                                    </div>
                                </div>
                                <div className="inline-flex w-full shrink grow basis-0 flex-col items-start justify-start">
                                    <div className="font-Outfit leading-relaxed self-stretch text-xl font-normal text-black">
                                        Email preferences
                                    </div>
                                    <div className="font-Outfit leading-tight text-right text-sm font-normal text-stone-350">
                                        Enable SAML single sign on for your entire team
                                    </div>
                                </div>
                            </div>

                            <div className="flex w-full flex-col items-start justify-start gap-y-6">
                                <div className="flex w-full  flex-col items-start justify-center gap-2 lg:w-3/5">
                                    <div className="inline-flex items-center justify-between self-stretch">
                                        <div className="font-Outfit leading-snug text-base font-medium text-black">
                                            Billing notification emails
                                        </div>
                                    </div>
                                    <div className="inline-flex items-center justify-start gap-2 self-stretch rounded-3xl bg-white">
                                        <BlueSwitch />
                                    </div>
                                </div>

                                <Divider className="w-full" />

                                <div className="flex flex-row items-center gap-2">
                                    <InfoSVGIcon className="relative h-4 w-4 fill-current text-stone-350" />
                                    <div className="font-Outfit leading-none text-xs font-normal text-stone-350">
                                        Filters EU profiles from KompassAI search based on available location data. Direct lookups EU
                                        Citizens via extention, api, files can still occur.
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* need to fix on BE */}
                        {/* <div className="flex w-full flex-col items-center justify-start gap-2 rounded-xl border border-stone-250 bg-white p-6">
              <div className="mb-8 flex w-full flex-row items-center gap-2.5">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-stone-150 p-2.5">
                  <div className="relative flex h-5 w-5 flex-col items-start justify-start">
                    <TeamIcon className="relative h-5 w-5 fill-current text-blue-500" />
                  </div>
                </div>
                <div className="inline-flex w-full shrink grow basis-0 flex-col items-start justify-start">
                  <div className="font-Outfit leading-relaxed self-stretch text-xl font-normal text-black">
                    Transfer Ownership
                  </div>
                  <div className="font-Outfit leading-tight text-right text-sm font-normal text-stone-350">
                    Choose a team member to take over your role as team member. All of your credits will be transferred to them.
                  </div>
                </div>
              </div>

              <div className="flex w-full flex-col items-start justify-start gap-y-6">
                <div className="flex w-full  flex-col items-start justify-center gap-2 lg:w-3/5">
                  <div className="inline-flex items-center justify-between self-stretch">
                    <div className="font-Outfit leading-snug text-base font-medium text-black">
                      Choose Team Member
                    </div>
                  </div>
                  <div className="inline-flex items-center justify-start gap-2 self-stretch rounded-3xl bg-white">
                    <SelectDropdown
                      options={members}
                      selectedOption={newTeamOwner}
                      onChange={(value) => {
                        setNewTeamOwner(
                          value
                        );
                      }}
                    />
                  </div>

                  <div className="ml-4 inline-flex shrink grow basis-0 flex-col items-start justify-start gap-2">
                  </div>
                  <div className="font-Outfit leading-tight text-sm font-bold uppercase tracking-tight text-white">
                    
                    </div>
                  <Button
                    type="button"
                    className="flex items-center justify-center rounded-md bg-stone-950 px-3 py-2"
                    onClick={async() => {
                      const response = await changeOwner({
                        newOwnerId: newTeamOwner,
                      });
                    }}
                  >
                    <div className="font-Outfit leading-tight text-sm font-bold uppercase tracking-tight text-white">
                      Confirm Ownership Transfer
                    </div>
                  </Button>
                </div>
              </div>
            </div> */}

                        <div className="flex w-full flex-col items-center justify-start gap-2 rounded-xl border border-stone-250 bg-white p-6">
                            <div className="mb-8 flex w-full flex-row items-center gap-2.5">
                                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-stone-150 p-2.5">
                                    <div className="relative flex h-5 w-5 flex-col items-start justify-start">
                                        <ContactsSVGIcon className="relative h-5 w-5 fill-current text-blue-500" />
                                    </div>
                                </div>
                                <div className="inline-flex w-full shrink grow basis-0 flex-col items-start justify-start">
                                    <div className="font-Outfit leading-relaxed self-stretch text-xl font-normal text-black">
                                        Account Status
                                    </div>
                                    <div className="font-Outfit leading-tight text-right text-sm font-normal text-stone-350">
                                        Your account
                                    </div>
                                </div>
                            </div>

                            <div className="flex w-full flex-col items-start justify-start gap-y-6">
                                <div className="flex w-full  flex-col items-start justify-center gap-2 lg:w-3/5">
                                    <div className="inline-flex items-center justify-between self-stretch">
                                        <div className="font-Outfit leading-snug text-base font-medium text-black">Danger Zone</div>
                                    </div>
                                    <div className="inline-flex flex-row items-center justify-start gap-2 self-stretch rounded-3xl bg-white">
                                        <div className="inline-flex h-6 w-28 items-center justify-center gap-1 rounded-3xl bg-stone-150 px-2.5">
                                            <div className="font-Outfit leading-none text-xs font-light text-stone-950">Active</div>
                                        </div>
                                        <button className="font-Outfit leading-tight text-base font-normal text-red-500 underline">
                                            De-activate and close account
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
