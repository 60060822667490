import { formatNumberWithCommas } from "@/utils/common";

function PlanDetails(props) {
    const { stripePrice } = props;

    return (
        <div className="font-Outfit text-sm leading-[1.4] flex flex-col gap-1 h-[76px] text-[#454545]">
            <div className="flex justify-between ">
                <p>Price per seat</p>
                <p className="font-semibold text-[#050505]">${stripePrice.price / 100}</p>
            </div>
            <div className="flex justify-between">
                <p>Total credits included</p>
                <p className="font-semibold text-[#050505]">{formatNumberWithCommas(stripePrice.credits)}</p>
            </div>
        </div>
    );
}

export default PlanDetails;
