import { useState } from "react";
import { Button } from "@material-tailwind/react";
import { styled } from "@mui/material/styles";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import cn from "classnames";
import { toast } from "react-toastify";
import ConfirmDialog from "@/components/email_validation/ConfirmDialog";
import { ReactComponent as CsvSVGIcon } from "@/assets/image/bulk/csv.svg";
import { ReactComponent as ContactSVGIcon } from "@/assets/image/bulk/contact.svg";
import { ReactComponent as TableEmailSVG } from "@/assets/image/bulk/table_email.svg";
import { ReactComponent as TableTXTSVG } from "@/assets/image/bulk/table_TXT.svg";
import StyledInput from "./StyledInput";
import FileUploadValidationWidget from "./FileUploadValidationWidget";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { useMutation, useQuery } from "@tanstack/react-query";
import { S3_URL } from "@/utils/constants";
import MainLoader from "../MainLoader";
import { useNavigate } from "react-router-dom";
const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // Yup's one is very forgiving, so we use another one

export default function EmailUploadWidget({ currentWidgetIndex, setCurrentWidgetIndex }) {
    const [validateOption, setValidateOption] = useState(0); // 0: csv/xls/xlsx, 1: linkedin profile
    const [visibleConfirmDlg, setVisibleConfirmDlg] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({});
    const options = [
        {
            icon: "csv",
            header: "I have a CSV to Upload",
            content: "Upload your e-mails in a CSV file",
        },
        {
            icon: "contact",
            header: "List of e-mail’s",
            content: "Give us a list of e-mails to validate",
        },
    ];
    const sendDataAsList = useHTTPRequest("/email-validation?", "PUT");
    const { mutateAsync: sendDataAsListMutation } = useMutation({
        mutationFn: sendDataAsList,
    });

    const Textarea = styled(BaseTextareaAutosize)(
        ({ theme }) => `
    box-sizing: border-box;
    width: calc(50% - 12px);
    max-height: 800px;
    overflow: auto !important;
    padding: 16px;
    border-radius: 8px;
    color: #090C05;
    background: #fff;
    border: 1px solid #E8E7E7 ;
    resize: none;
    transition: background-color 0.2s;
    &:hover {
      background-color: #F9F9F9;
    }
    &:focus {
      border-color: #4873FA;
      background-color: white;
    }
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
    );
    const initialValues = {
        list: "",
        emailListName: "",
    };
    const validationSchema = Yup.object().shape({
        list: Yup.string()
            .required("Please add at least one email")
            .test("isValidEmailList", "Email list formatting is invalid", (value, context) => {
                const listValidity = value
                    .split(",")
                    .map((value) => value.trim())
                    .map((email) => {
                        return Yup.string().matches(emailRegex).isValidSync(email);
                    });
                return !listValidity.includes(false);
            }),
        emailListName: Yup.string().required("Please name the list").max(80, "List name is too long!"),
    });

    const onOptionSelected = (option) => {
        setValidateOption(option);
    };

    const handleInitiateLoading = async (values) => {
        setFormValues(values);
        setVisibleConfirmDlg(true);
    };

    const getUsageData = useHTTPRequest("/credits/getUserCreditsUsed", "GET");
    const { data: creditsData } = useQuery({
        queryKey: ["CreditsUsage"],
        queryFn: () => getUsageData(),
    });

    const handleForm = async () => {
        try {
            setLoading(true);
            const formattedEmails = formValues.list.split(",").map((value) => {
                return { email: value.trim() };
            });
            if (creditsData?.data?.available < formattedEmails.length) {
                toast.error("You don't have enough email validation credits, please refill your credits");
                setLoading(false);
                return;
            }
            await sendDataAsListMutation(
                JSON.stringify({
                    emails: formattedEmails,
                    fileName: formValues.emailListName,
                    listName: "",
                })
            );
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error("Something went wrong, try again");
            return;
        }
        toast.success("Successfully uploaded!");
        setLoading(false);
        setTimeout(() => {
            setCurrentWidgetIndex(0); //TODO: Add additional screen with list?
        }, 2000);
    };

    const navigate = useNavigate();

    return (
        <>
            {loading && <MainLoader />}

            <div className="flex w-full  flex-col items-center justify-center">
                <div className="my-6 w-full flex flex-col">
                    <div className="flex flex-col font-Outfit text-left text-[#090c05] text-xl font-medium font-['Outfit'] leading-normal">
                        What format is your contact list in?
                    </div>
                    <div className=" w-full flex flex-col md:flex-row font-Outfit mt-10 text-center text-lg justify-start gap-4">
                        {options.map((option, index) => {
                            return (
                                <div
                                    onClick={() => {
                                        onOptionSelected(index);
                                    }}
                                    className="w-[224px] group justify-start rounded-t-lg gap-3 inline-flex bg-white border-[1px] !border-b-0 border-[#E6E6E6] px-4 py-3 transition-all duration-300 cursor-pointer hover:bg-[#000] aria-selected:bg-[#000]"
                                    aria-selected={index === validateOption}
                                >
                                    {index === 0 ? (
                                        <svg
                                            className="shrink-0 group-aria-selected:fill-current group-aria-selected:text-white group-hover:fill-current group-hover:text-white transition-all duration-300"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <mask
                                                id="mask0_2335_13229"
                                                style={{ maskType: "alpha" }}
                                                maskUnits="userSpaceOnUse"
                                                x="0"
                                                y="0"
                                                width="20"
                                                height="20"
                                            >
                                                <rect width="20" height="20" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_2335_13229)">
                                                <path
                                                    d="M9.25 16V8.875L7.0625 11.0625L6 10L10 6L14 10L12.9375 11.0625L10.75 8.875V16H9.25ZM4 6V4.5C4 4.0875 4.14694 3.73438 4.44083 3.44063C4.73472 3.14688 5.08806 3 5.50083 3H14.5058C14.9186 3 15.2708 3.14688 15.5625 3.44063C15.8542 3.73438 16 4.0875 16 4.5V6H14.5V4.5H5.5V6H4Z"
                                                    fill="currentColor"
                                                />
                                            </g>
                                        </svg>
                                    ) : (
                                        <svg
                                            className="shrink-0 group-aria-selected:fill-current group-aria-selected:text-white group-hover:fill-current group-hover:text-white transition-all duration-300"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <mask
                                                id="mask0_2335_13234"
                                                style={{ maskType: "alpha" }}
                                                maskUnits="userSpaceOnUse"
                                                x="0"
                                                y="0"
                                                width="20"
                                                height="20"
                                            >
                                                <rect width="20" height="20" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_2335_13234)">
                                                <path
                                                    d="M6 7.5V6H17V7.5H6ZM6 10.75V9.25H17V10.75H6ZM6 14V12.5H17V14H6ZM3.75 7.5C3.55556 7.5 3.38194 7.42361 3.22917 7.27083C3.07639 7.11806 3 6.94097 3 6.73958C3 6.53819 3.07639 6.36458 3.22917 6.21875C3.38194 6.07292 3.55903 6 3.76042 6C3.96181 6 4.13542 6.07187 4.28125 6.21562C4.42708 6.35937 4.5 6.5375 4.5 6.75C4.5 6.94444 4.42812 7.11806 4.28437 7.27083C4.14062 7.42361 3.9625 7.5 3.75 7.5ZM3.75 10.75C3.55556 10.75 3.38194 10.6736 3.22917 10.5208C3.07639 10.3681 3 10.191 3 9.98958C3 9.78819 3.07639 9.61458 3.22917 9.46875C3.38194 9.32292 3.55903 9.25 3.76042 9.25C3.96181 9.25 4.13542 9.32188 4.28125 9.46563C4.42708 9.60938 4.5 9.7875 4.5 10C4.5 10.1944 4.42812 10.3681 4.28437 10.5208C4.14062 10.6736 3.9625 10.75 3.75 10.75ZM3.75 14C3.55556 14 3.38194 13.9236 3.22917 13.7708C3.07639 13.6181 3 13.441 3 13.2396C3 13.0382 3.07639 12.8646 3.22917 12.7188C3.38194 12.5729 3.55903 12.5 3.76042 12.5C3.96181 12.5 4.13542 12.5719 4.28125 12.7156C4.42708 12.8594 4.5 13.0375 4.5 13.25C4.5 13.4444 4.42812 13.6181 4.28437 13.7708C4.14062 13.9236 3.9625 14 3.75 14Z"
                                                    fill="currentColor"
                                                />
                                            </g>
                                        </svg>
                                    )}
                                    <div className="flex flex-col gap-2">
                                        <div className="font-Outfit text-base font-normal font-['Outfit'] leading-snug select-none self-stretch text-left text-gray-950 transition-all  duration-300 group-hover:text-white  group-aria-selected:text-white">
                                            {option.header}
                                        </div>
                                        {/* <div className="font-Outfit select-none text-left text-xl font-light text-gray-950 transition-all duration-300 group-hover:text-white group-aria-selected:text-white">
                                                {option.content}
                                            </div> */}
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {validateOption === 0 && currentWidgetIndex === 1 && (
                        <div className="border border-[#e7e7e7]  p-6 rounded-tr-lg rounded-bl-lg rounded-br-lg ">
                            <div className="font-Outfit flex flex-col gap-4 items-start">
                                <p className="font-Outfit text-left  text-[#050505] text-xl font-semibold font-['Outfit'] leading-normal">
                                    What KompassAI Needs
                                </p>
                                <div className="flex flex-col gap-2 font-Outfit text-[#5d5d5d] text-base font-normal font-['Outfit'] leading-tight">
                                    <p>Please upload your contact list in CSV or TXT format</p>
                                    <p>CSV file must contain header row and a field that contains 'email' in its name</p>
                                    <p>TXT file should contain list of emails separated by comma</p>
                                </div>
                            </div>
                            <div className="flex flex-col mt-8 md:flex-row gap-4 md:gap-8 w-full md:space-between">
                                <TableEmailSVG />
                                <div className="my-auto flex items-center justify-center">
                                    <p className="font-Outfit text-[#050505] text-base font-normal font-['Outfit'] leading-tight">or</p>
                                </div>
                                <TableTXTSVG />
                            </div>
                            <hr className="border-t border-[#e7e7e7] my-8"></hr>
                            <div className="font-Outfit flex flex-col text-left">
                                <p className="font-Outfit text-left text-[#050505] text-xl font-semibold font-['Outfit'] leading-normal mb-2">
                                    Use our template for the most accurate results
                                </p>
                                <span className="font-Outfit text-left text-[#5d5d5d] text-base font-normal font-['Outfit'] leading-tight mb-4">
                                    Download our CSV template for correct column format.
                                </span>

                                <button
                                    onClick={() => {
                                        window.open(S3_URL.KOMPASS_EMAIL_TEMPLATE, "_blank");
                                    }}
                                    className=" w-fit border border-[#d1d1d1] py-2 font-OutfitMedium px-4 flex items-center gap-2 text-black  whitespace-nowrap rounded-lg text-sm "
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_1348_8973" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                            <rect width="20" height="20" fill="#D9D9D9" />
                                        </mask>
                                        <g mask="url(#mask0_1348_8973)">
                                            <path
                                                d="M10 13L6 9L7.0625 7.9375L9.25 10.125V3H10.75V10.125L12.9375 7.9375L14 9L10 13ZM5.49417 16C5.08139 16 4.72917 15.8531 4.4375 15.5594C4.14583 15.2656 4 14.9125 4 14.5V13H5.5V14.5H14.5V13H16V14.5C16 14.9125 15.8531 15.2656 15.5592 15.5594C15.2653 15.8531 14.9119 16 14.4992 16H5.49417Z"
                                                fill="#1C1B1F"
                                            />
                                        </g>
                                    </svg>
                                    Kompass AI CSV Template
                                </button>
                            </div>
                        </div>
                    )}

                    {validateOption === 0 && currentWidgetIndex === 2 && (
                        <FileUploadValidationWidget setCurrentWidgetIndex={setCurrentWidgetIndex}></FileUploadValidationWidget>
                    )}

                    {validateOption === 1 && (
                        <>
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleInitiateLoading}>
                                {({ errors }) => (
                                    <Form className="flex flex-col gap-6 border border-[#e7e7e7] p-6 rounded-tr-lg rounded-bl-lg rounded-br-lg ">
                                        <div>
                                            <label className="flex flex-col mb-2">
                                                <span className="font-Outfit text-left text-[#050505] text-base font-normal font-['Outfit'] leading-tight">
                                                    List name
                                                </span>
                                            </label>
                                            <StyledInput
                                                name={"emailListName"}
                                                error={errors["emailListName"]}
                                                placeholder={"My list"}
                                                className="outline-none max-w-[428px] py-3 pl-4 pr-6 font-Outfit text-base w-full placeholder:text-stone-950 placeholder:opacity-40 placeholder:font-light border-[e7e7e7] border bg-white rounded-[8px]"
                                            ></StyledInput>
                                            <ErrorMessage
                                                name="emailListName"
                                                component="div"
                                                className="font-Outfit leading-tight text-sm font-normal text-red-600"
                                            />
                                        </div>
                                        <div>
                                            <label className=" flex flex-col gap-4">
                                                <div className="font-Outfit mb-2 flex flex-col text-left text-base font-normal leading-md">
                                                    Paste e-mail addresses separated by a comma (,) up to 100 addresses
                                                </div>
                                            </label>
                                            <Field
                                                as={Textarea}
                                                className={cn(
                                                    "font-OutfitLight !w-full p-0 text-base outline-none placeholder:text-[#929292] before:content-['']",
                                                    !!errors["list"] && "!border-[#dc2626]"
                                                )}
                                                variant="filled"
                                                id="list"
                                                name="list"
                                                minRows={6}
                                                placeholder="youremail1@gmail.com, youremail2@gmail.com"
                                            />
                                            <ErrorMessage
                                                name="list"
                                                component="div"
                                                className="font-Outfit leading-tight text-sm font-normal text-red-600"
                                            />
                                        </div>

                                        {/* <hr></hr>
                                        <Button
                                            type="submit"
                                            className="font-Outfit mx-auto h-11 w-56 flex items-center justify-center rounded-md bg-blue-500 text-base font-bold leading-md capitalize text-white"
                                        >
                                            Finish
                                        </Button> */}
                                    </Form>
                                )}
                            </Formik>
                        </>
                    )}
                    <div className="flex flex-col gap-4">
                        {validateOption === 0 && currentWidgetIndex === 1 && (
                            <div className="flex flex-row mt-10 justify-start gap-4">
                                <Button
                                    onClick={() => {
                                        setCurrentWidgetIndex(0);
                                        navigate("/bulkenrich");
                                    }}
                                    className="bg-white w-[110px] border border-[#d1d1d1] font-Outfit rounded-lg text-[#888888] text-base font-normal font-['Outfit'] leading-tight tracking-tight py-2 px-6 normal-case "
                                >
                                    Go back
                                </Button>
                                <Button
                                    onClick={() => {
                                        setCurrentWidgetIndex(2);
                                    }}
                                    className="w-[110px] text-white bg-[#050505] font-Outfit rounded-lg py-2 px-6 normal-case font-normal text-base leading-tight"
                                >
                                    Next
                                </Button>
                            </div>
                        )}

                        {validateOption === 0 && currentWidgetIndex === 2 && (
                            <div className="flex flex-row mt-10 justify-start gap-4">
                                <Button
                                    onClick={() => {
                                        setCurrentWidgetIndex(1);
                                    }}
                                    className="bg-white w-[110px] border border-[#d1d1d1] font-Outfit rounded-lg text-[#888888] text-base font-normal font-['Outfit'] leading-tight tracking-tight py-2 px-6 normal-case "
                                >
                                    Go back
                                </Button>
                                <Button
                                    onClick={() => {}}
                                    className="w-[110px] text-white bg-[#050505] font-Outfit rounded-lg py-2 px-6 normal-case font-normal text-base leading-tight"
                                >
                                    Finish
                                </Button>
                            </div>
                        )}
                        {validateOption === 1 && (
                            <div className="flex flex-row mt-10 justify-start gap-4">
                                <Button
                                    onClick={() => {
                                        setCurrentWidgetIndex(0);
                                        navigate("/bulkenrich");
                                    }}
                                    className="bg-white w-[110px] border border-[#d1d1d1] font-Outfit rounded-lg text-[#888888] text-base font-normal font-['Outfit'] leading-tight tracking-tight py-2 px-6 normal-case "
                                >
                                    Go back
                                </Button>
                                <Button
                                    onClick={() => {}}
                                    className="w-[110px] text-white bg-[#050505] font-Outfit rounded-lg py-2 px-6 normal-case font-normal text-base leading-tight"
                                >
                                    Finish
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <ConfirmDialog
                open={visibleConfirmDlg}
                handleClose={() => {
                    setVisibleConfirmDlg(false);
                }}
                handleOK={() => {
                    setVisibleConfirmDlg(false);
                    handleForm();
                }}
            />
        </>
    );
}
