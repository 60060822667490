import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { kompassColors } from "@/theme/palette";
import { ReactComponent as SalesforceIcon } from "@/assets/image/integrations/salesforce.svg";
import { ReactComponent as HubspotIcon } from "@/assets/image/integrations/hubspot.svg";
import { useState } from "react";
import StatusBadge from "./StatusBadge";
import useCRMPushResultStore from "@/store/useCRMPushResultStore";
import { truncateText } from "@/utils/common";

function CRMUploadTableRow(props) {
    const { row, labelId } = props;
    const [rowData, setRowData] = useState(row);
    const { target } = useCRMPushResultStore();

    return (
        <>
            <TableRow
                hover
                role="checkbox"
                className="h-[56px] min-h-[56px]"
                tabIndex={-1}
                key={rowData?.fileId}
                sx={{
                    cursor: "pointer",
                    background: "white",
                    "& .MuiTableCell-root": {
                        border: "none",
                        borderBottom: "1px solid #E8E7E7",
                        borderColor: kompassColors.light,
                    },
                }}
            >
                <TableCell component="th" id={labelId} scope="row" padding="none">
                    <div className="flex flex-row items-center gap-2">
                        <div className="h-fit w-fit">
                            {target === "Hubspot" && <HubspotIcon />}
                            {target === "Salesforce" && <SalesforceIcon />}
                        </div>
                        <div className="flex flex-col">
                            <span className="text-md font-OutfitLight font-semibold text-black">{ truncateText(rowData?.name, 30) }</span>
                            <div className="flex flex-row gap-1">{ truncateText(rowData?.company, 40) }</div>
                        </div>
                    </div>
                </TableCell>
                <TableCell align="center">
                    <div className="m-auto flex w-fit flex-col">{rowData?.entity}</div>
                </TableCell>
                <TableCell padding="none" align="center">
                    {rowData?.currentEntity}
                </TableCell>
                <TableCell align="center">
                    <StatusBadge status={rowData?.status} text={rowData?.status}>
                        {rowData?.status}
                    </StatusBadge>
                </TableCell>
                <TableCell align="center">{rowData?.suggestions}</TableCell>
            </TableRow>
        </>
    );
}
export default CRMUploadTableRow;
