import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import MainLayout from "@/components/MainLayout";
import { Backdrop } from "@mui/material";
import { ReactComponent as GoogleIcon } from "@/assets/image/search/chrome.svg";
import { ReactComponent as DoubleArrow } from "@/assets/image/double_arrow.svg";
import { ReactComponent as DoubleArrowRight } from "@/assets/image/double_arrow_2.svg";
import CRMUploadResultsTable from "@/components/common/CRMElements/CRMUploadTable";
import useCRMPushResultStore from "@/store/useCRMPushResultStore";
import MainTitleBar from "@/components/MainTitleBar";
import { getAllQueryParams } from "@/utils/common";
import { KompassFiltersProvider } from "@/hooks/useKompassFilters";
import SingleListFilters from "@/components/lists/singleListPage/SingleListFilters";
import { useParams } from "react-router-dom";
import useList from "@/hooks/useList";
import SingleListContactsTable from "@/components/lists/singleListPage/SingleListContactsTable";

export default function SingleListScene() {
    const [currentTab, setCurrentTab] = useState(0);
    const { show, loading: crmLoading } = useCRMPushResultStore();
    const [loading, setLoading] = useState(false);
    const [contactFilter, setContactFilter] = useState(null);
    const [selected, setSelected] = useState([]);
    const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
    const [filtersOpen, setFiltersOpen] = useState(true);
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [openRowIndex, setOpenRowIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [page, setPage] = useState(0);
    const [filters, setFilters] = useState({
        name: "",
        website: [],
        title: [],
        companyName: [],
        companyLocation: [],
        personalLocation: [],
        dept: [],
        subDepartment: [],
        level: [],
        numberOfEmployees: [],
        revenue: [],
        // fundingRaised: { min: "", max: "" },
        linkedinUrl: "",
        industryName: [],
        country: [],
        state: []
    });

    const [allOptions, setAllOptions] = useState({
        website: [],
        title: [],
        revenue: [],
        companyName: [],
        companyLocation: [],
        personalLocation: [],
        dept: [],
        subDepartment: [],
        level: [],
        numberOfEmployees: [],
        industryName: [],
        country: [],
        state: []
    });

    const { listId } = useParams();
    const { getProfilesByContactId } = useList();

    const extensionLogoUrl = "chrome-extension://imkmjfajnjfpfkdojdmabcphojonjjjf/logo.svg";
    // The status for installation of chrome extension
    const [crxStatus, setCrxStatus] = useState(null);

    useEffect(() => {
        const checkIfExtensionInstalled = async () => {
            try {
                const res = await fetch(extensionLogoUrl, { method: "HEAD" });

                setIsExtensionInstalled(res.ok);
            } catch (e) {
                setIsExtensionInstalled(false);
            }
        };
        checkIfExtensionInstalled();
    }, []);

    useEffect(() => {
        let timeoutId;

        const handleMessage = (event) => {
            const { action, params } = event.data;

            if (action === `kompassai-extension-ping`) {
                setCrxStatus({
                    isInstalled: true,
                    timestamp: params.timestamp,
                });

                // Clear any previous timeout and set a new one
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    setCrxStatus(null); // Mark as uninstalled after 10 seconds of no ping
                }, 10 * 1000);
            }
        };

        window.addEventListener(`message`, handleMessage);

        // Clean up the event listener and timeout on unmount
        return () => {
            window.removeEventListener(`message`, handleMessage);
            clearTimeout(timeoutId);
        };
    }, []); // No need for crxStatus in dependencies since we're handling it internally

    useEffect(() => {
        // Handle search query params
        const queryParams = getAllQueryParams(window.location.search);
        if (Object.keys(queryParams).length) {
            setContactFilter({
                name: [queryParams?.name],
                companyName: [queryParams?.companyName],
                nameExactMatch: true,
            });
            // Update the URL without causing a page reload
            const url = new URL(window.location.href);
            for (const key of Object.keys(queryParams)) {
                url.searchParams.delete(key);
            }
            window.history.replaceState(null, "", url.toString());
        }
    }, []); // Run once on mount

    const fetchContactsByListId = async () => {
        setIsLoading(true);
        const response = await getProfilesByContactId({ contactId: listId });

        if (response.status) {
            const newTableData = [...response.data];
            console.log('newTableData', newTableData)
            setTableData(newTableData);
            setFilteredData(newTableData);
            setTotalCount(newTableData.length);

            // Initialize a Set for each filter to ensure unique values
            const websiteSet = new Set();
            const titleSet = new Set();
            const companyNameSet = new Set();
            const companyLocationSet = new Set();
            const personalLocationSet = new Set();
            const deptSet = new Set();
            const subDepartmentSet = new Set();
            const levelSet = new Set();
            const numberOfEmployeesSet = new Set();
            const revenueSet = new Set();
            const industryNameSet = new Set();
            const countrySet = new Set();
            const stateSet = new Set();

            // Populate Sets with data, filtering out undefined values
            newTableData.forEach(contact => {
                if (contact.job_company_website) websiteSet.add(contact.job_company_website);
                if (contact.job_title) titleSet.add(contact.job_title);
                if (contact.job_company_name) companyNameSet.add(contact.job_company_name);
                if (contact.location_name) companyLocationSet.add(contact.location_name);
                if (contact.location) personalLocationSet.add(contact.location);
                if (contact.job_title_role) deptSet.add(contact.job_title_role);
                if (contact.job_title_sub_role) subDepartmentSet.add(contact.job_title_sub_role);
                if (contact.level) levelSet.add(contact.level);
                if (contact.company?.headCount) numberOfEmployeesSet.add(contact.company.headCount);
                if (contact.company?.revenue) revenueSet.add(contact.company.revenue);
                if (contact.industry) industryNameSet.add(contact.industry);
                if (contact.location) {
                    const locationParts = contact.location.split(",").map(part => part.trim());
                    const country = locationParts[locationParts.length - 1];
                    const state = locationParts[locationParts.length - 2];
                    if (country) countrySet.add(country);
                    if (state) stateSet.add(state);
                }
            });

            // Convert Sets to arrays of objects with { label, value } structure
            const convertToLabelValueArray = set =>
                Array.from(set).map(item => ({ label: item, value: item }));
            const allOptions = {
                website: convertToLabelValueArray(websiteSet),
                title: convertToLabelValueArray(titleSet),
                companyName: convertToLabelValueArray(companyNameSet),
                companyLocation: convertToLabelValueArray(companyLocationSet),
                personalLocation: convertToLabelValueArray(personalLocationSet),
                dept: convertToLabelValueArray(deptSet),
                subDepartment: convertToLabelValueArray(subDepartmentSet),
                level: convertToLabelValueArray(levelSet),
                numberOfEmployees: convertToLabelValueArray(numberOfEmployeesSet),
                revenue: convertToLabelValueArray(revenueSet),
                industryName: convertToLabelValueArray(industryNameSet),
                country: convertToLabelValueArray(countrySet),
                state: convertToLabelValueArray(stateSet),
            };

            setAllOptions(allOptions);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchContactsByListId();
    }, []);



    const filterTableData = () => {
        const data = tableData.filter((row) => {
            // Check text fields (name, title, etc.)
            if (filters.name && !row.full_name.toLowerCase().includes(filters.name.toLowerCase())) return false;
            if (filters.website?.length > 0 && !filters.website.includes(row.job_company_website)) return false;
            if (filters.title?.length > 0 && !filters.title.includes(row.job_title)) return false;
            if (filters.linkedinUrl && !row.linkedin_url?.includes(filters.linkedinUrl)) return false;
            // Check multi-value filters (companyName, companyLocation, etc.)
            if (filters.companyName?.length > 0 && !filters.companyName.includes(row.job_company_name)) return false;
            if (filters.companyLocation?.length > 0 && !filters.companyLocation.includes(row.location_name)) return false;
            if (filters.personalLocation?.length > 0 && !filters.personalLocation.includes(row.location)) return false;

            if (filters.dept?.length > 0 && !filters.dept.includes(row.job_title_role)) return false;
            if (filters.subDepartment?.length > 0 && !filters.subDepartment.includes(row.job_title_sub_role)) return false;

            if (filters.level?.length > 0 && !filters.level.includes(row.level)) return false;
            // Check numerical filters (numberOfEmployees, revenue)
            if (filters.numberOfEmployees?.length > 0 && !filters.numberOfEmployees.includes(row.company.headCount)) return false;
            if (filters.revenue?.length > 0 && filters.revenue.includes(row.company.revenue)) return false;
            // Check range filter (fundingRaised)
            // if (filters.fundingRaised.min && Number(row.funding) < filters.fundingRaised.min) return false;
            // if (filters.fundingRaised.max && Number(row.funding) > filters.fundingRaised.max) return false;
            // Check array filters (country, state)
            if (filters.country?.length > 0 && !filters.country.includes(row.location?.split(",")[row?.location?.split(",")?.length - 1]?.trim())) return false;
            if (filters.state?.length > 0 && !filters.state.includes(row.location?.split(",")[row?.location?.split(",")?.length - 2]?.trim())) return false;
            // Check industry filter (industryName)
            if (filters.industryName?.length > 0 && !filters.industryName.includes(row.industry)) return false;
            return true;
        });
        
        setFilteredData(data);
        setTotalCount(data.length);
        setPage(0)
    };

    useEffect(() => {
        filterTableData();
    }, [filters]);

    return (
        <KompassFiltersProvider
            onSearchByFilter={(filter) => setContactFilter(filter)}
            isFiltersSubpage={currentTab > 0}
            setCurrentTab={setCurrentTab}
        >
            <MainLayout loading={loading || crmLoading}>
                <>
                    <AnimatePresence>
                        {show && (
                            <motion.div
                                className="w-full"
                                initial={{ opacity: 0, zIndex: 11 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <Backdrop
                                    open
                                    className="!absolute"
                                    sx={{
                                        backgroundColor: "rgba(0,0,0,0.18)",
                                        backdropFilter: "blur(2px)",
                                        zIndex: 99999,
                                    }}
                                >
                                    <CRMUploadResultsTable></CRMUploadResultsTable>
                                </Backdrop>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </>
                <MainTitleBar>
                    <div className="flex flex-col justify-between sm:flex-row sm:items-end  gap-[4px]">
                        <p>Prospect Lists</p>
                        <div className="flex flex-row gap-2">
                            {isExtensionInstalled ? (
                                <a
                                    href="https://www.linkedin.com/profile/preview?locale=en_US"
                                    className="main-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <p className="font-Outfit leading-[1.4] font-normal">Launch Chrome Extension</p>
                                    <GoogleIcon className="ml-1 h-5 w-5 fill-current text-white" />
                                </a>
                            ) : (
                                <a
                                    href="https://chromewebstore.google.com/detail/kompassai/imkmjfajnjfpfkdojdmabcphojonjjjf?hl=en&authuser=2"
                                    className="main-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <p className="font-Outfit leading-[1.4] font-normal">Install Chrome Extension</p>
                                    <GoogleIcon className="ml-1 h-5 w-5 fill-current text-white" />
                                </a>
                            )}
                        </div>
                    </div>
                </MainTitleBar>
                <div className="grid w-full grid-cols-1 lg:grid-cols-12 lg:h-[calc(100vh-80px)] relative">
                    <div className={`col-span-1 lg:col-span-3 border-r border-stone-250  bg-white relative flex-col ${filtersOpen ? "flex" : "hidden"}`}>
                        <div onClick={() => setFiltersOpen(false)} className="absolute cursor-pointer lg:block hidden lg:right-[-24px] 2xl:right-[-27px] top-0 bg-black rounded-r-md p-[3px] py-2 ">
                            <DoubleArrow className=" h-5 w-5 " />
                        </div>
                        <SingleListFilters
                            filters={filters}
                            setFilters={setFilters}
                            setOpenRowIndex={setOpenRowIndex}
                            filterTableData={filterTableData}
                            allOptions={allOptions}
                        />
                    </div>
                    <div className={`col-span-1 h-full rounded-lg ${filtersOpen ? "lg:col-span-9 " : "lg:col-span-12"} `}>
                        <SingleListContactsTable
                            tableData={filteredData}
                            totalCount={totalCount}
                            setTableData={setFilteredData}
                            setTotalCount={setTotalCount}
                            page={page}
                            setPage={setPage}
                            filter={contactFilter}
                            selected={selected}
                            setSelected={setSelected}
                            filtersOpen={filtersOpen}
                            setOpenRowIndex={setOpenRowIndex}
                            openRowIndex={openRowIndex}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            isSearchClicked={isSearchClicked}
                            setIsSearchClicked={setIsSearchClicked}
                        />
                    </div>
                    <div onClick={() => setFiltersOpen(true)} className={`absolute cursor-pointer lg:block hidden left-0 top-0 bg-black rounded-r-md p-[3px] py-2 ${filtersOpen && "lg:hidden"}`}>
                        <DoubleArrowRight className=" h-5 w-5 " />
                    </div>
                </div>
            </MainLayout>
        </KompassFiltersProvider>
    );
}