import React, { useState, useEffect, useRef, useContext } from "react";
import PricingComponent from "@/components/pricing/PricingComponent/PricingComponent";
import PricingTable from "@/components/pricing/PricingTable";
import { useNavigate } from "react-router-dom";
import useBillingStore from "@/store/useBillingStore";
import { AuthContext } from "@/context/AuthContext";

const tiers = {
    free: {
        title: "Team",
        slogan: "Test drive it",
        theme: {
            border: "border-stone-250",
            main: "",
        },
    },
    professional: {
        title: "Professional",
        slogan: "Essential for finding your prospects",
        theme: {
            border: "border-[#F1A9FF]",
            main: "bg-[#F1A9FF]",
        },
    },
    premium: {
        title: "Premium",
        slogan: "Perfect for small teams with simple workflows",
        theme: {
            border: "border-accents-green",
            main: "bg-accents-green",
        },
        promo: "Most popular",
    },
    enterprise: {
        title: "Enterprise",
        slogan: "Ideal for prospecting at large scales",
        theme: {
            border: "border-[#9AB2FF]",
            main: "bg-[#9AB2FF]",
        },
    },
};

function FullPricingBlock(props) {
    const { tariffList, subscriptionInfo, annual, loading, topPosition } = props;
    const { changePrices, setRedirect, cleanPrices, changePlan, cleanPlan } = useBillingStore();
    const authContext = useContext(AuthContext);
    const [signed, setSigned] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState({
        planKey: null,
        price: null,
    });
    const currentPlan = tariffList?.find((tariff) => tariff.key === selectedPlan.planKey);
    const hideFreePlan = signed && subscriptionInfo && subscriptionInfo.plan !== "Free";
    const fieldRef = useRef(null);
    const navigate = useNavigate();

    //Selecting a plan by clicking a button in a table or on a Plan card
    const handleSelection = (product, price_obj, isCustomUpgrade) => {
        if (!signed) {
            setRedirect("/billingpay");
            navigate("/signin");
            return;
        }

        changePrices({ main: price_obj });
        changePlan(product);
        //If not customizable, redirect to payment

        if (selectedPlan.planKey === product.key) {
            cleanPrices();
            cleanPlan();
            setSelectedPlan({ planKey: null, price: null });
            return;
        } else {
            cleanPrices();
            changePrices({ main: price_obj });

            if (isCustomUpgrade) {
                navigate("/planupgrade", { state: { redirected: true } });
                return;
            }

            if (subscriptionInfo.plan !== "Free") {
                navigate("/planupgrade", { state: { redirected: true } });
                return;
            }

            navigate("/billingpay");
            return;
        }
    };

    useEffect(() => {
        (async () => {
            const signedIn = await authContext.isSignIn();
            setSigned(signedIn);
        })();
    }, []);

    //Smoothly scroll sliders into view on selecting customizable plan
    useEffect(() => {
        if (selectedPlan.planKey) {
            fieldRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [selectedPlan.planKey, fieldRef.current]);

    return (
        <div className="pt-12 xl:pt-16 flex h-fit w-full flex-col">
            <p className="mb-8 text-sm font-medium">* All features enabled until December 31, 2024 for all plans.</p>
            <div
                className={`w-full grid gap-x-6 xl:gap-x-10 gap-y-20 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 ${hideFreePlan ? "lg:grid-cols-3" : "lg:grid-cols-4"} items-end justify-between justify-items-between xl:gap-8 self-center`}
            >
                {tariffList?.map((product, index) => {
                    if (hideFreePlan && product.key === "free") return <></>;
                    return (
                        <PricingComponent
                            loading={loading}
                            key={product.id}
                            current={
                                subscriptionInfo?.plan?.toLowerCase() === product?.name?.toLowerCase() &&
                                subscriptionInfo?.status !== "canceled"
                            }
                            selected={currentPlan?.key === product.key}
                            tiers={tiers}
                            handleSelection={handleSelection}
                            product={product}
                            planState={[selectedPlan, setSelectedPlan]}
                            tierNumber={index}
                            annual={annual}
                            expandState={[expanded, setExpanded]}
                        />
                    );
                })}
            </div>
            {tariffList && tariffList.length > 0 && (
                <PricingTable tiers={tiers} annual={annual} current={currentPlan} products={tariffList} topPosition={topPosition} />
            )}
        </div>
    );
}

export default FullPricingBlock;
