import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Country, State } from "country-state-city";
import classNames from "classnames";
import { Button } from "@material-tailwind/react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AccordianComponent } from "@/components/search/AccordianComponents";
import FilterAutocomplete from "../common/FilterAutocomplete";
import AutocompleteInput from "../common/AutocompleteInput";
import { FilterInputProps } from "@/theme/typography";
import UserIcon from "@/assets/image/search/user.svg";
import HomeIcon from "@/assets/image/search/home.svg";
import LocationIcon from "@/assets/image/search/location.svg";
import DepartmentIcon from "@/assets/image/search/department.svg";
import JobTitleIcon from "@/assets/image/search/jobtitle.svg";
import SubDepartmentIcon from "@/assets/image/search/sub-department.svg";
import PLocationIcon from "@/assets/image/search/p-location.svg";
import CountryIcon from "@/assets/image/search/country.svg";
import StateIcon from "@/assets/image/search/state.svg";
import LevelIcon from "@/assets/image/search/level.svg";
import JobLevelIcon from "@/assets/image/search/level-icon.svg";
import USDIcon from "@/assets/image/search/usd.svg";
import IndustryIcon from "@/assets/image/search/industry.svg";
import GenderIcon from "@/assets/image/search/women-line.svg";
import CalendarIcon from "@/assets/image/search/calendar.svg";
import GlobesIcon from "@/assets/image/search/globus.svg";
import SkillLevel from "@/assets/image/search/skill-level.svg";
import MailIcon from "@/assets/image/search/mail-line.svg";
import EarthIcon from "@/assets/image/search/earth.svg";
import CertificationIcon from "@/assets/image/search/ph_certificate.svg";
import WebsiteAdvancedIcon from "@/assets/image/search/website.svg";
import ListingIcon from "@/assets/image/search/listing.svg";
import RangeIcon from "@/assets/image/search/range.svg";
import SchoolIcon from "@/assets/image/search/school.svg";
import EditIcon from "@/assets/image/search/edit-icon.svg";
import LinkIcon from "@/assets/image/search/base-link.svg";
import EditUserIcon from "@/assets/image/search/edit.svg";
import PhoneIcon from "@/assets/image/search/call-phone.svg";
import {
    departmentList,
    levelList,
    numberOfEmployeesList,
    revenueList,
    industryList,
    degreeList,
    genderList,
    continentList,
    countryList,
    listingList,
    languageList,
} from "@/utils/filter";
import { AdvancedFilters } from "@/components/search/AdvancedFilters";
import { SavedSearchesList } from "@/components/search/SavedSearchesList";
import { SaveSearchDialog } from "@/components/search/SaveSearchDialog";
import { useKompassFilters } from "@/hooks/useKompassFilters";
import { ScrollArea } from "../common/ScrollArea";

const GrayTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "#E8E7E7",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#E8E7E7",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#E8E7E7",
        },
        "&:hover fieldset": {
            borderColor: "#E8E7E7",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#E8E7E7",
        },
    },
    "& input:-webkit-autofill": {
        transition: "background-color 5000s ease-in-out 0s",
        boxShadow: "inset 0 0 0px 1000px transparent",
    },
});

function ContactFilterComponent({ isFiltersSubpage = true, setIsSearchClicked, setOpenRowIndex }) {
    const {
        savedFilters,
        setUpdateSearchFiltersSubpage,
        isSaveSearchModalOpen,
        setIsSaveSearchModalOpen,
        saveSearchTitle,
        setSaveSearchTitle,
        isAdvancedModalOpen,
        setIsAdvancedModalOpen,
        name,
        setName,
        website,
        setWebsite,
        title,
        setTitle,
        companyName,
        setCompanyName,
        companyLocation,
        setCompanyLocation,
        personalLocation,
        setPersonalLocation,
        dept,
        setDept,
        jobTitle,
        setJobTitle,
        subDepartment,
        setSubDepartment,
        level,
        setLevel,
        numberOfEmployees,
        setNumberOfEmployees,
        revenue,
        setRevenue,
        fundingRaised,
        setFundingRaised,
        linkedinUrl,
        setLinkedinUrl,
        industryName,
        setIndustryName,
        degree,
        setDegree,
        previousCompanyHqLocation,
        setPreviousCompanyHqLocation,
        languagesSpoken,
        setLanguagesSpoken,
        totalYearsOfExperience,
        setTotalYearsOfExperience,
        gender,
        setGender,
        continent,
        setContinent,
        country,
        setCountry,
        state,
        setState,
        skills,
        setSkills,
        workEmail,
        setWorkEmail,
        mobilePhone,
        setMobilePhone,
        personalEmail,
        setPersonalEmail,
        certification,
        setCertification,
        companyWebsite,
        setCompanyWebsite,
        companyContinent,
        setCompanyContinent,
        companyCountry,
        setCompanyCountry,
        companyListing,
        setCompanyListing,
        actualEmployeeRange,
        setActualEmployeeRange,
        hqContinent,
        setHqContinent,
        hqCountry,
        setHqCountry,
        numbersOfMonthInCurrentRole,
        setNumbersOfMonthInCurrentRole,
        jobLevel,
        setJobLevel,
        jobDepartment,
        setJobDepartment,
        jobSubDepartment,
        setJobSubDepartment,
        gitHubUrl,
        setGitHubUrl,
        gitHubUsername,
        setGitHubUsername,
        facebookUrl,
        setFacebookUrl,
        companyIndustry,
        setCompanyIndustry,
        schoolName,
        setSchoolName,
        isExpandAllTitles,
        setIsExpandAllTitles,
        pastCompanyName,
        setPastCompanyName,
        clearTrigger,
        handleSearch,
        onClearFilterClicked,
        onClearAdvancedFilterClicked,
        handleSubDepartmentList,
        handleSaveSearch,
        handleSetFilters,
        activeFilterCount,
        advancedActiveFilterCount,
        isFromSavedFiltersPage,
        statesList,
        setStatesList,
        isSavingLoading,
    } = useKompassFilters();

    const countryListForIsoCode = Country.getAllCountries().map((country) => ({
        label: country.name,
        value: country.isoCode,
    }));

    const getIsoCode = (country) => {
        const countryObject = countryListForIsoCode.find((obj) => obj.label.toLowerCase() === country?.toLowerCase());
        return countryObject?.value;
    };

    const handleCountryChange = (selectedCountries) => {
        // if (country && selectedCountries.length < country.length) {
        //     setCountry(selectedCountries);
        //     const includedCountries = selectedCountries.filter((country) => country.status == "include");
        //     if(!includedCountries.length) {
        //         return;
        //     }
        //     const selectedCountryIsoCode = getIsoCode(includedCountries[includedCountries.length - 1]?.value);
        //     const states = State.getStatesOfCountry(selectedCountryIsoCode).map((state) => ({
        //         label: state.name,
        //         value: state.name,
        //     }));

        //     setStatesList(states);
        //     setState([]);
        //     return;
        // }

        setCountry(selectedCountries);
        const includedCountries = selectedCountries.filter((country) => country.status == "include");
        if (!includedCountries.length) {
            setStatesList([]);
            return;
        }
        if (includedCountries.length > 0) {
            let newStatesList = [];
            includedCountries.forEach((country) => {
                const selectedCountryIsoCode = getIsoCode(country?.value);
                const states = State.getStatesOfCountry(selectedCountryIsoCode).map((state) => ({
                    label: state.name,
                    value: state.name,
                }));
                newStatesList = [...newStatesList, ...states];
            });
            setStatesList(newStatesList);
        } else {
            setStatesList([]);
        }
        // setState([]);
    };

    const location = useLocation();
    const searchStateProps = () => {
        if (location.state?.level) {
            setLevel(location.state?.level);
        }
        if (location.state?.department) {
            setDept(location.state?.department);
        }
        if (location.state?.companyFilter) {
            setCompanyName(location.state?.companyFilter);
        }
        if (location.state?.industryFilter) {
            setIndustryName(location.state?.industryFilter);
        }
        console.log(location.state?.sizeFilter);
        if (location.state?.sizeFilter) {
            setNumberOfEmployees(location.state?.sizeFilter);
        }
    };

    useEffect(() => {
        searchStateProps();
    }, []);

    const [heightClssName, setHeightClssName] = React.useState("lg:h-[calc(100vh-298px)] 2xl:h-[calc(100vh-331px)]");
    useEffect(() => {
        if (advancedActiveFilterCount && activeFilterCount) {
            setHeightClssName("lg:h-[calc(100vh-382px)] 2xl:h-[calc(100vh-425px)]");
        } else if (advancedActiveFilterCount || activeFilterCount) {
            setHeightClssName("lg:h-[calc(100vh-347px)] 2xl:h-[calc(100vh-382px)]");
        } else {
            setHeightClssName("lg:h-[calc(100vh-310px)] 2xl:h-[calc(100vh-343px)]");
        }
    }, [advancedActiveFilterCount, activeFilterCount]);

    return (
        <div
            className={classNames("font-Outfit w-full grow", {
                "flex flex-col justify-between": isFiltersSubpage,
            })}
        >
            <div className={"mb-6 flex w-full flex-col grow"}>
                {isFiltersSubpage ? (
                    <>
                        <div className="px-4 py-6 border-b-2 border-stone-400">
                            <div className="flex w-full flex-row items-center justify-between gap-2">
                                <p className="font-Outfit lg:text-[16px] xl:text-[18px] text-stone-950">Filters</p>
                                <div className="flex flex-row items-center gap-2 ">
                                    <button
                                        onClick={() => setIsAdvancedModalOpen(true)}
                                        className="rounded-[8px] lg:py-1 xl:py-1.5 lg:text-[9px] xl:text-xs font-medium lg:px-2 xl:px-3 border whitespace-nowrap border-stone-250 font-Outfit font-md text-black leading-[1.4] hover:bg-dgray-50"
                                    >
                                        Advanced Filters
                                    </button>
                                    <button
                                        onClick={handleSaveSearch}
                                        className="rounded-[8px] lg:py-1 xl:py-1.5 lg:text-[9px] xl:text-xs lg:px-2 xl:px-3 whitespace-nowrap bg-black border border-black text-white font-Outfit leading-[1.4] hover:opacity-90"
                                    >
                                        Save Filters
                                    </button>
                                </div>
                            </div>
                            <div className="">
                                {advancedActiveFilterCount ? (
                                    <div className="flex items-center gap-2 mt-5">
                                        <p className="text-sm font-Outfit">Advanced Filters: </p>

                                        <div className=" px-2 py-1 text-stone-950 flex gap-3 items-center bg-highlight rounded">
                                            <p className="font-Outfit text-xs font-normal leading-[1.2] text-black ">
                                                {advancedActiveFilterCount}
                                            </p>
                                        </div>
                                        <p
                                            onClick={onClearAdvancedFilterClicked}
                                            className="font-Outfit text-sm text-dgray-500 ml-auto hover:opacity-90 cursor-pointer"
                                        >
                                            Clear All
                                        </p>
                                    </div>
                                ) : null}
                                {activeFilterCount ? (
                                    <div className="flex items-center gap-2 mt-5">
                                        <p className="text-sm text-stone-950">Filters: </p>
                                        <div className=" px-2 py-1 flex gap-3 items-center bg-highlight rounded">
                                            <p className="font-Outfit text-xs font-normal leading-[1.2] text-black ">{activeFilterCount}</p>
                                        </div>
                                        <p
                                            onClick={onClearFilterClicked}
                                            className="text-sm text-dgray-500 ml-auto hover:opacity-90 cursor-pointer"
                                        >
                                            Clear All
                                        </p>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <button
                            onClick={() => setIsExpandAllTitles((prev) => !prev)}
                            className="font-Outfit px-4 py-3 text-sm text-dgray-500 font-md ml-auto hover:opacity-90 cursor-pointer text-right"
                        >
                            {isExpandAllTitles ? "Hide all" : "Expand All"}
                        </button>

                        <div className={`flex w-full flex-col ${heightClssName}`}>
                            <ScrollArea>
                                <h2 className="text-dgray-600 text-sm text-start px-4">Contact info</h2>
                                <AccordianComponent
                                    title="Full Name"
                                    icon={UserIcon}
                                    expand={isFromSavedFiltersPage && name}
                                    isAddBorder={true}
                                    expandAll={isExpandAllTitles}
                                >
                                    <div className="mt-1 w-full">
                                        <GrayTextField
                                            value={name}
                                            onKeyDown={(evt) => {
                                                if (evt.keyCode === 13) handleSearch();
                                            }}
                                            onChange={(e) => setName(e.target.value)}
                                            className="w-full"
                                            placeholder="First and last name"
                                            InputProps={FilterInputProps}
                                            sx={{ fontFamily: "Outfit" }}
                                        />
                                    </div>
                                </AccordianComponent>
                                <AccordianComponent
                                    title="Country"
                                    icon={CountryIcon}
                                    isAddBorder={true}
                                    expand={isFromSavedFiltersPage && country?.length}
                                    expandAll={isExpandAllTitles}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            multiple={true}
                                            clearTrigger={clearTrigger}
                                            value={country}
                                            options={countryList}
                                            onFilterChange={(selectedCountries) => {
                                                handleCountryChange(selectedCountries);
                                            }}
                                        />
                                    </div>
                                </AccordianComponent>
                                <AccordianComponent
                                    title="State/Province"
                                    icon={StateIcon}
                                    isAddBorder={true}
                                    expand={isFromSavedFiltersPage && state?.length}
                                    expandAll={isExpandAllTitles}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            multiple={true}
                                            clearTrigger={clearTrigger}
                                            value={state}
                                            options={statesList}
                                            onFilterChange={(selectedStates) => {
                                                setState(selectedStates);
                                            }}
                                            placeholder={
                                                !country.filter((country) => country.status == "include").length &&
                                                "Please select a country first."
                                            }
                                            disabled={!country.filter((country) => country.status == "include").length}
                                        />
                                    </div>
                                </AccordianComponent>
                                <AccordianComponent
                                    title="Personal Location"
                                    icon={PLocationIcon}
                                    isAddBorder={true}
                                    expand={isFromSavedFiltersPage && personalLocation?.length}
                                    expandAll={isExpandAllTitles}
                                >
                                    <div className="mt-1 w-full">
                                        <AutocompleteInput
                                            multiple={true}
                                            value={personalLocation}
                                            clearTrigger={clearTrigger}
                                            searchBy="location"
                                            onNameFilterChange={(selectedLocations) => {
                                                setPersonalLocation(selectedLocations);
                                                console.log(personalLocation);
                                            }}
                                        />
                                    </div>
                                </AccordianComponent>
                                <h2 className="text-dgray-600 text-sm text-start mt-3 px-4">Job info</h2>
                                <AccordianComponent
                                    title="Job Title"
                                    icon={JobTitleIcon}
                                    isAddBorder={true}
                                    expand={isFromSavedFiltersPage && title?.length}
                                    expandAll={isExpandAllTitles}
                                >
                                    <AutocompleteInput
                                        value={title}
                                        clearTrigger={clearTrigger}
                                        multiple={true}
                                        searchBy="title"
                                        onNameFilterChange={(ids) => setTitle(ids)}
                                    />
                                </AccordianComponent>
                                <AccordianComponent
                                    title="Job Level"
                                    icon={JobLevelIcon}
                                    isAddBorder={true}
                                    expand={isFromSavedFiltersPage && level?.length > 0}
                                    expandAll={isExpandAllTitles}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            multiple={true}
                                            clearTrigger={clearTrigger}
                                            value={level}
                                            options={levelList}
                                            onFilterChange={(value) => {
                                                setLevel(value);
                                            }}
                                        />
                                    </div>
                                </AccordianComponent>
                                <AccordianComponent
                                    title="Department"
                                    isAddBorder={true}
                                    icon={DepartmentIcon}
                                    expand={isFromSavedFiltersPage && dept?.length}
                                    expandAll={isExpandAllTitles}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            multiple={true}
                                            clearTrigger={clearTrigger}
                                            value={dept}
                                            options={departmentList}
                                            onFilterChange={(value) => {
                                                setDept(value);
                                            }}
                                        />
                                    </div>
                                </AccordianComponent>
                                <AccordianComponent
                                    title="Sub-Department"
                                    icon={SubDepartmentIcon}
                                    isAddBorder={true}
                                    expand={isFromSavedFiltersPage && subDepartment?.length}
                                    expandAll={isExpandAllTitles}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            multiple={true}
                                            clearTrigger={clearTrigger}
                                            value={subDepartment}
                                            options={handleSubDepartmentList(dept)}
                                            onFilterChange={(value) => setSubDepartment(value)}
                                        />
                                    </div>
                                </AccordianComponent>

                                <h2 className="text-dgray-600 text-sm text-start mt-3 px-4">Company Info</h2>
                                <AccordianComponent
                                    title="Company Name"
                                    icon={HomeIcon}
                                    isAddBorder={true}
                                    expand={isFromSavedFiltersPage && companyName?.length}
                                    expandAll={isExpandAllTitles}
                                >
                                    <div className="mt-1 w-full">
                                        <AutocompleteInput
                                            value={companyName}
                                            clearTrigger={clearTrigger}
                                            multiple={true}
                                            searchBy="company"
                                            onNameFilterChange={(company) => setCompanyName(company)}
                                        />
                                    </div>
                                </AccordianComponent>
                                <AccordianComponent
                                    title="Website"
                                    icon={WebsiteAdvancedIcon}
                                    isAddBorder={true}
                                    expand={isFromSavedFiltersPage && website?.length}
                                    expandAll={isExpandAllTitles}
                                >
                                    <div className="mt-1 w-full">
                                        <AutocompleteInput
                                            value={website}
                                            multiple={true}
                                            clearTrigger={clearTrigger}
                                            isWebsiteAutocomplete={true}
                                            searchBy="website"
                                            onNameFilterChange={(website) =>
                                                setWebsite(website?.name || website?.meta?.website || website || "")
                                            }
                                        />
                                    </div>
                                </AccordianComponent>
                                <AccordianComponent
                                    title="Industry"
                                    icon={LocationIcon}
                                    isAddBorder={true}
                                    expand={isFromSavedFiltersPage && industryName?.length}
                                    expandAll={isExpandAllTitles}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            multiple={true}
                                            value={industryName}
                                            options={industryList}
                                            onFilterChange={(selectedIndustry) => {
                                                setIndustryName(selectedIndustry);
                                            }}
                                        />
                                    </div>
                                </AccordianComponent>
                                <AccordianComponent
                                    title="Company Revenue"
                                    icon={USDIcon}
                                    isAddBorder={true}
                                    expand={isFromSavedFiltersPage && Object.keys(revenue).length}
                                    expandAll={isExpandAllTitles}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            value={revenue}
                                            options={revenueList}
                                            onFilterChange={(value) => setRevenue(value)}
                                        />
                                    </div>
                                </AccordianComponent>
                                <AccordianComponent
                                    title="Size"
                                    icon={DepartmentIcon}
                                    isAddBorder={true}
                                    expand={isFromSavedFiltersPage && numberOfEmployees?.length}
                                    expandAll={isExpandAllTitles}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            multiple={true}
                                            value={numberOfEmployees}
                                            options={numberOfEmployeesList}
                                            onFilterChange={(value) => setNumberOfEmployees(value)}
                                        />
                                    </div>
                                </AccordianComponent>
                                <AccordianComponent
                                    title="Funding Raised"
                                    icon={LevelIcon}
                                    isAddBorder={true}
                                    expand={isFromSavedFiltersPage && numberOfEmployees?.length}
                                    expandAll={isExpandAllTitles}
                                >
                                    <div className="mt-1 w-full grid grid-cols-2 gap-2">
                                        <GrayTextField
                                            value={fundingRaised.min}
                                            onKeyDown={(evt) => {
                                                if (evt.keyCode === 13) handleSearch();
                                            }}
                                            type="number"
                                            min={1}
                                            onChange={(e) => {
                                                const value = e.target.value === "" ? "" : Math.max(1, Number(e.target.value));
                                                setFundingRaised((prev) => ({
                                                    ...prev,
                                                    min: value,
                                                }));
                                            }}
                                            className="w-full"
                                            placeholder="Min USD"
                                            InputProps={FilterInputProps}
                                            expandAll={isExpandAllTitles}
                                        />
                                        <GrayTextField
                                            value={fundingRaised.max}
                                            onKeyDown={(evt) => {
                                                if (evt.keyCode === 13) handleSearch();
                                            }}
                                            type="number"
                                            min={1}
                                            onChange={(e) => {
                                                const value = e.target.value === "" ? "" : Math.max(1, Number(e.target.value));
                                                setFundingRaised((prev) => ({
                                                    ...prev,
                                                    max: value,
                                                }));
                                            }}
                                            className="w-full"
                                            placeholder="Max USD"
                                            InputProps={FilterInputProps}
                                        />
                                    </div>
                                </AccordianComponent>
                                <AccordianComponent
                                    title="Company Location"
                                    icon={PLocationIcon}
                                    isAddBorder={true}
                                    expand={isFromSavedFiltersPage && companyLocation?.length}
                                    expandAll={isExpandAllTitles}
                                >
                                    <div className="mt-1 flex w-full flex-col gap-1">
                                        <AutocompleteInput
                                            value={companyLocation}
                                            clearTrigger={clearTrigger}
                                            multiple={true}
                                            searchBy="location"
                                            onNameFilterChange={(location) => setCompanyLocation(location?.name || location || "")}
                                        />
                                    </div>
                                </AccordianComponent>
                                <h2 className="text-dgray-600 text-sm text-start mt-3 px-4">Social Presence</h2>
                                <AccordianComponent
                                    title="Linkedin URL"
                                    icon={LinkIcon}
                                    isAddBorder={false}
                                    expand={isFromSavedFiltersPage && linkedinUrl}
                                    expandAll={isExpandAllTitles}
                                >
                                    <div className="mt-1 w-full">
                                        <GrayTextField
                                            value={linkedinUrl}
                                            onKeyDown={(evt) => {
                                                if (evt.keyCode === 13) handleSearch();
                                            }}
                                            onChange={(e) => setLinkedinUrl(e.target.value)}
                                            className="w-full"
                                            placeholder="linkedin.com/in/somename"
                                            InputProps={FilterInputProps}
                                        />
                                    </div>
                                </AccordianComponent>
                            </ScrollArea>
                        </div>
                    </>
                ) : null}
            </div>
            {isFiltersSubpage ? (
                <div className="lg:sticky bottom-0 z-10 flex w-full flex-row items-center justify-between border-y border-l border-stone-250 bg-white">
                    <Button
                        onClick={() => {
                            setIsSearchClicked(true);
                            setOpenRowIndex(null);
                            handleSearch();
                        }}
                        className="font-Outfit m-5 w-full rounded-md bg-stone-950 p-3 text-base font-normal capitalize text-white"
                    >
                        Search
                    </Button>
                </div>
            ) : null}
            {!isFiltersSubpage ? (
                <div className={`flex flex-col w-full`}>
                    <SavedSearchesList
                        handleSetFilters={handleSetFilters}
                        savedFilters={savedFilters}
                        setUpdateSearchFilters={setUpdateSearchFiltersSubpage}
                    />
                </div>
            ) : null}
            <AdvancedFilters
                open={isAdvancedModalOpen}
                onClose={() => {
                    setIsAdvancedModalOpen(false);
                }}
            >
                <div className="grid grid-cols-2">
                    <div className="advanced-filters-grid-item border-r border-stone-250 ">
                        <p className="advanced-search-filters-sub-title font-OutfitSemiBold">Contact info</p>
                        <div className="advanced-filters-group-box">
                            <AccordianComponent
                                isAddBorder={true}
                                title="Gender"
                                icon={GenderIcon}
                                expand={isFromSavedFiltersPage && gender}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        value={gender}
                                        options={genderList}
                                        onFilterChange={(selectedGenders) => {
                                            setGender(selectedGenders);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                isAddBorder={true}
                                title="Total Years of Experience"
                                icon={CalendarIcon}
                                expand={isFromSavedFiltersPage && (totalYearsOfExperience.min || totalYearsOfExperience.max)}
                            >
                                <div className="mt-1 w-full grid grid-cols-2 gap-2">
                                    <GrayTextField
                                        value={totalYearsOfExperience.min}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        type="number"
                                        min={1}
                                        onChange={(e) => {
                                            const value = e.target.value === "" ? "" : Math.max(1, Number(e.target.value));
                                            setTotalYearsOfExperience((prev) => ({
                                                ...prev,
                                                min: value,
                                            }));
                                        }}
                                        className="w-full"
                                        placeholder="Min years"
                                        InputProps={FilterInputProps}
                                    />
                                    <GrayTextField
                                        value={totalYearsOfExperience.max}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        type="number"
                                        min={1}
                                        onChange={(e) => {
                                            const value = e.target.value === "" ? "" : Math.max(1, Number(e.target.value));
                                            setTotalYearsOfExperience((prev) => ({
                                                ...prev,
                                                max: value,
                                            }));
                                        }}
                                        className="w-full"
                                        placeholder="Max years"
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                isAddBorder={true}
                                title="Languages Spoken"
                                icon={GlobesIcon}
                                expand={isFromSavedFiltersPage && languagesSpoken?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        value={languagesSpoken}
                                        options={languageList}
                                        onFilterChange={(selectedLanguages) => {
                                            setLanguagesSpoken(selectedLanguages);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Skills"
                                isAddBorder={true}
                                icon={SkillLevel}
                                expand={isFromSavedFiltersPage && skills?.length}
                            >
                                <div className="mt-1 w-full">
                                    <AutocompleteInput
                                        value={skills}
                                        clearTrigger={clearTrigger}
                                        multiple={true}
                                        searchBy="skill"
                                        onNameFilterChange={(skill) => setSkills(skill)}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Work Email (beta)"
                                isAddBorder={true}
                                icon={MailIcon}
                                expand={isFromSavedFiltersPage && workEmail}
                            >
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={workEmail}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        onChange={(e) => setWorkEmail(e.target.value)}
                                        className="w-full"
                                        placeholder=""
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Mobile Number (beta)"
                                isAddBorder={true}
                                icon={PhoneIcon}
                                expand={isFromSavedFiltersPage && mobilePhone}
                            >
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={mobilePhone}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        onChange={(e) => setMobilePhone(e.target.value)}
                                        className="w-full"
                                        placeholder=""
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent title="Continent" isAddBorder={true} icon={EarthIcon} expand={continent?.length}>
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        value={continent}
                                        options={continentList}
                                        onFilterChange={(selectedContinents) => {
                                            setContinent(selectedContinents);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Personal Email (beta)"
                                icon={MailIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && personalEmail}
                            >
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={personalEmail}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        onChange={(e) => setPersonalEmail(e.target.value)}
                                        className="w-full"
                                        placeholder=""
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                        </div>
                        <p className="advanced-search-filters-sub-title font-OutfitSemiBold mt-3">Education</p>
                        <div className="advanced-filters-group-box">
                            <AccordianComponent
                                title="Certification"
                                icon={CertificationIcon}
                                expand={isFromSavedFiltersPage && certification}
                            >
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={certification}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        onChange={(e) => setCertification(e.target.value)}
                                        className="w-full"
                                        placeholder=""
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="School Name"
                                icon={SchoolIcon}
                                isAddBorder={true}
                                expand={isFromSavedFiltersPage && schoolName}
                            >
                                <div className="mt-1 w-full">
                                    <AutocompleteInput
                                        value={schoolName}
                                        clearTrigger={clearTrigger}
                                        multiple={true}
                                        searchBy="school"
                                        onNameFilterChange={(school) => setSchoolName(school?.name || school || "")}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Degree Name"
                                icon={EditIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && degree?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        value={degree}
                                        options={degreeList}
                                        onFilterChange={(value) => {
                                            setDegree(value);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                        </div>
                        <p className="advanced-search-filters-sub-title font-OutfitSemiBold mt-3">Social Presence</p>
                        <div className="advanced-filters-group-box">
                            <AccordianComponent title="GitHub URL" icon={LinkIcon} expand={isFromSavedFiltersPage && gitHubUrl}>
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={gitHubUrl}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        onChange={(e) => setGitHubUrl(e.target.value)}
                                        className="w-full"
                                        placeholder="github.com/some_name"
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="GitHub User Name"
                                icon={EditUserIcon}
                                expand={isFromSavedFiltersPage && gitHubUsername}
                            >
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={gitHubUsername}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        onChange={(e) => setGitHubUsername(e.target.value)}
                                        className="w-full"
                                        placeholder="some_name"
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Facebook URL"
                                icon={LinkIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && facebookUrl}
                            >
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={facebookUrl}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        onChange={(e) => setFacebookUrl(e.target.value)}
                                        className="w-full"
                                        placeholder=""
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                        </div>
                    </div>
                    <div className="advanced-filters-grid-item">
                        <p className="advanced-search-filters-sub-title font-OutfitSemiBold">Company info</p>
                        <div className="advanced-filters-group-box">
                            <AccordianComponent
                                title="Actual Employee Range"
                                icon={RangeIcon}
                                expand={isFromSavedFiltersPage && (actualEmployeeRange?.min || actualEmployeeRange.max)}
                            >
                                <div className="mt-1 w-full grid grid-cols-2 gap-2">
                                    <GrayTextField
                                        value={actualEmployeeRange.min}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        type="number"
                                        min={1}
                                        onChange={(e) => {
                                            const value = e.target.value === "" ? "" : Math.max(1, Number(e.target.value));
                                            setActualEmployeeRange((prev) => ({
                                                ...prev,
                                                min: value,
                                            }));
                                        }}
                                        className="w-full"
                                        placeholder="Min employees"
                                        InputProps={FilterInputProps}
                                    />
                                    <GrayTextField
                                        value={actualEmployeeRange.max}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        type="number"
                                        min={1}
                                        onChange={(e) => {
                                            const value = e.target.value === "" ? "" : Math.max(1, Number(e.target.value));
                                            setActualEmployeeRange((prev) => ({
                                                ...prev,
                                                max: value,
                                            }));
                                        }}
                                        className="w-full"
                                        placeholder="Max employees"
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent title="HQ Continent" icon={EarthIcon} expand={hqContinent?.length}>
                                <div className="mt-1 w-full ">
                                    <FilterAutocomplete
                                        multiple={true}
                                        isAddBorder={true}
                                        value={hqContinent}
                                        options={continentList}
                                        onFilterChange={(selectedContinents) => {
                                            setHqContinent(selectedContinents);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent title="HQ Country" icon={CountryIcon} expand={hqCountry?.length}>
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        value={hqCountry}
                                        options={countryList}
                                        onFilterChange={(selectedGenders) => {
                                            setHqCountry(selectedGenders);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            {/*<AccordianComponent*/}
                            {/*  title="Company Growth Rate"*/}
                            {/*  isAddBorder={false}*/}
                            {/*  icon={GrowthRate}*/}
                            {/*>*/}
                            {/*  <div className="mt-1 w-full">*/}
                            {/*    <GrayTextField*/}
                            {/*      value={companyGrowthRate}*/}
                            {/*      onKeyDown={(evt) => {*/}
                            {/*        if (evt.keyCode === 13) onSearchButtonClicked();*/}
                            {/*      }}*/}
                            {/*      onChange={(e) => setCompanyGrowthRate(e.target.value)}*/}
                            {/*      className="w-full"*/}
                            {/*      placeholder=""*/}
                            {/*      InputProps={FilterInputProps}*/}
                            {/*    />*/}
                            {/*  </div>*/}
                            {/*</AccordianComponent>*/}
                            {/*<AccordianComponent*/}
                            {/*  title="Exclude Europe"*/}
                            {/*  isAddBorder={false}*/}
                            {/*  icon={EuIcon}*/}
                            {/*>*/}
                            {/*  <div className="mt-1 w-full">*/}
                            {/*    <GrayTextField*/}
                            {/*      value={companyGrowthRate}*/}
                            {/*      onKeyDown={(evt) => {*/}
                            {/*        if (evt.keyCode === 13) onSearchButtonClicked();*/}
                            {/*      }}*/}
                            {/*      onChange={(e) => setCompanyGrowthRate(e.target.value)}*/}
                            {/*      className="w-full"*/}
                            {/*      placeholder=""*/}
                            {/*      InputProps={FilterInputProps}*/}
                            {/*    />*/}
                            {/*  </div>*/}
                            {/*</AccordianComponent>*/}
                            <AccordianComponent
                                title="Numbers of Months in Current Role"
                                icon={CalendarIcon}
                                isAddBorder={false}
                                expand={isFromSavedFiltersPage && numbersOfMonthInCurrentRole}
                            >
                                <div className="mt-1 w-full">
                                    <GrayTextField
                                        value={numbersOfMonthInCurrentRole}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) handleSearch();
                                        }}
                                        type="number"
                                        min={1}
                                        onChange={(e) => {
                                            const value = e.target.value === "" ? "" : Math.max(1, Number(e.target.value));
                                            setNumbersOfMonthInCurrentRole(value);
                                        }}
                                        className="w-full"
                                        InputProps={FilterInputProps}
                                    />
                                </div>
                            </AccordianComponent>
                        </div>
                        <p className="advanced-search-filters-sub-title font-OutfitSemiBold mt-3">Past Job Info</p>
                        <div className="advanced-filters-group-box">
                            <AccordianComponent title="Job Title" icon={JobTitleIcon} expand={isFromSavedFiltersPage && jobTitle?.length}>
                                <div className="mt-1 w-full">
                                    <AutocompleteInput
                                        value={jobTitle}
                                        clearTrigger={clearTrigger}
                                        multiple={true}
                                        searchBy="title"
                                        onNameFilterChange={(title) => setJobTitle(title)}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent title="Job Level" icon={JobLevelIcon} expand={isFromSavedFiltersPage && jobLevel?.length}>
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        value={jobLevel}
                                        options={levelList}
                                        onFilterChange={(selectedJobLevels) => {
                                            setJobLevel(selectedJobLevels);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Department"
                                icon={DepartmentIcon}
                                expand={isFromSavedFiltersPage && jobDepartment?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        isAddBorder={true}
                                        value={jobDepartment}
                                        options={departmentList}
                                        onFilterChange={(selectedDepartment) => {
                                            setJobDepartment(selectedDepartment);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Sub-Department"
                                icon={SubDepartmentIcon}
                                expand={isFromSavedFiltersPage && jobSubDepartment?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        value={jobSubDepartment}
                                        options={handleSubDepartmentList(jobDepartment)}
                                        onFilterChange={(selectedSubDepartments) => {
                                            setJobSubDepartment(selectedSubDepartments);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Website"
                                icon={WebsiteAdvancedIcon}
                                expand={isFromSavedFiltersPage && companyWebsite}
                            >
                                <div className="mt-1 w-full">
                                    <AutocompleteInput
                                        value={companyWebsite}
                                        multiple={true}
                                        clearTrigger={clearTrigger}
                                        isWebsiteAutocomplete={true}
                                        searchBy="website"
                                        onNameFilterChange={(website) =>
                                            setCompanyWebsite(website?.name || website?.meta?.website || website || "")
                                        }
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Continent"
                                icon={EarthIcon}
                                expand={isFromSavedFiltersPage && companyContinent?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        value={companyContinent}
                                        options={continentList}
                                        onFilterChange={(selectedContinents) => {
                                            setCompanyContinent(selectedContinents);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Country"
                                icon={CountryIcon}
                                expand={isFromSavedFiltersPage && companyCountry?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        isAddBorder={true}
                                        value={companyCountry}
                                        options={countryList}
                                        onFilterChange={(selectedCountries) => {
                                            setCompanyCountry(selectedCountries);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                isAddBorder={true}
                                title="Listing"
                                icon={ListingIcon}
                                expand={isFromSavedFiltersPage && companyListing?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        isAddBorder={true}
                                        value={companyListing}
                                        options={listingList}
                                        onFilterChange={(selectedList) => {
                                            setCompanyListing(selectedList);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>

                            <AccordianComponent
                                title="Company name"
                                icon={EditIcon}
                                expand={isFromSavedFiltersPage && pastCompanyName?.length}
                            >
                                <div className="mt-1 w-full">
                                    <AutocompleteInput
                                        value={pastCompanyName}
                                        clearTrigger={clearTrigger}
                                        multiple={true}
                                        searchBy="company"
                                        onNameFilterChange={(company) => setPastCompanyName(company)}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Industry"
                                icon={IndustryIcon}
                                expand={isFromSavedFiltersPage && companyIndustry?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        multiple={true}
                                        value={companyIndustry}
                                        options={industryList}
                                        onFilterChange={(selectedIndustries) => {
                                            setCompanyIndustry(selectedIndustries);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                isAddBorder={false}
                                title="Previous Company HQ Location"
                                icon={StateIcon}
                                expand={isFromSavedFiltersPage && previousCompanyHqLocation}
                            >
                                <div className="mt-1 w-full">
                                    <AutocompleteInput
                                        value={previousCompanyHqLocation}
                                        clearTrigger={clearTrigger}
                                        multiple={true}
                                        searchBy="location"
                                        onNameFilterChange={(title) => setPreviousCompanyHqLocation(title?.name || title || "")}
                                    />
                                </div>
                            </AccordianComponent>
                        </div>
                    </div>
                </div>
            </AdvancedFilters>
            <SaveSearchDialog
                open={isSaveSearchModalOpen}
                close={() => {
                    setIsSaveSearchModalOpen(false);
                }}
                title={saveSearchTitle}
                isSaving={isSavingLoading}
                setTitle={setSaveSearchTitle}
                onSave={handleSaveSearch}
            />
        </div>
    );
}

export { ContactFilterComponent };
